import React from 'react'
import styled from '@emotion/styled'
import {
  textElementStyle,
  AdminLabelTextDiv,
  adminInputWrapperStyle,
} from './input-builder'

const Label = styled('label')`
  ${adminInputWrapperStyle};
  display: block;
  div {
    height: 23px;
  }
  input {
    all: unset;
    ${textElementStyle};
    width: 100%;
    :focus {
      border-color: black;
    }
    &[disabled] {
      background-color: #d5d5d5;
    }
  }
`

const Error = styled('span')`
  color: red;
`

type PropertiesExcept<T, E> = {
  [k in keyof T]: k extends E ? never : k
}[keyof T]
type PickExcept<T, E> = Pick<T, PropertiesExcept<T, E>>

const AdminInput = ({
  label,
  state = {},
  disabled,
  ...props
}: PickExcept<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange' | 'value'
> & {
  label: string
  state?: {
    change?: (v: string) => void
    value?: string
    error?: string | null
  }
}) => (
  <Label>
    <AdminLabelTextDiv>
      {label}
      {state.error && <Error> {state.error}</Error>}
    </AdminLabelTextDiv>
    <input
      {...props}
      disabled={!state.change ? true : disabled}
      value={state.value || ''}
      onChange={(evt) => {
        if (state.change) state.change(evt.target.value)
      }}
    />
  </Label>
)
export default AdminInput

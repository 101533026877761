/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useQuery, useMutation } from '@apollo/client/react/hooks'
import gql from 'graphql-tag'
import LoaderSpinner from 'components/loader-spinner'
import * as q from 'queries/queries'
import { Fab, Modal } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import AdminInput from 'admin/components/admin-input'
import { EditCard } from 'admin/components/edit-card'

const tokensQuery = gql`
  query adminApiTokens {
    me {
      id
      apiTokens {
        id
        name
        expiration
      }
    }
  }
`

export default function AdminApiToken() {
  const { data, loading, error } = useQuery<q.AdminApiTokensQuery>(tokensQuery)
  const [modalShown, setModalShown] = useState(false)
  const [name, setName] = useState('')
  const [generateApiToken, generateApiTokenResult] = useMutation<
    q.AdminGenerateApiTokenMutation,
    q.AdminGenerateApiTokenMutationVariables
  >(
    gql`
      mutation adminGenerateApiToken($name: String!) {
        generateApiToken(name: $name) {
          value
          token {
            id
            expiration
            name
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: tokensQuery }],
      awaitRefetchQueries: true,
    },
  )
  const [deleteApiToken] = useMutation<
    q.AdminDeleteApiTokenMutation,
    q.AdminDeleteApiTokenMutationVariables
  >(
    gql`
      mutation adminDeleteApiToken($id: GlobalID!) {
        deleteApiToken(id: $id)
      }
    `,
    {
      refetchQueries: [{ query: tokensQuery }],
      awaitRefetchQueries: true,
      ignoreResults: true,
    },
  )
  if (loading) return <LoaderSpinner />
  if (error) return <div>{JSON.stringify(error)}</div>
  if (!data || !data.me) return <div>Něco se pokazilo</div>

  const tokenValue =
    !!generateApiTokenResult.data &&
    !!generateApiTokenResult.data.generateApiToken &&
    generateApiTokenResult.data.generateApiToken.value

  const clipboardWrite =
    typeof navigator === 'object' &&
    navigator.clipboard &&
    'writeText' in navigator.clipboard &&
    ((text: string) => navigator.clipboard.writeText(text))

  return (
    <div>
      <Modal open={modalShown} onClose={() => setModalShown(false)}>
        <div css={{ marginTop: 50 }}>
          <EditCard
            changed={name !== '' && !tokenValue}
            title="Nový API klíč"
            onSave={() => {
              generateApiToken({ variables: { name } })
            }}
            saving={generateApiTokenResult.loading}
            hasError={!!generateApiTokenResult.error}
          >
            {tokenValue ? (
              <div>
                Tvůj token je: {tokenValue}{' '}
                {clipboardWrite ? (
                  <button
                    type="button"
                    onClick={() => {
                      clipboardWrite(tokenValue)
                    }}
                  >
                    Kopírovat do schránky
                  </button>
                ) : null}
              </div>
            ) : (
              <AdminInput
                state={{ value: name, change: setName }}
                label="Název API klíče"
              />
            )}
          </EditCard>
        </div>
      </Modal>
      <Fab
        onClick={() => {
          setModalShown(true)
          setName('')
        }}
      >
        <AddIcon />
      </Fab>
      {data.me.apiTokens.map((token) => (
        <div key={token.id}>
          {token.name} {token.expiration ? `(končí ${token.expiration})` : ''}{' '}
          <button
            type="button"
            onClick={() => {
              deleteApiToken({ variables: { id: token.id } })
            }}
          >
            Smazat
          </button>
        </div>
      ))}
    </div>
  )
}

import React, { useContext, PropsWithChildren } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Text,
  TouchableOpacity,
  StyleProp,
  TextStyle,
  Platform,
} from 'react-native'
import { useHistoryChange } from 'components/use-router'

type LinkComponent = React.FC<
  {
    to: string
    style?: any
    className?: string
  } & React.AnchorHTMLAttributes<HTMLAnchorElement>
>

type RNLinkProps = PropsWithChildren<{
  to: string
  style?: StyleProp<TextStyle>
}>

type RNLinkComponent = React.ComponentType<RNLinkProps>

const LinkContext = React.createContext((to: string) => to)

export const LinkContextProvider: React.FC<{
  mapTo: (to: string) => string
}> = ({ mapTo, children }) => {
  return <LinkContext.Provider value={mapTo}>{children}</LinkContext.Provider>
}

function categorizeLink(
  to: string,
): 'external' | 'internal' | 'internal-refresh' {
  return to.startsWith('http:') ||
    to.startsWith('https:') ||
    to.startsWith('mailto:') ||
    to.endsWith('.pdf')
    ? 'external'
    : 'internal'
}

const BaseLink: LinkComponent = ({ to, ...props }) => {
  const cat = categorizeLink(to)
  return cat === 'external' ? (
    <a {...props} href={to} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) : cat === 'internal-refresh' ? (
    <a {...props} href={to}>
      {props.children}
    </a>
  ) : cat === 'internal' ? (
    <RouterLink to={to} {...props} />
  ) : (
    assertNever(cat)
  )
}

function assertNever(a: never): never {
  throw new Error(`assertNever ${a}`)
}

const BaseRNLink: RNLinkComponent = ({ children, to, ...rest }) => {
  const onPress = useLinkOnPress(to)
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Text
        {...Platform.select({
          web: {
            accessibilityRole: 'link',
          },
        })}
        {...rest}
      >
        {children}
      </Text>
    </TouchableOpacity>
  )
}

const Link: LinkComponent = ({ to, ...props }) => {
  const mapTo = useContext(LinkContext)
  return <BaseLink to={mapTo(to)} {...props} />
}
export const RNLink: RNLinkComponent = ({ to, ...props }) => {
  const mapTo = useContext(LinkContext)
  return <BaseRNLink to={mapTo(to)} {...props} />
}

export function useLinkOnPress(to: string) {
  const history = useHistoryChange()
  const mapTo = useContext(LinkContext)
  return () => {
    const mapped = mapTo(to)
    const cat = categorizeLink(mapped)
    if (cat === 'external') {
      const win = window.open()
      if (win) {
        win.opener = null
        win.location.replace(mapped)
      }
    } else if (cat === 'internal-refresh') {
      window.location.assign(mapped)
    } else if (cat === 'internal') {
      history.push(mapped)
    } else {
      assertNever(cat)
    }
  }
}

export default Link

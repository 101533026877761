import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { createUseMutation } from 'admin/utils/create-use-mutation'
import { useMemo, useRef, useEffect, useCallback } from 'react'
import {
  AdminTopicQuery,
  AdminTopicQueryVariables,
  AdminTopicListQuery,
  AdminTopicListQueryVariables,
  AdminUpsertTopicMutation,
  AdminUpsertTopicMutationVariables,
} from 'queries/queries'

const topicListQuery = gql`
  query adminTopicList($only: TopicFilter) {
    authorities {
      list {
        topics: filteredTopics(only: $only) {
          list {
            id
            name
            availableInReflektor
            availableInEvaluation
          }
        }
      }
    }
  }
`

export const useAdminTopicList = ():
  | { status: 'loading' | 'error'; list: null; refetch: () => void }
  | {
      status: 'ok'
      list: readonly {
        readonly __typename: 'Topic'
        readonly id: string
        readonly name: string
        readonly availableInReflektor: boolean
        readonly availableInEvaluation: boolean
      }[]
      refetch: () => void
    } => {
  const ret = useQuery<AdminTopicListQuery, AdminTopicListQueryVariables>(
    topicListQuery,
  )
  const fallback = ret?.loading ? 'loading' : 'error'
  const list =
    (ret &&
      ret.data &&
      'authorities' in ret.data &&
      ret.data.authorities.list) ||
    fallback
  const refetchRef = useRef(ret?.refetch)
  useEffect(() => {
    refetchRef.current = ret?.refetch
  })
  const refetch = useCallback(() => {
    refetchRef.current?.({})
  }, [])

  return useMemo(() => {
    if (typeof list === 'string') return { list: null, status: list, refetch }
    return {
      status: 'ok',
      list: list.map((a) => a.topics.list).reduce((a, b) => a.concat(b), []),
      refetch,
    }
  }, [list, refetch])
}

const topicFragment = gql`
  fragment topic on Topic {
    id
    name
    icon {
      id
      url
    }
    description(editable: true)
    shareLink(editable: true)
    availableInReflektor
    availableInEvaluation
  }
`

const topicQuery = gql`
  query adminTopic($id: GlobalID!) {
    node(id: $id) {
      ...topic
    }
  }
  ${topicFragment}
`

export const useAdminTopic = (id: string) => {
  const ret = useQuery<AdminTopicQuery, AdminTopicQueryVariables>(topicQuery, {
    variables: { id },
    skip: id === 'new',
  })
  if (id === 'new') {
    return {
      __typename: 'Topic' as 'Topic',
      id,
      name: '',
      icon: null,
      description: null,
      shareLink: '',
      availableInReflektor: true,
      availableInEvaluation: true,
    }
  }
  if (!ret) return null
  const { data } = ret
  if (
    !data ||
    !('node' in data) ||
    !data.node ||
    data.node.__typename !== 'Topic'
  )
    return null
  return data.node
}

const upsertTopicMutation = gql`
  mutation adminUpsertTopic($id: GlobalID, $data: TopicPatchInput!) {
    upsertTopic(id: $id, data: $data) {
      topic {
        ...topic
      }
    }
  }
  ${topicFragment}
`

export const useAdminUpsertTopicMutation = createUseMutation<
  AdminUpsertTopicMutation,
  AdminUpsertTopicMutationVariables
>(upsertTopicMutation, {
  refetchQueries: (v) => (v.id ? [] : [{ query: topicListQuery }]),
})

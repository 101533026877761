import React from 'react'
import { AdminLoginMutation, AdminLoginMutationFn } from 'admin/queries/user'

type Values = {
  email: string
  password: string
  passwordAgain: string
  register: boolean
}
type Errors = {
  global: null | string
  email: null | string
  password: null | string
  passwordAgain: null | string
}
type State = {
  values: Values
  errors: Errors
  result: null | string
}
type Children = (args: {
  submit: () => void
  values: Values
  change: {
    email: (v: string) => void
    password: (v: string) => void
    passwordAgain: (v: string) => void
    register: (v: boolean) => void
  }
  errors: Errors
  result: string | null
}) => React.ReactNode
class LoginContainerImpl extends React.Component<
  {
    children: Children
    login: AdminLoginMutationFn
  },
  State
> {
  state: State = {
    values: {
      email: '',
      password: '',
      passwordAgain: '',
      register: false,
    },
    errors: {
      global: null,
      email: null,
      password: null,
      passwordAgain: null,
    },
    result: null,
  }

  login = () => {
    const { values } = this.state
    this.props.login({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
      refetchQueries: ['adminMe'],
    })
  }

  submit = (evt?: any) => {
    if (evt && 'preventDefault' in evt) evt.preventDefault()
    this.login()
  }

  change = {
    email: (email: string) =>
      this.setState((st) => ({ values: { ...st.values, email } })),
    password: (password: string) =>
      this.setState((st) => ({ values: { ...st.values, password } })),
    passwordAgain: (passwordAgain: string) =>
      this.setState((st) => ({ values: { ...st.values, passwordAgain } })),
    register: (register: boolean) =>
      this.setState((st) => ({ values: { ...st.values, register } })),
  }

  render() {
    return this.props.children({
      submit: this.submit,
      values: this.state.values,
      errors: this.state.errors,
      change: this.change,
      result: this.state.result,
    })
  }
}

const AdminLoginContainer = ({ children }: { children: Children }) => (
  <AdminLoginMutation>
    {(login) => (
      <LoginContainerImpl login={login}>{children}</LoginContainerImpl>
    )}
  </AdminLoginMutation>
)
export default AdminLoginContainer

import Link from 'components/link'
import styled from '@emotion/styled'
import { useAdminPartyList } from 'admin/queries/party'

const ListItem = styled(Link)({
  display: 'block',
  padding: 5,
})

const AdminPartyList = () => {
  const list = useAdminPartyList()
  if (!list) return null
  return (
    <div>
      {list.map((item) => (
        <ListItem key={item.id} to={`/admin/party/${item.id}`}>
          {item.displayText
            ? `${item.displayText} (${item.abbreviation} - ${item.name})`
            : `${item.abbreviation} (${item.name})`}
        </ListItem>
      ))}
    </div>
  )
}
export default AdminPartyList

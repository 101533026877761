/** @jsx jsx */
import { jsx } from '@emotion/react'
import { CardBodyTwoColumn } from 'admin/components/card'
import { EditCard } from 'admin/components/edit-card'
import AdminInput from 'admin/components/admin-input'
import { useFormState } from 'admin/utils/use-form-state'
import { AdminCheckbox, AdminCheckboxGroup } from 'admin/components/checkbox'
import gql from 'graphql-tag'
import {
  useAdminPoliticianQuery,
  useAdminCreatePoliticianMutation,
  useAdminUpdatePoliticianMutation,
} from 'queries/queries'
import { useRef, useState } from 'react'
import {
  AdminImageInputContainer,
  AdminImageInput,
} from 'admin/components/file-input'
import { AdminLabelTextDiv } from 'admin/components/input-builder'
import { useHistory } from 'react-router-dom'
import Link from 'components/link'
import LoaderSpinner from 'components/loader-spinner'

gql`
  fragment AdminPoliticianFragment on Politician {
    id
    person {
      name
      surname
      gender

      image {
        id
        url
      }
    }
    statement
    partyOverride
    email
    slug
  }

  mutation AdminCreatePolitician($input: CreatePoliticianInput!) {
    createPolitician(input: $input) {
      politician {
        ...AdminPoliticianFragment
      }
    }
  }
  mutation AdminUpdatePolitician($input: UpdatePoliticianInput!) {
    updatePolitician(input: $input) {
      politician {
        ...AdminPoliticianFragment
      }
    }
  }
  query AdminPolitician($id: GlobalID!) {
    node(id: $id) {
      ...AdminPoliticianFragment
    }
  }
`
function clearImage(img: { url: string; id: string } | null) {
  return img ? { url: img.url, id: img.id } : null
}

export default function AdminUpsertPolitician({ id }: { id: string }) {
  const politicianData = useAdminPoliticianQuery({
    variables: { id },
    skip: id === 'new',
  })

  const history = useHistory()
  const politician =
    politicianData?.data?.node?.__typename === 'Politician'
      ? politicianData?.data?.node
      : id === 'new'
      ? {
          person: {
            name: '',
            surname: '',
            gender: 'female',
            image: null,
          },
          statement: '',
          partyOverride: '',
          email: '',
        }
      : null

  const formState = useFormState(
    !politician || politicianData.loading
      ? null
      : {
          name: politician.person.name,
          surname: politician.person.surname,
          male: politician.person.gender === 'male',
          image: clearImage(politician.person.image ?? null),
          email: politician.email || '',
          statement: politician.statement,
          partyOverride: politician.partyOverride ?? undefined,
        },
  )({
    converters: {
      image: (i) => i?.id ?? null,
    },
  })

  const [createMutation, createMutationResult] =
    useAdminCreatePoliticianMutation()
  const [updateMutation, updateMutationResult] =
    useAdminUpdatePoliticianMutation()

  const [validationError, setValidationError] = useState('')

  if (politicianData.loading) {
    return <LoaderSpinner />
  }
  if (formState.failed || !politician) return <>Politik nenalezen</>
  const { changed, patch, state } = formState

  const error =
    validationError || updateMutationResult.error || createMutationResult.error

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <EditCard
        title={id === 'new' ? 'Vložit politika' : 'Upravit politika'}
        changed={changed}
        saving={createMutationResult.loading || updateMutationResult.loading}
        onSave={() => {
          if (id === 'new') {
            const { name, surname, email, male, ...rest } = patch
            if (!name) setValidationError('Jméno je povinné')
            else if (!surname) setValidationError('Příjmení je povinné')
            else if (!email) setValidationError('Email je povinný')
            else
              createMutation({
                variables: {
                  input: {
                    ...rest,
                    name,
                    surname,
                    gender: male ? 'male' : 'female',
                    email,
                  },
                },
              }).then((v) => {
                const id = v.data?.createPolitician?.politician.id
                if (id) history.replace('/admin/politician/' + id)
              })
          } else {
            updateMutation({
              variables: {
                input: { ...patch, id },
              },
            })
          }
        }}
        hasError={!!error}
        CardBody={CardBodyTwoColumn}
      >
        <AdminInput label="Jméno" state={state.name} />
        <AdminInput label="Příjmení" state={state.surname} />
        <AdminImageInputContainer>
          <AdminLabelTextDiv>Obrázek</AdminLabelTextDiv>
          <AdminImageInput state={state.image} />
        </AdminImageInputContainer>
        <AdminInput label="Email" state={state.email} />
        <AdminInput label="Vyjádření" state={state.statement} />
        <AdminInput
          label="Přepsání stranické příslušnosti"
          state={state.partyOverride}
        />
        <AdminCheckboxGroup>
          <AdminCheckbox label="Muž" state={state.male} />
        </AdminCheckboxGroup>
        {'slug' in politician ? (
          <div>
            <div>
              <Link
                to={
                  'https://www.rekonstrukcestatu.cz/seznam-politiku/' +
                  politician.slug
                }
                style={{ textDecoration: 'underline' }}
              >
                Zobrazit v hodnocení politiků
              </Link>
            </div>
            <div>
              <Link
                to={
                  '/admin/db-explorer?nodes=' +
                  encodeURIComponent(politician.id)
                }
                style={{ textDecoration: 'underline' }}
              >
                Zobrazit v průzkumníku databáze
              </Link>
            </div>
          </div>
        ) : null}
      </EditCard>
    </>
  )
}

import { EditorView } from 'prosemirror-view'
import { createContext, PropsWithChildren, useContext } from 'react'

const ctx = createContext<EditorView | null>(null)
export function TextEditorViewContextProvider({
  view,
  children,
}: PropsWithChildren<{ view: EditorView }>) {
  return <ctx.Provider value={view}>{children}</ctx.Provider>
}

export function useTextEditorView() {
  const view = useContext(ctx)
  if (!view) throw new Error('no view')
  return view
}

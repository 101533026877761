/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Card,
  CardBodyTwoColumn,
  oneColumnMaxWidth,
} from 'admin/components/card'
import { EditCard } from 'admin/components/edit-card'
import AdminInput from 'admin/components/admin-input'
import { useHistory } from 'react-router-dom'
import { useFormState } from 'admin/utils/use-form-state'
import {
  useAdminReflektorUpdate,
  useReflektorUpsertUpdate,
} from 'admin/queries/reflektor-update'
import AdminSelect from 'admin/components/select'
import { DateTime } from 'luxon'
import AdminTextEditor, {
  compareAdminTextEditorValue,
} from 'admin/components/admin-text-editor'
import {
  AdminImageInputContainer,
  AdminImageInput,
} from 'admin/components/file-input'
import { AdminLabelTextDiv } from 'admin/components/input-builder'
import UpsertReflektorUpdatePoliticianList from './list-politician'
import AdminSendTestNotification from './send-test-notification'
import { AdminSelectTopic } from 'admin/components/admin-select-topic'
import { AdminCheckbox, AdminCheckboxGroup } from 'admin/components/checkbox'
import AdminDatePicker from 'admin/components/date-picker'
import LoaderSpinner from 'components/loader-spinner'

function clearImage(img: { url: string; id: string } | null) {
  return img ? { url: img.url, id: img.id } : null
}

function parseDate(v: string | null) {
  if (!v) return null
  const ret = DateTime.fromISO(v)
  if (ret.isValid) return ret
  return null
}

const AdminUpsertReflektorUpdate = ({ id }: { id: string }) => {
  const item = useAdminReflektorUpdate(id)

  const formState = useFormState(
    !item || item === 'loading'
      ? null
      : {
          title: item.title,
          titleGoodPoliticians: item.titleGoodPoliticians,
          titleNotGoodPoliticians: item.titleNotGoodPoliticians,
          linkHref: item.linkHref,
          linkLabel: item.linkLabel,
          leadImage: clearImage(item.leadImage ?? null),
          publishDate: parseDate(item.publishDate ?? null),
          type: item.type,
          endDate: item.endDate,
          summary: item.summary,
          text: item.text,
          topic: item.topic ? item.topic.id : null,
          onlyCorrespondingEmoji: item.onlyCorrespondingEmoji,
          archive: item.archive,
        },
  )({
    converters: {
      leadImage: (v) => (v ? v.id : null),
      publishDate: (v) => (v ? v.toISO() : null),
    },
    comparators: {
      text: compareAdminTextEditorValue,
      summary: compareAdminTextEditorValue,
    },
  })

  const history = useHistory()
  const { mutate, error, mutating } = useReflektorUpsertUpdate({
    onSuccess: (v) => {
      if (id === 'new') {
        if (v.reflektorUpsertUpdate) {
          console.log(v.reflektorUpsertUpdate.update.id)
          history.replace(
            `/admin/reflektor-update/${v.reflektorUpsertUpdate.update.id}`,
          )
        }
      }
    },
    onError: () => {},
  })

  if (item === 'loading') return <LoaderSpinner />
  if (!item) return <div>Výzva reflektoru nenalezena</div>
  if (formState.failed) return null

  const { state, changed, patch } = formState

  function calculatePatch() {
    if (patch.type === 'info') {
      return { ...patch, politicians: [] }
    }
    return patch
  }

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <EditCard
        title={
          id === 'new'
            ? 'Vložit novou výzvu reflektoru'
            : 'Upravit výzvu reflektoru'
        }
        changed={changed}
        saving={mutating}
        onSave={() => {
          mutate({
            variables: {
              id: id === 'new' ? undefined : id,
              data: calculatePatch(),
            },
          })
        }}
        CardBody={CardBodyTwoColumn}
      >
        <AdminInput label="Nadpis" state={state.title} />
        <AdminInput
          label="Nadpis seznamu poslanců (souhlas s ReSt)"
          state={state.titleGoodPoliticians}
        />
        <AdminInput
          label="Nadpis seznamu poslanců (nesouhlas s ReSt)"
          state={state.titleNotGoodPoliticians}
        />
        <AdminInput
          label="Cíl odkazu v patičce výzvy"
          state={state.linkHref}
          placeholder="Pokud je ponecháno prázdné tak se použije odkaz na dané hlasování"
        />
        <AdminInput
          label="Text odkazu v patičce výzvy"
          state={state.linkLabel}
        />
        <AdminSelectTopic
          topic={state.topic}
          filter={(t) => t.availableInReflektor}
        />
        <AdminSelect
          label="Typ"
          state={state.type}
          options={[
            { value: null, display: 'Vyberte typ ' },
            { value: 'info', display: 'Informační' },
            { value: 'mail', display: 'Mailovací' },
            { value: 'vote', display: 'Hlasovací' },
          ]}
        />
        <AdminImageInputContainer>
          <AdminLabelTextDiv>Úvodní obrázek</AdminLabelTextDiv>
          <AdminImageInput state={state.leadImage} />
        </AdminImageInputContainer>
        <AdminDatePicker label="Datum publikace" state={state.publishDate} />
        <AdminSendTestNotification id={id} />
        <AdminCheckboxGroup>
          <AdminCheckbox state={state.archive} label="Archivní výzva" />
          <AdminCheckbox
            state={state.onlyCorrespondingEmoji}
            label="Pouze odpovídající emoji"
          />
        </AdminCheckboxGroup>
        <AdminTextEditor
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            @media (max-width: ${oneColumnMaxWidth}) {
              grid-column-end: 2;
            }
          `}
          label="Shrnutí"
          state={state.summary}
        />
        <AdminTextEditor
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            @media (max-width: ${oneColumnMaxWidth}) {
              grid-column-end: 2;
            }
          `}
          label="Text po rozbalení"
          state={state.text}
        />
      </EditCard>
      <UpsertReflektorUpdatePoliticianList
        enabled={state.type.value !== 'info'}
        updateId={item.id}
        list={item.politicians.list}
        poll={item.poll ?? null}
      />

      <Card title="Náhled">
        Výzvy reflektoru zobrazujeme pouze v appce, takže náhled pro webovou
        administraci nemáme
      </Card>
    </>
  )
}
export default AdminUpsertReflektorUpdate

/** @jsx jsx */
import React, {
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
} from 'react'
import { css, Global, jsx, PropsOf } from '@emotion/react'
import * as bariolBoldF from './fonts/bariol-bold'
import * as bariolBoldItF from './fonts/bariol-bold-italic'
import * as bariolLightF from './fonts/bariol-light'
import * as bariolLightItF from './fonts/bariol-light-italic'
import * as bariolRegularF from './fonts/bariol-regular'
import * as bariolRegularItF from './fonts/bariol-regular-italic'
import * as bariolThinF from './fonts/bariol-thin'
import * as bariolThinItF from './fonts/bariol-thin-italic'

const fontContext = React.createContext(
  null as null | {
    dispatch: (id: string) => void
  },
)

const cssMap = new Map<string, ReturnType<typeof css>>()

function reducer(
  prevState: {
    [id: string]: boolean
  },
  id: string,
) {
  if (prevState[id]) return prevState
  return {
    ...prevState,
    [id]: true,
  }
}

export const FontProvider: React.FC<{}> = ({ children }) => {
  const dataRef = useRef({})
  const [state, dispatch] = useReducer(reducer, dataRef.current)
  const ctx = useMemo(
    () => ({
      dispatch,
    }),
    [],
  )
  return (
    <React.Fragment>
      <Global
        styles={Object.keys(state)
          .filter((k) => state[k])
          .map((id) => cssMap.get(id))}
      />
      <fontContext.Provider value={ctx}>{children}</fontContext.Provider>
    </React.Fragment>
  )
}

function font(
  theFont: { woff: string; woff2: string },
  family: { name: string; fallback: string },
  weight: 100 | 200 | 300 | 400 | 500 | 600 | 700,
  style: 'normal' | 'italic',
) {
  const id = `${family.name}:${weight}:${style}`
  cssMap.set(
    id,
    css`
      @font-face {
        font-family: ${family.name};
        src: url('${theFont.woff2}') format('woff2'),
          url('${theFont.woff}') format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: swap;
      }
    `,
  )

  const styles = {
    fontFamily: `${family.name}`,
    fontStyle: style,
    fontWeight: weight,
  }
  return function useFont() {
    const ctx = useContext(fontContext)
    if (!ctx) throw new Error('Missing FontProvider')
    const { dispatch } = ctx
    useEffect(() => {
      dispatch(id)
    }, [dispatch])
    return styles
  }
}

const b = { name: 'Bariol', fallback: 'sans-serif' }
export const useBariolBold = font(bariolBoldF, b, 700, 'normal')
export const useBariolBoldItalic = font(bariolBoldItF, b, 700, 'italic')
export const useBariolLight = font(bariolLightF, b, 300, 'normal')
export const useBariolLightItalic = font(bariolLightItF, b, 300, 'italic')
export const useBariol = font(bariolRegularF, b, 400, 'normal')
export const useBariolItalic = font(bariolRegularItF, b, 400, 'italic')
export const useBariolThin = font(bariolThinF, b, 100, 'normal')
export const useBariolThinItalic = font(bariolThinItF, b, 100, 'italic')

import styled from '@emotion/styled'
import {
  adminInputWrapperStyle,
  textElementStyle,
  AdminLabelTextDiv,
} from 'admin/components/input-builder'
import { useAdminReflektorUpdateSendTestNotificationMutation } from 'admin/queries/notification'
import { useState } from 'react'

const Label = styled('label')`
  ${adminInputWrapperStyle};
  display: block;
  div {
    height: 23px;
  }
  select {
    all: unset;
    ${textElementStyle};
    width: 100%;
    :focus {
      border-color: black;
    }
  }
`

export default function AdminSendTestNotification({ id }: { id: string }) {
  const [status, setStatus] = useState('')
  const mut = useAdminReflektorUpdateSendTestNotificationMutation({
    onSuccess(r) {
      setStatus(
        r.reflektorUpdateSendTestNotification || 'Odesílání notifikace selhalo',
      )
    },
    onError(err) {
      console.error(err)
      setStatus('Odesílání notifikace selhalo')
    },
  })
  if (id === 'new') return null
  return (
    <Label>
      <AdminLabelTextDiv>Testovací notifikace</AdminLabelTextDiv>
      <button
        type="button"
        onClick={() => {
          console.log('click')
          setStatus('')
          mut.mutate({ variables: { id } })
        }}
      >
        Poslat mi notifikaci
      </button>
      {mut.mutating ? 'Odesílám...' : status}
    </Label>
  )
}

import React from 'react'
import styled from '@emotion/styled'
import {
  textElementStyle,
  AdminLabelTextDiv,
  adminInputWrapperStyle,
} from './input-builder'
import { DateTime } from 'luxon'
import { DatePicker } from '@material-ui/pickers'

const Wrapper = styled('div')`
  ${adminInputWrapperStyle};
  display: block;
  div:first-of-type {
    height: 23px;
  }
`

const Div = styled('div')`
  all: unset;
  ${textElementStyle};
  border-color: transparent;
  display: block;
  width: 100%;
  :focus {
    border-color: black;
  }
`

const AdminDatePicker = ({
  label,
  state,
}: {
  label: string
  state: { change: (v: DateTime | null) => void; value: DateTime | null }
}) => (
  <Wrapper>
    <AdminLabelTextDiv>{label}</AdminLabelTextDiv>
    <Div>
      <DatePicker
        label=""
        value={state.value}
        onChange={state.change}
        cancelLabel={false}
        disableFuture={true}
        format="d. MMMM yyyy"
        InputProps={{ value: state.value?.toFormat('d. MMMM yyyy') ?? '' }}
      />
      {state.value ? (
        <button
          onClick={(evt) => {
            evt.preventDefault()
            state.change(null)
          }}
        >
          Vymazat
        </button>
      ) : (
        <button
          onClick={(evt) => {
            evt.preventDefault()
            state.change(DateTime.utc())
          }}
        >
          Nastavit na dnešek
        </button>
      )}
    </Div>
  </Wrapper>
)
export default AdminDatePicker

import React, { useEffect, useRef } from 'react'
import ErrorBoundary from 'containers/error-boundary'
import { createUploadLink } from 'apollo-upload-client'
import { Router, useLocation } from 'react-router'
import { createBrowserHistory } from 'history'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import OutlineHandler from '../utils/outline-handler'
import { UseApolloUriProvider } from 'utils/use-apollo-uri'
import { EnvProvider } from 'routes/routes'
import { FontProvider } from 'utils/fonts'
import { AppRegistry } from 'react-native'
import App from '../app'
import ft from '../queries/fragment-types-generated.json'

const browserHistory = createBrowserHistory()

//const uri = 'https://staging.rekonstrukcestatu.cz/graphql'
const uri = '/graphql'

/* global document */
const createLink = (credentials = 'include') =>
  createUploadLink({
    credentials,
    uri,
  })

const client = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes: ft.possibleTypes }),
  link: createLink(),
})

;(() => {
  if (window.location.search === '?end') {
    return
  }
  const app = document.createElement('div')
  document.body.appendChild(app)
  app.id = 'app'
  AppRegistry.registerComponent('App', () => () => (
    <ErrorBoundary>
      <EnvProvider
        value={{
          hosttype: 'admin',
          env:
            window.location.hostname === 'admin.rekonstrukcestatu.cz'
              ? 'production'
              : window.location.hostname ===
                'admin.staging.rekonstrukcestatu.cz'
              ? 'staging'
              : 'development',
        }}
      >
        <ApolloProvider client={client}>
          <UseApolloUriProvider value={uri}>
            <Router history={browserHistory}>
              <OutlineHandler />
              <ScrollTopOnNavigate />
              <FontProvider>
                <App />
              </FontProvider>
            </Router>
          </UseApolloUriProvider>
        </ApolloProvider>
      </EnvProvider>
    </ErrorBoundary>
  ))
  AppRegistry.runApplication('App', {
    initialProps: {},
    rootTag: app,
  })
})()

function ScrollTopOnNavigate() {
  const location = useLocation()
  const lastPath = useRef(location.pathname)
  useEffect(() => {
    if (lastPath.current !== location.pathname) {
      lastPath.current = location.pathname
      window.scrollTo({ top: 0, behavior: 'auto' })
    }
  })
  return null
}

import React, { ComponentType } from 'react'
import ErrorBoundary from './components/error-boundary'
import Routes from './routes/routes'

export default function AdminRoute() {
  return (
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  )
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { CardBodyTwoColumn, Card } from 'admin/components/card'
import { EditCard } from 'admin/components/edit-card'
import AdminInput from 'admin/components/admin-input'
import { useHistory } from 'react-router-dom'
import { useFormState } from 'admin/utils/use-form-state'
import {
  useAdminPollEvaluation,
  useAdminUpsertPollEvaluationMutation,
} from 'admin/queries/poll-evaluation'
import InsertPoliticiansFromPoll from './upsert-reflektor-update/insert-politicians-from-poll'
import { useAdminPollById } from 'admin/queries/reflektor-update'
import LoaderSpinner from 'components/loader-spinner'
import { AdminSelectTopic } from 'admin/components/admin-select-topic'
import { AdminCheckboxGroup, AdminCheckbox } from 'admin/components/checkbox'
import { useBariolItalic } from 'utils/fonts'
import { SingleTwemoji } from 'components/twemoji'
import AdminButton from 'admin/components/admin-button'
import gql from 'graphql-tag'
import { useDeletePollEvalMutation } from 'queries/queries'
import Raven from 'raven-js'

gql`
  mutation deletePollEval($id: GlobalID!) {
    deletePollEval(evaluation: $id)
  }
`

const AdminUpsertPollEval = ({ id }: { id: string }) => {
  const italic = useBariolItalic()
  const pollEval = useAdminPollEvaluation(id)
  const [deletePollEval, deleteInfo] = useDeletePollEvalMutation()

  const formState = useFormState(
    pollEval && {
      poll: pollEval.poll ? pollEval.poll.id : undefined,
      topic: pollEval.topic ? pollEval.topic.id : null,
      weight: `${pollEval.weight}`,
      description: pollEval.description || '',
      standpoint: pollEval.standpoint,
      positive: pollEval.positive,
    },
  )({
    converters: {
      description: (v) => v || null,
      weight: (v) => {
        const r = Number.parseInt(v.replace(/ /g, ''), 10)
        if (
          !/^[0-9 ]+?$/.test(v) ||
          !Number.isFinite(r) ||
          !Number.isInteger(r) ||
          r > 100 ||
          r < 0
        ) {
          throw new Error('musí být číslo mezi 0 a 100')
        }
        return r
      },
    },
  })

  const history = useHistory()
  const { mutate, error, mutating } = useAdminUpsertPollEvaluationMutation({
    onSuccess: (v) => {
      if (id === 'new') {
        if (v.upsertPollEvaluation) {
          history.replace(
            `/admin/poll-eval/${v.upsertPollEvaluation.pollEvaluation.id}`,
          )
        }
      }
    },
    onError: () => {},
  })

  const poll = useAdminPollById(
    (formState.failed ? null : formState.state.poll.value) || null,
  )
  console.log(poll)

  if (formState.failed) return <>Vyhodnocení nenalezeno</>
  const { patch, state } = formState

  if (!state.poll.value) {
    return (
      <InsertPoliticiansFromPoll
        onChange={(_, pollId, positive) => {
          state.poll.change(pollId || undefined)
          state.positive.change(positive)
        }}
        openOverride={true}
      />
    )
  }

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <EditCard
        title={
          id === 'new'
            ? 'Přidat hodnocení hlasování'
            : 'Upravit hodnocení hlasování'
        }
        changed={formState.changed}
        saving={mutating || deleteInfo.loading}
        hasError={formState.hasError}
        onSave={() => {
          mutate({
            variables: {
              id: id === 'new' ? undefined : id,
              data: id === 'new' ? formState.fullSubmit : formState.patch,
            },
            extra: {
              topic: pollEval && pollEval.topic ? pollEval.topic.id : null,
            },
          })
        }}
        CardBody={CardBodyTwoColumn}
      >
        <AdminInput state={state.poll} label="Hlasování" disabled />
        <AdminSelectTopic
          topic={state.topic}
          filter={(t) => t.availableInEvaluation}
        />
        <AdminInput state={state.weight} label="Váha (%)" />
        <AdminInput
          state={state.description}
          label="Popis"
          placeholder="Pokud je ponecháno prázdné použije se oficiální popis"
        />
        <AdminInput state={state.standpoint} label="Stanovisko" />
        <AdminCheckboxGroup>
          <AdminCheckbox state={state.positive} label="Pozitivní" />
          <div css={[italic, { paddingLeft: 10, alignSelf: 'center' }]}>
            Pokud je hlasování neutrální vyplňte váhu 0 a tato hodnota je
            ignorována
          </div>
        </AdminCheckboxGroup>
        {pollEval ? (
          <div>
            <AdminButton
              type="button"
              css={{ border: '3px solid #aaa', fontSize: '1em' }}
              onClick={(evt) => {
                evt.preventDefault()
                if (!confirm('Smazat "' + state.description.value + '"?'))
                  return
                deletePollEval({
                  variables: { id: pollEval.id },
                })
                  .then(() => {
                    history.replace('/admin/poll-eval/list')
                  })
                  .catch((e) => {
                    console.warn(e)
                    Raven.captureException(e)
                  })
              }}
            >
              Smazat{' '}
              {pollEval.committeePoll
                ? 'výborové hlasování'
                : 'hodnocení plenárního hlasování'}
            </AdminButton>
            {deleteInfo.error?.message || ''}
          </div>
        ) : null}
      </EditCard>
      <Card title="Hlasování">
        {poll.fetching && <LoaderSpinner />}
        {poll.data &&
          poll.data.votes.list.map((vote) => (
            <div key={vote.id}>
              <span title={vote.vote}>
                <SingleTwemoji
                  emoji={
                    {
                      for: '👍',
                      not_present: '👻',
                      abstained:
                        vote.submandate.mandate.politician.person.gender ===
                        'male'
                          ? '🤷‍♂️'
                          : '🤷‍♀️',
                      against: '👎',
                      secret: '🤫',
                      excused: '🙇',
                    }[vote.vote]
                  }
                  css={{ fontSize: '2em', verticalAlign: 'middle' }}
                />
              </span>{' '}
              {vote.submandate.mandate.politician.person.name}{' '}
              {vote.submandate.mandate.politician.person.surname}
            </div>
          ))}
      </Card>
    </>
  )
}
export default AdminUpsertPollEval

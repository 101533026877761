/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useReducer, useEffect } from 'react'
import AdminInput from 'admin/components/admin-input'
import { DateTime } from 'luxon'
import {
  useAdminPollsAtDate,
  useAdminPollById,
  useAdminPollDates,
} from 'admin/queries/reflektor-update'
import Link from 'components/link'
import { LocalizedDate } from 'components/localized-date'

type Politician = {
  id: string
  good: boolean
  isAboveFold: boolean
}
type OnChange = (
  v: Politician[],
  pollId: string | null,
  positive: boolean,
) => void

type Action =
  | { type: 'open' | 'close' }
  | { type: 'date'; value: string }
  | { type: 'choose'; pollId: string; positive: boolean }
  | { type: 'done'; list: Politician[] }

const initialState = { open: false, date: '', pollId: '', positive: false }
type State = typeof initialState

function reducer(onChange: OnChange) {
  return (state: State, action: Action) => {
    if (action.type === 'open') return { ...state, open: true }
    if (action.type === 'close') return initialState
    if (action.type === 'date') return { ...state, date: action.value }
    if (action.type === 'choose')
      return { ...state, pollId: action.pollId, positive: action.positive }
    if (action.type === 'done') {
      onChange(
        state.positive
          ? action.list
          : action.list.map((itm) => ({ ...itm, good: !itm.good })),
        state.pollId,
        state.positive,
      )
      return initialState
    }

    return state
  }
}

function coerceDate(date: string | null) {
  if (!date) return null
  const v = DateTime.fromFormat(date.replace(/ /g, ''), 'd.M.yyyy', {
    zone: 'UTC',
  })
  if (!v.isValid) return null
  return v.toISO()
}

function Dates({ onSelect }: { onSelect: (date: DateTime) => void }) {
  const pollDates = useAdminPollDates()
  if (!pollDates.data) return null
  return (
    <div>
      {pollDates.data.slice(0, 10).map(({ date, count }) => (
        <a
          key={date}
          css={{
            marginRight: 5,
            whiteSpace: 'nowrap',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={(evt) => {
            evt.preventDefault()
            onSelect(DateTime.fromISO(date))
          }}
        >
          <LocalizedDate date={DateTime.fromISO(date)} /> ({count})
        </a>
      ))}
    </div>
  )
}

export default function InsertPoliticiansFromPoll({
  onChange,
  openOverride,
}: {
  onChange: OnChange
  openOverride?: boolean
}) {
  const [state, dispatch] = useReducer(reducer(onChange), initialState)
  const polls = useAdminPollsAtDate(coerceDate(state.date))
  const poll = useAdminPollById(state.pollId)

  useEffect(() => {
    if (poll.data) {
      dispatch({
        type: 'done',
        list: poll.data.votes.list.map((v) => ({
          id: v.submandate.mandate.politician.id,
          good: v.vote === 'for',
          isAboveFold: false,
        })),
      })
    }
  })

  const handler = (action: Action) => (evt: any) => {
    evt.preventDefault()
    dispatch(action)
  }

  if (openOverride !== undefined ? !openOverride : !state.open) {
    return (
      <div>
        <button onClick={handler({ type: 'open' })}>
          Vložit politiky z hlasování
        </button>
      </div>
    )
  }

  return (
    <div css={{ border: '1px solid' }}>
      {openOverride ? null : (
        <button onClick={handler({ type: 'close' })}>Zrušit</button>
      )}
      {/* example: 4. 12. 2013 */}
      <AdminInput
        label="Datum (dd. mm. yyyy)"
        state={{
          change: (value) => dispatch({ type: 'date', value }),
          value: state.date,
        }}
      />
      <Dates
        onSelect={(date) => {
          dispatch({ type: 'date', value: date.toFormat('dd. MM. yyyy') })
        }}
      />

      {poll.fetching ? (
        'Načítám data o hlasování'
      ) : (
        <>
          {polls.loading ? 'Načítám seznam hlasování' : null}
          {polls.data &&
            polls.data.polls.list.map((p) => (
              <div
                key={p.id}
                css={{ border: '1px solid gray', display: 'flex' }}
              >
                <div css={{ alignSelf: 'center', margin: 10 }}>
                  <button
                    onClick={handler({
                      type: 'choose',
                      pollId: p.id,
                      positive: true,
                    })}
                    css={{ marginRight: 5 }}
                  >
                    👍
                  </button>
                  <button
                    onClick={handler({
                      type: 'choose',
                      pollId: p.id,
                      positive: false,
                    })}
                  >
                    👎
                  </button>
                </div>
                <div css={{ alignSelf: 'center' }}>
                  <div>
                    {p.electionPeriod.isSenate
                      ? 'Senát'
                      : 'Poslanecká sněmovna'}
                  </div>
                  {p.context ? <div>kontext: {p.context}</div> : null}
                  {(p.description || '').trim() ? (
                    <div>popis: {p.description}</div>
                  ) : null}
                  {!p.importId ? null : p.url ? (
                    <Link css={{ textDecoration: 'underline' }} to={p.url}>
                      ID: {p.importId}
                    </Link>
                  ) : (
                    <div>ID: {p.importId}</div>
                  )}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  )
}

import gql from 'graphql-tag'

export const idSet = new Set([
  'EvActivityGroup',
  'Topic',
  'EvActivity',
  'EvSubmandate',
  'EvMandate',
  'Politician',
  'EvPartyMembership',
  'EvParlamentaryGroup',
  'EvParty',
  'EvParlamentaryGroupMembership',
])

gql`
  query AdminDbExplorer($id: GlobalID!) {
    node(id: $id) {
      __typename
      ... on ActivityGroup {
        id
        isPositive
        topic {
          id
        }
        activities {
          list {
            id
          }
        }
      }
      ... on Topic {
        id
        name
        icon {
          id
          sha256
          mime
          url
          size
          imageMetadata {
            width
            height
          }
        }
        shareLink
        availableInReflektor
        availableInEvaluation
        description_1: description
        reflektorUpdates {
          list {
            id
          }
        }
        reflektorHot
      }

      ... on Activity {
        id
        submandates {
          list {
            id
          }
        }
        description
        weight
      }
      ... on Submandate {
        id
        start
        end
        mandate {
          id
        }
        electionPeriod {
          id
        }
        votes {
          list {
            id
          }
        }
        parlamentaryGroupMemberships {
          list {
            id
            start
            end
            group {
              id
            }
            submandate {
              id
            }
          }
        }
      }
      ... on Mandate {
        id
        chamber
        start
        end
        region {
          id
        }
        politician {
          id
        }
        submandates {
          list {
            id
          }
        }
      }
      ... on Politician {
        id
        person {
          id
        }
        caption
        senateImportId
        lowerChamberImportId
        partyMemberships {
          list {
            id
          }
        }
        mandates {
          list {
            id
          }
        }
        evaluation {
          id
        }
        dead
        filteredEvaluations {
          list {
            id
          }
        }
        email
        parlamentaryGroup {
          id
        }
        reflektorTitleOverride
        resolvedPartyAbbreviation
        slug
      }
      ... on PartyMembership {
        id
        politician {
          id
        }
        party {
          id
        }
        start_1: start
        end
        function
      }
      ... on ParlamentaryGroup {
        id
        start
        end
        name
        abbreviation
        party_1: party {
          id
        }
      }
      ... on Party {
        id
        color
        contrastingColor
        logo {
          id
        }
        abbreviation
        name_1: name
        partyMemberships {
          list {
            id
          }
        }
        displayText
        availableInReflektor
      }
      ... on ParlamentaryGroupMembership {
        id
        start
        end
        group {
          id
        }
        submandate {
          id
        }
      }
    }
  }
`

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { WorkBook, Sheet } from 'xlsx/types'
import { useXLSX } from '../import-utils'
import { useMemo, useReducer, useEffect, useRef } from 'react'
import { Tooltip } from '@material-ui/core'

type ColumnId =
  | 'group'
  | 'proposer'
  | 'topic'
  | 'description'
  | 'finalWeight'
  | 'date'

export type ColumnMap = { [id in ColumnId]: string }

const columnNameData: {
  id: ColumnId
  label: string
  tooltip?: string
  defaultMatch?: ((v: string) => boolean) | string
}[] = [
  {
    id: 'group',
    label: 'Skupina aktivit',
    tooltip:
      'Sloupec pomocí kterého jde několik souvisejících jednotlivých aktivit sloučit pod jednu a tím nenafukovat potenciální maximum',
    defaultMatch: (h) => h === 'Číslo kroku',
  },
  {
    id: 'proposer',
    label: 'Osoba/y účastné dané aktivity',
    defaultMatch: 'Předkladatel',
  },
  {
    id: 'topic',
    label: 'Téma',
    defaultMatch: (h) => ['zákon', 'téma'].includes(h),
  },
  { id: 'description', label: 'Popis', defaultMatch: 'Popis' },
  {
    id: 'finalWeight',
    label: 'Váha se znamínkem',
    tooltip: 'V rozsahu -1 až 1 (tj. ne v procentech)',
    defaultMatch: 'index',
  },
  { id: 'date', label: 'datum', defaultMatch: 'datum' },
]

function defaultState(header: string[]): { [id in ColumnId]: string } {
  const ret = {} as any
  for (const c of columnNameData) {
    const { defaultMatch } = c
    if (typeof defaultMatch === 'function')
      ret[c.id] = header.find(defaultMatch)
    else if (typeof defaultMatch === 'string')
      ret[c.id] = header.find(
        (v) =>
          typeof v === 'string' &&
          v.toLocaleLowerCase() === defaultMatch.toLocaleLowerCase(),
      )
    else ret[c.id] = ''
  }
  return ret
}

function check(columnMap: ColumnMap): string | false {
  const allColumnNamesSelected = !Object.values(columnMap).some((t) => !t)
  const noDuplicateColumns = !Object.values(columnMap).some(
    (v, i, a) => a.indexOf(v) !== i,
  )
  return !noDuplicateColumns
    ? 'Vybral jsi stejný sloupec vícekrát'
    : !allColumnNamesSelected
    ? 'Musíš vybrat všechny sloupce'
    : false
}

export function ActivityImportSelectColumns({
  sheet,
  onChange,
}: {
  sheet: Sheet
  onChange: (v: { columnMap: ColumnMap; ok: boolean }) => void
}) {
  const xlsx = useXLSX()
  const header = useMemo(
    () =>
      Object.keys(sheet)
        .filter((cellName) => /^[a-z]+1$/i.test(cellName))
        .sort()
        .map((headerCell) => sheet[headerCell]!.v)
        .filter((v) => !!v),
    [sheet],
  )
  const headersAreNotUnique = header.some(
    (v, idx, arr) => arr.indexOf(v) !== idx,
  )
  const [stateColumnMap, dispatch] = useReducer(
    (
      state: { [c in ColumnId]: string } | null,
      action: { column: ColumnId; value: string },
    ): ColumnMap | null => {
      const res = {
        ...(state || defaultState(header)),
        [action.column]: action.value,
      }
      setImmediate(() => onChange({ columnMap: res, ok: check(res) === false }))
      return res
    },
    null,
  )

  const columnMap = stateColumnMap || defaultState(header)
  const justMounted = useRef(true)
  const err = check(columnMap)
  useEffect(() => {
    if (stateColumnMap === null && justMounted.current) {
      onChange({ columnMap, ok: err === false })
    }
    justMounted.current = false
  }, [columnMap, err, onChange, stateColumnMap])

  const columnOptions = header.map((head) => (
    <option key={head} value={head}>
      {head}
    </option>
  ))
  function columnSelect(id: ColumnId) {
    return (
      <select
        value={columnMap[id]}
        onChange={(evt) => dispatch({ column: id, value: evt.target.value })}
        css={{ background: 'white', color: 'black' }}
      >
        <option value="">Vyberte</option>
        {columnOptions}
      </select>
    )
  }

  if (headersAreNotUnique) return <div>Nadpisy sloupců musí být unikátní</div>

  return (
    <>
      <div>
        3. Výběr jmen sloupců
        {columnNameData.map((c) => (
          <div key={c.id}>
            {c.tooltip ? (
              <Tooltip title={c.tooltip}>
                <span>{c.label}</span>
              </Tooltip>
            ) : (
              <span>{c.label}</span>
            )}{' '}
            {columnSelect(c.id)}
          </div>
        ))}
        {err || 'Sloupce vybrány :-)'}
      </div>
    </>
  )
}

import { Plugin, EditorState, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { cloudUpload } from './cloud-upload'

function px(v?: number) {
  if (v) return `${v}px`
  return ''
}

export const placeholderBackground = '#aaa'

export const placeholderPlugin = new Plugin({
  key: new PluginKey('placeholder-plugin'),
  state: {
    init() {
      return DecorationSet.empty
    },
    apply(tr, _set) {
      // Adjust decoration positions to changes made by the transaction
      let set: DecorationSet = _set.map(tr.mapping, tr.doc)
      // See if the transaction adds or removes any placeholders
      const action = tr.getMeta(this as any)
      if (action && action.add && action.add.id) {
        const widget = document.createElement('placeholder')
        widget.style.width = px(action.add.width)
        widget.style.height = px(action.add.height)
        widget.style.backgroundColor = placeholderBackground
        widget.style.display = 'inline-block'
        widget.style.position = 'relative'
        const div = document.createElement('div')
        div.classList.add('cloud-upload')
        div.appendChild(document.createElement('i'))
        widget.appendChild(div)

        const deco = Decoration.widget(action.add.pos, widget, {
          id: action.add.id,
        } as any)
        set = set.add(tr.doc, [deco])
      } else if (action && action.remove) {
        set = set.remove(
          set.find(
            undefined,
            undefined,
            (spec) => spec.id === action.remove.id,
          ),
        )
      }
      return set
    },
  },
  props: {
    decorations(state) {
      return (this as any).getState(state)
    },
  },
})

export function findPlaceholder(state: EditorState, id: {}) {
  const decos = placeholderPlugin.getState(state)
  const found = decos.find(null, null, (spec: any) => spec.id === id)
  return found.length ? found[0].from : null
}

import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const AdminLabelTextDiv = styled.div`
  height: 23px;
`

export const adminInputWrapperStyle = css`
  height: 68px;
`

export const textElementStyle = css`
  box-sizing: border-box;
  padding: 7px;
  font-size: 1.1em;
  border: 3px solid #aaa;
  height: 45px;
`

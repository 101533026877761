/** @jsx jsx */
import { jsx } from '@emotion/react'
import { XLSXProvider } from '../import-utils'
import { Card, CardBase, CardHead } from 'admin/components/card'
import { ImporterHelp } from '../importer-help'
import { useSheetSelector } from '../sheet-selector'
import { useCallback, useMemo, useState } from 'react'
import { CommitteeSheetDescription } from './sheet-description'
import { SheetPreview } from './sheet-preview'
import { DateTime } from 'luxon'
import { notNull } from '@codewitchbella/ts-utils'
import { useMapTopic } from '../use-map-topic'
import { useGetSubmandate } from '../use-get-submandate'
import LoaderSpinner from 'components/loader-spinner'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client/react/hooks'
import { Raven } from 'utils/globals'
import { SingleTwemoji } from 'components/twemoji'
import {
  AdminImportCommitteeVoteCreateMutation,
  AdminImportCommitteeVoteCreateMutationVariables,
  CommitteePollEvalInputVote,
} from 'queries/queries'

function useCreateVote() {
  const mut = gql`
    mutation AdminImportCommitteeVoteCreate($data: CommitteePollEvalInput!) {
      createCommitteePollEval(data: $data)
    }
  `
  return useMutation<
    AdminImportCommitteeVoteCreateMutation,
    AdminImportCommitteeVoteCreateMutationVariables
  >(mut)
}

function checkResult(
  v: string,
): v is 'schváleno' | 'zamítnuto' | 'schválen' | 'zamítnut' {
  return ['schváleno', 'zamítnuto', 'schválen', 'zamítnut'].includes(v)
}

type Vote = 'ANO' | 'NE' | '@' | 'zdržel se' | 'omluven'
function checkVote(v: string): v is Vote {
  return ['ANO', 'NE', '@', 'zdržel se', 'omluven'].includes(v)
}

function normalizeVote(v: string): Vote | 'invalid' {
  v = v.trim()
  const normalizer: { [name: string]: Vote } = {
    ANO: 'ANO',
    NE: 'NE',
    '@': '@',
    nepřítomen: '@',
    nepřítomna: '@',
    'zdržel se': 'zdržel se',
    'zdržela se': 'zdržel se',
    omluven: 'omluven',
    omluvena: 'omluven',
  }
  if (!(v in normalizer)) {
    return 'invalid'
  }
  return normalizer[v]
}

function isString(v: any): v is string {
  return typeof v === 'string'
}

function CommitteeVoteInsert({
  result,
}: {
  result: {
    topic: GqlScalarGlobalID
    isPositive: boolean
    date: DateTime
    description: string
    result: 'accepted' | 'rejected'
    standpoint: string
    weight: number
    votes: readonly CommitteePollEvalInputVote[]
  }[]
}) {
  const [state, setStatus] = useState<null | JSX.Element>(null)
  const [insert] = useCreateVote()
  async function doInsert() {
    try {
      let i = 0
      for (const r of result) {
        i += 1
        setStatus(
          <>
            Vkládám {i}/{result.length}
          </>,
        )
        await insert({
          variables: {
            data: {
              topic: r.topic,
              isPositive: r.isPositive,
              date: r.date.toISO(),
              description: r.description,
              result: r.result,
              standpoint: r.standpoint,
              votes: r.votes.map((vote) => ({
                vote: vote.vote,
                submandate: vote.submandate,
              })),
              weight: r.weight,
            },
          },
        })
      }
      setStatus(<>Přepočítávám hodnocení</>)
      await new Promise((r) => setTimeout(r, 750))
      const ret = await fetch('/api/recalculate-evaluation', { method: 'POST' })
      const json = await ret.json()
      if (json.success) {
        setStatus(
          <>
            Hotovo! <SingleTwemoji emoji="🎉" />
          </>,
        )
      } else {
        console.log(json)
        setStatus(
          <>
            Import úspěšný <SingleTwemoji emoji="🎉" /> ale hodnotící script
            selhal <SingleTwemoji emoji="😢" />
          </>,
        )
      }
    } catch (e) {
      console.error(e)
      if (Raven) Raven.captureException(e)
      setStatus(<>Nastala chyba</>)
    }
  }
  return (
    <Card title="Vložit do databáze">
      {state !== null ? (
        state
      ) : (
        <>
          <button type="button" onClick={doInsert}>
            Vložit
          </button>
        </>
      )}
    </Card>
  )
}

function trimIfString<T>(v: T | string): T | string {
  if (typeof v === 'string') return v.trim()
  return v
}

function CommitteeVoteConvert({
  polls,
}: {
  polls: NonNullable<ReturnType<typeof useParsedPolls>>['ok']
}) {
  const {
    mapping: topicMap,
    component: topicMapComponent,
    topics,
  } = useMapTopic({
    topicNames: useMemo(
      () =>
        polls
          .map((p) => p.topic)
          .filter((val, idx, arr) => arr.indexOf(val) === idx),
      [polls],
    ),
  })
  const [submandateSrc, refetch] = useGetSubmandate()
  if (submandateSrc === 'loading') return <LoaderSpinner />
  if (submandateSrc === 'error')
    return (
      <div>
        Načítání selhalo
        <br />
        <button type="button" onClick={() => refetch()}>
          Zkusit znovu
        </button>
      </div>
    )

  if (!topicMap) {
    return (
      <>
        <Card title="Mapování témat">{topicMapComponent}</Card>
      </>
    )
  }

  const result1 =
    (topicMap &&
      submandateSrc &&
      polls.map((poll) => ({
        ...poll,
        topic: topicMap[poll.topic],
        result: poll.accepted ? ('accepted' as const) : ('rejected' as const),
        votes: poll.votes.map((vote) => {
          const submandate = submandateSrc.get(
            `${trimIfString(vote.name)} ${trimIfString(vote.surname)}`,
            poll.date,
          )
          if (typeof submandate === 'string') return submandate
          return {
            ...vote,
            submandate,
            vote: (
              {
                '@': 'not_present',
                'zdržel se': 'abstained',
                ANO: 'for',
                NE: 'against',
                omluven: 'excused',
              } as const
            )[vote.vote],
          }
        }),
      }))) ||
    null
  if (result1)
    for (const r of result1) {
      const err = r.votes.find((v) => typeof v === 'string')
      const ep = r.votes[0]
      if (typeof err === 'string' || typeof ep === 'string')
        return <Card title="Chyba">{err}</Card>
      if (
        r.votes.some(
          (v) =>
            typeof v !== 'string' &&
            v.submandate.electionPeriod !== ep.submandate.electionPeriod,
        )
      ) {
        return (
          <Card title="Chyba">
            Všechny submandáty v hlasování musí být ze stejného volebního
            období. Nesmíchal/a jsi poslance a senátory?
            <div>Popis chybného hlasování: {r.description}</div>
            <div>Datum chybného hlasování: {r.date.toFormat('d. M. yyyy')}</div>
          </Card>
        )
      }
    }
  const result =
    result1 &&
    result1.map((poll) => ({
      ...poll,
      votes: poll.votes
        .map((v) => (typeof v === 'string' ? null : v))
        .filter(notNull)
        .map((vote) => ({ ...vote, submandate: vote.submandate.submandate })),
    }))
  if (!result) return <LoaderSpinner />
  return (
    <>
      <Card title="Mapování témat">{topicMapComponent}</Card>
      {result && topics && (
        <Card title="Náhled výsledku">
          <div css={{ maxHeight: 500, overflowY: 'auto' }}>
            {result.map((poll, i) => (
              <div key={i} css={{ border: '1px solid black' }}>
                <div>Hlasování #{i + 1}</div>
                <div>
                  <b>Téma:</b>{' '}
                  {topics.find((top) => top.id === poll.topic)!.name}
                </div>
                <div>
                  <b>Váha:</b> {poll.weight}%
                </div>
                <div>
                  <b>Pozitivní:</b>{' '}
                  {poll.weight === 0
                    ? 'NEUTRÁLNÍ'
                    : poll.isPositive
                    ? 'ANO'
                    : 'NE'}
                </div>
                <div>
                  <b>Datum:</b> {poll.date.toFormat('d. M. yyyy')}
                </div>
                <div>
                  <b>Popis pro lidi:</b> {poll.description}
                </div>
                <div>
                  <b>Přijat:</b> {poll.accepted ? 'ANO' : 'NE'}
                </div>
                <div>
                  <b>Stanovisko:</b> {poll.standpoint}
                </div>
                <div>
                  <b>Hlasy:</b>{' '}
                  {poll.votes.map((v, vi) => (
                    <div key={vi}>
                      {v.name} {v.surname} ({v.submandate}):{' '}
                      {
                        {
                          for: 'Pro',
                          against: 'Proti',
                          not_present: 'Nepřítomen/Nepřítomna',
                          abstained: 'Zdržel(a) se',
                          secret: 'Tajné hlasování',
                          excused: 'Omluven/Omluvena',
                        }[v.vote]
                      }
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
      {result && <CommitteeVoteInsert result={result} />}
    </>
  )
}

function useParsedPolls(sheet: ReturnType<typeof useSheetSelector>) {
  const { parsed } = sheet
  return useMemo(() => {
    if (parsed) {
      const voteRows = parsed.cells.slice(5).map((r) => ({
        name: r[1].value,
        surname: r[2].value,
        votes: r.slice(5).map((v) => v),
      }))
      const err = (row: number, col: number, v: string) =>
        `Buňka ${parsed.cells[row][col].name}: ${v}`

      const ret = Array.from(Array(parsed.cols - 5), (_, i) => {
        const topic = parsed.cells[0][i + 5].value
        const weight = Number.parseFloat(`${parsed.cells[1][i + 5].value}`)
        const positiveRaw = parsed.cells[2][i + 5].value
        const positive =
          typeof positiveRaw === 'string'
            ? Number.parseInt(positiveRaw, 10)
            : NaN
        const [date, description, result, standpoint] = `${
          parsed.cells[3][i + 5].value
        }`
          .split('///')
          .map((v) => v.trim())
        if (weight < 0 || weight > 1)
          return err(1, i + 5, 'Váha musí být z rozsahu 0 až 1')
        if (typeof topic !== 'string')
          return err(0, i + 5, 'Téma musí být text')
        if (positive !== 0 && positive !== 1 && positive !== -1)
          return err(2, i + 5, 'pozitivita musí být 0, 1 nebo -1')
        if (positive === 0 && weight !== 0)
          return err(
            2,
            i + 5,
            'Pozitivita může být nula pouze pokud je váha nula',
          )
        if (weight === 0 && positive !== 0)
          return err(
            2,
            i + 5,
            'Váha může být nula pouze pokud je pozitivita nula',
          )

        const parsedDate = DateTime.fromFormat(
          date.replace(/ /g, ''),
          'd.M.yyyy',
          {
            zone: 'UTC',
          },
        )
        if (!parsedDate.isValid)
          return err(3, i + 5, 'Datum musí být platné a ve formát d.m.yyyy')
        if (!checkResult(result))
          return err(3, i + 5, 'Výsledek musí být schváleno nebo zamítnuto')

        const votes = voteRows
          .map((r) => {
            const value = r.votes[i].value
            return {
              name: r.name,
              surname: r.surname,
              vote: {
                ...r.votes[i],
                value:
                  value === null
                    ? null
                    : typeof value === 'string'
                    ? normalizeVote(value)
                    : 'invalid',
              },
            }
          })
          .filter((v) => v.vote.value)
        for (const v of votes) {
          if (typeof v.vote.value !== 'string' || !checkVote(v.vote.value)) {
            return `Buňka ${v.vote.name}: Hlas musí být ANO, zdržel se, NE, @, nebo omluven`
          }
        }

        return {
          votes: votes.map((v) => ({ ...v, vote: v.vote.value as Vote })),
          topic,
          weight: Math.round(weight * 100),
          isPositive: positive !== -1,
          date: parsedDate,
          description,
          accepted: result === 'schválen' || result === 'schváleno',
          standpoint,
        }
      })
      return {
        ok: ret.map((v) => (typeof v === 'string' ? null : v)).filter(notNull),
        ko: ret.filter(isString),
      }
    }
    return null
  }, [parsed])
}

function CommitteeVoteImport() {
  const sheet = useSheetSelector({
    match: useCallback(
      (name) =>
        (name.includes('vybor') ? 1 : 0) +
        (name.includes('hlasovani') ? 1 : 0) +
        Number.parseInt(name.replace(/[^0-9]/g, ''), 10) / 1e6,
      [],
    ),
  })
  const polls = useParsedPolls(sheet)
  return (
    <div {...sheet.rootProps}>
      <h2 css={{ maxWidth: 1200, margin: '20px auto' }}>
        Import hlasování výboru
      </h2>
      <Card title="Popis struktury tabulky">
        <CommitteeSheetDescription />
      </Card>
      <Card title="Výběr tabulky">
        <ImporterHelp definitions={false} />
        {sheet.element}
      </Card>
      {sheet.parsed && (
        <CardBase css={{ maxWidth: '100%' }}>
          <CardHead>Náhled vybraného listu</CardHead>
          <SheetPreview parsed={sheet.parsed} />
        </CardBase>
      )}
      {polls && (
        <Card title="Chybné sloupce">
          {polls.ko.map((poll, i) => (
            <div key={i}>{poll}</div>
          ))}
          {polls.ko.length === 0 ? 'Žádné' : null}
        </Card>
      )}
      {polls && <CommitteeVoteConvert polls={polls.ok} />}
    </div>
  )
}

export default function AdminCommitteeVoteImport() {
  return (
    <XLSXProvider>
      <CommitteeVoteImport />
    </XLSXProvider>
  )
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useAdminTopicList } from 'admin/queries/topic'
import { useReducer, useEffect, useMemo } from 'react'

type NotNull<T> = T extends null ? never : T
type State = { [topic: string]: string }
function guessState(
  topicNames: string[],
  topics: NotNull<ReturnType<typeof useAdminTopicList>['list']>,
) {
  const ret: State = {}
  function topicByName(name: string) {
    const candidate = topics.find((t) => t.name === name)
    if (!candidate) return ''
    return candidate.id
  }
  for (const tn of topicNames) {
    if (tn === 'registr smluv' || tn === 'REGISTR') {
      ret[tn] = topicByName('Smlouvy státu na internetu')
    } else if (tn === 'střet zájmů') {
      ret[tn] = topicByName('Majetková přiznání politiků')
    } else if (tn === 'PPF') {
      ret[tn] = topicByName('Politika bez tajných sponzorů')
    } else if (tn === 'NKÚ' || tn === 'NKU') {
      ret[tn] = topicByName('Rozšíření pravomocí NKÚ')
    } else if (tn === 'jednací řád' || tn === 'PRILEPKY') {
      ret[tn] = topicByName('Zákony bez přílepků')
    }
    if (!ret[tn]) {
      const guess = topics.find((t) =>
        t.name.toLowerCase().includes(tn.toLowerCase()),
      )
      if (guess) ret[tn] = guess.id
    }
  }
  return ret
}

export function useMapTopic({
  topicNames,
  skip = false,
}: {
  topicNames: string[]
  skip?: boolean
}): {
  mapping: { [topic: string]: string } | null
  component: JSX.Element
  topics: ReturnType<typeof useAdminTopicList>['list']
} {
  const { list: topicsIn, status, refetch } = useAdminTopicList()
  const [mapping, dispatch] = useReducer(
    (state: State, action: State) => ({ ...state, ...action }),
    {},
  )
  const topics = useMemo(
    (): typeof topicsIn =>
      topicsIn
        ? topicsIn
            .filter((t) => t.availableInEvaluation)
            .sort((a, b) => a.name.localeCompare(b.name))
        : null,
    [topicsIn],
  )

  useEffect(() => {
    if (topics) {
      dispatch(guessState(topicNames, topics))
    }
  }, [topicNames, topics])

  if (!topics)
    return {
      topics,
      mapping: null,
      component: (
        <div>
          6.{' '}
          {status === 'error' ? (
            <>
              Načítání témat selhalo
              <br />
              <button type="button" onClick={() => refetch()}>
                Zkusit znovu
              </button>
            </>
          ) : (
            <>Načítám seznam témat</>
          )}
        </div>
      ),
    }

  const select = (tn: string) => (
    <select
      value={mapping[tn] || ''}
      css={{ background: 'white', color: 'black' }}
      onChange={(evt) => dispatch({ [tn]: evt.target.value })}
    >
      <option value="" disabled>
        Vyber
      </option>
      {topics.map((top) => (
        <option key={top.id} value={top.id}>
          {top.name}
        </option>
      ))}
    </select>
  )
  return {
    topics,
    mapping: topicNames.some((tn) => !mapping[tn]) ? null : mapping,
    component: (
      <div>
        {topicNames.map((tn, i) => (
          <div key={i}>
            {tn}: {select(tn)}
          </div>
        ))}
      </div>
    ),
  }
}

import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { AdminLabelTextDiv } from './input-builder'
import TextEditor, {
  deserialize,
  serialize,
  fromHtml,
} from './text-editor/text-editor'
import isEqual from 'lodash.isequal'

const Container = styled('div')`
  display: block;
`

const emptyRichTextDocument = {
  editor: 'prosemirror' as 'prosemirror',
  version: 1,
  value: {
    doc: {
      type: 'doc',
      content: [{ type: 'paragraph' }],
    },
    selection: { type: 'text', anchor: 1, head: 1 },
  },
}

export function compareAdminTextEditorValue(a: any, b: any) {
  if (a === b) return true
  if (!a && b) return false
  if (a && !b) return false
  if ('value' in a && !('value' in b)) return false
  if (!('value' in a) && 'value' in b) return false
  if (a.value && !b.value) return false
  if (!a.value && b.value) return false
  return isEqual(a.value.doc, b.value.doc)
}

const AdminTextEditor = ({
  label,
  state,
  className,
}: {
  label: string
  state: { change: (v: any) => void; value: any }
  className?: string
}) => {
  const [value, setValue] = useState(deserialize(state.value))
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (state.value === null) state.change(emptyRichTextDocument)
  })

  if (state.value && state.value.noneditable) {
    return (
      <Container className={className}>
        <AdminLabelTextDiv>{label}</AdminLabelTextDiv>
        <div style={{ maxWidth: 600 }}>
          Tento text byl automaticky importován a není editovatelný. Pokud ho
          chcete editovat klikněte na následující tlačítko, které ho převede do
          editovatelné podoby.
          <br />
          Po konverzi prosím zkontrolujte, jestli je text v pořádku, nic nechybí
          či nepřebývá všechny odkazy fungují a podobně a až po kontrole a
          případné opravě problémů aktualitu uložte.
        </div>
        <button
          onClick={(evt) => {
            evt.preventDefault()
            const val = fromHtml(state.value.value)
            state.change(val)
            setValue(deserialize(val))
          }}
        >
          Konvertovat text
        </button>
      </Container>
    )
  }

  return (
    <Container className={className}>
      <AdminLabelTextDiv>{label}</AdminLabelTextDiv>
      <TextEditor
        value={value || emptyRichTextDocument}
        onChange={(evt) => {
          if (state.change) {
            setValue(evt.value)
            state.change(serialize(evt.value))
          }
        }}
        submit={() => buttonRef.current!.click()}
      />
      <button style={{ display: 'none' }} ref={buttonRef} />
    </Container>
  )
}
export default AdminTextEditor

/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'
import { PropsWithChildren } from 'react'

function create<T extends 'span' | 'code' | 'div' | 'button'>(
  Component: T,
  c: SerializedStyles,
) {
  return function Primitive({ ...props }: JSX.IntrinsicElements[T]) {
    return <Component {...(props as any)} css={c} />
  }
}

const reset = css`
  all: unset;
  font-family: inherit;
  user-select: none;
  /* required to fix color on safari with all: unset */
  -webkit-text-fill-color: currentColor;
`

export const Key = create(
  'span',
  css`
    color: #f8f9f2;
  `,
)
export function StringC({
  children,
  onClick,
}: PropsWithChildren<{ onClick?: () => void }>) {
  if (!onClick)
    return (
      <span css={{ color: '#fff4a0' }}>
        {'"'}
        {children}
        {'"'}
      </span>
    )
  return (
    <span css={{ color: '#fff4a0' }}>
      {'"'}
      <button
        css={[
          reset,
          {
            color: '#fff4a0',
            cursor: 'pointer',
            textDecoration: 'underline',
            ':hover': { textDecoration: 'none', fontWeight: 'bold' },
          },
        ]}
        onClick={onClick}
      >
        {children}
      </button>
      {'"'}
    </span>
  )
}
export const NumberC = create(
  'span',
  css`
    color: #d60066;
  `,
)
export const BooleanC = create(
  'span',
  css`
    color: #d60066;
  `,
)
export const ObjectLiteral = create(
  'span',
  css`
    color: #999;
  `,
)
export const Code = create(
  'code',
  css`
    display: block;
    padding: 1rem;
    white-space: pre;
    position: relative;
    overflow: auto;

    font-size: 1rem;
    background-color: #222;
    color: #888;
  `,
)
export const NonSelectable = create(
  'span',
  css`
    user-select: none;
  `,
)
export const ObjectContent = create(
  'div',
  css`
    padding-left: 2ch;
  `,
)
export function PlusMinus({
  expanded,
  onClick,
}: {
  expanded: boolean
  onClick: () => void
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      css={[
        reset,
        css`
          position: absolute;
          cursor: pointer;
          padding: 0.125rem;
          transform: translate(-1.125rem, -0.25rem);
        `,
      ]}
    >
      {expanded ? '-' : '+'}
    </button>
  )
}
export const KeyValueWrap = create(
  'div',
  css`
    position: relative;
    line-height: 120%;
    font-family: 'PT Mono', monospace;
  `,
)
export const InvisibleButSelectable = create(
  'span',
  css`
    position: absolute;
    font-size: 0;
    width: 0;
    white-space: pre;
  `,
)

import { createContext, useContext } from 'react'

const context = createContext<string | null>(null)

export const UseApolloUriProvider = context.Provider

export const useApolloUri = () => {
  const ctx = useContext(context)
  if (!ctx) throw new Error('Your are missing UseApolloProvider up the tree')
  return { uri: ctx }
}

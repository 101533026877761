import { useState, useEffect, useCallback, useRef } from 'react'

type UsePromiseResultReturn<T> =
  | { status: 'done'; data: T; error: null; promise: Promise<T> | null }
  | { status: 'loading'; data: null; error: null; promise: Promise<T> | null }
  | { status: 'error'; data: null; error: any; promise: Promise<T> | null }

export function useThen<T>(
  promise: Promise<T> | null,
  { caching = false }: { caching?: boolean } = {},
): UsePromiseResultReturn<T> {
  const state = useThenCaching(promise)
  // this ternary makes sure that if you pass new promise then you will immediatelly get loading status
  return state.promise === promise || caching
    ? state
    : { status: 'loading', data: null, error: null, promise }
}

function useThenCaching<T>(
  promise: Promise<T> | null,
): UsePromiseResultReturn<T> {
  const [state, setState] = useState<UsePromiseResultReturn<T>>({
    promise,
    status: 'loading',
    data: null,
    error: null,
  })
  useEffect(() => {
    let ended = false
    if (promise) {
      promise
        .then((val) => {
          if (!ended)
            setState({ status: 'done', data: val, error: null, promise })
        })
        .catch((e) => {
          if (!ended)
            setState({ status: 'error', data: null, error: e, promise })
        })
    }
    return () => {
      ended = true
    }
  }, [promise])
  return state
}

export function useThenState<T>(
  loader: (() => Promise<T>) & { preloaded?: T | null },
  opts: Parameters<typeof useThen>[1] = {},
) {
  const [promise, setPromise] = useState<Promise<T> | null>(null)

  const preloadedRef = useRef<UsePromiseResultReturn<T> | null>(
    loader.preloaded
      ? { status: 'done', data: loader.preloaded, error: null, promise }
      : null,
  )
  useEffect(() => {
    if (!preloadedRef.current) setPromise(loader)
  }, [loader])

  const result = useThen(promise, opts)
  const retry = useCallback(() => {
    if (!preloadedRef.current) setPromise(loader)
  }, [loader])

  return [preloadedRef.current || result, { retry }] as const
}

export function retryableCachedPromise<T>(
  load: () => Promise<T>,
): (() => Promise<T>) & { preloaded: T | null; cache: Promise<T> } {
  const ret: any = () => {
    if (!ret.cache)
      ret.cache = load().then(
        (v) => {
          ret.preloaded = v
          return v
        },
        (err) => {
          ret.cache = null
          throw err
        },
      )
    return ret.cache
  }
  return ret
}

import React from 'react'
import { Card, CardBase, CardHead, CardBody } from './card'
import styled from '@emotion/styled'
import AdminButton from './admin-button'

const Div = styled('div')`
  display: flex;
`

export const EditCard: React.SFC<{
  title: React.ReactNode
  onSave?: () => void
  saving?: boolean
  hasError?: boolean
  changed?: boolean
  CardBody?: typeof CardBody
}> = ({
  children,
  title,
  saving,
  hasError,
  onSave,
  changed,
  CardBody: TheCardBody = CardBody,
}) => (
  <form
    onSubmit={(evt) => {
      evt.preventDefault()
      if (onSave && !hasError) onSave()
    }}
  >
    <CardBase>
      <CardHead>
        <Div>
          <AdminButton disabled={hasError || saving || !changed}>
            {saving
              ? 'Ukládám...'
              : hasError
              ? 'Formulář obsahuje chyby'
              : changed
              ? 'Uložit'
              : 'Všechny změny uloženy'}
          </AdminButton>{' '}
          {title}
        </Div>
      </CardHead>
      <TheCardBody>{children}</TheCardBody>
    </CardBase>
  </form>
)

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { EditorView } from 'prosemirror-view'
import { Schema, MarkType } from 'prosemirror-model'
import { SelectionRange } from 'prosemirror-state'
import { toggleMark } from 'prosemirror-commands'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { PropsWithChildren, useState } from 'react'
import { textEditorSchema as schema } from './text-editor-schema'
import { useTextEditorView } from './text-editor-view-context'
import { icons } from 'prosemirror-menu'
import AdminInput from '../admin-input'
import AdminButton from '../admin-button'

export default function TextEditorMenu() {
  const [linkPrompt, setLinkPrompt] = useState<null | string>(null)
  const view = useTextEditorView()
  return (
    <div css={{ position: 'relative' }}>
      {linkPrompt === null ? null : (
        <form
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
            zIndex: 11,
            display: 'flex',
            alignItems: 'flex-end',
          }}
          onSubmit={(evt) => {
            evt.stopPropagation()
            evt.preventDefault()
            if (linkPrompt) {
              toggleMark(schema.marks.link, { href: linkPrompt, title: '' })(
                view.state,
                view.dispatch,
              )
            }
            setLinkPrompt(null)
          }}
        >
          <AdminInput
            label="Odkaz"
            state={{ value: linkPrompt, change: setLinkPrompt }}
          />
          <button
            css={{
              all: 'unset',
              padding: 7,
              fontSize: '1.1em',
              height: 45,
              border: '3px solid #aaa',
              boxSizing: 'border-box',
              marginLeft: 5,
            }}
          >
            {linkPrompt ? 'Vložit' : 'Zrušit'}
          </button>
        </form>
      )}
      <MenuButtonMark title="Toggle strong style" mark="strong">
        <Icon icon={icons.strong} />
      </MenuButtonMark>
      <MenuButtonMark title="Toggle emphasis" mark="em">
        <Icon icon={icons.em} />
      </MenuButtonMark>
      <MenuButton
        title="Add or remove link"
        isActive={markActive(schema.marks.link)}
        isAllowed={(state) => !state.selection.empty}
        command={(state, dispatch) => {
          if (markActive(schema.marks.link)(view.state)) {
            toggleMark(schema.marks.link)(state, dispatch)
            return
          }
          setLinkPrompt('')
        }}
      >
        <Icon icon={icons.link} />
      </MenuButton>
    </div>
  )
}

function Icon({
  icon,
}: {
  icon: { path: string; width: number; height: number }
}) {
  return (
    <svg
      viewBox={`0 0 ${icon.width} ${icon.height}`}
      css={{ width: icon.width / icon.height + 'em' }}
    >
      <path d={icon.path} fill="currentColor" />
    </svg>
  )
}

function markActive(mark: MarkType<any>) {
  return (state: EditorView['state']) => {
    let { from, $from, to, empty } = state.selection
    if (empty) return !!mark.isInSet(state.storedMarks || $from.marks())
    else return !!state.doc.rangeHasMark(from, to, mark)
  }
}

function MenuButtonMark({
  mark: markName,
  children,
  title,
}: PropsWithChildren<{ mark: string; title: string }>) {
  const view = useTextEditorView()
  const mark = schema.marks[markName]

  return (
    <MenuButton
      command={toggleMark(mark)}
      isAllowed={toggleMark(mark)}
      isActive={markActive(mark)}
      title={title}
    >
      {children}
    </MenuButton>
  )
}

const Button = styled.button`
  all: unset;
  padding: 5px 10px;
  cursor: pointer;
`

function MenuButton({
  children,
  command,
  isActive,
  isAllowed,
  title,
}: PropsWithChildren<{
  command: (
    editorState: EditorView['state'],
    dispatchTransaction: EditorView['dispatch'],
    view: EditorView,
  ) => void
  isActive?: (editorState: EditorView['state']) => boolean
  isAllowed?: (editorState: EditorView['state']) => boolean
  title: string
}>) {
  const view = useTextEditorView()
  // this is like an `select` field in prosemirror-menu item spec
  const disabled = isAllowed && !isAllowed(view.state)
  // this is like an `active` field in prosemirror-menu item spec
  const active = isActive && isActive(view.state)
  return (
    <Button
      title={title}
      type="button"
      disabled={disabled}
      css={
        active
          ? markActiveStyle
          : disabled
          ? markDisabledStyle
          : markDefaultStyle
      }
      onMouseDown={(e) => {
        e.stopPropagation()
        // so we don't steal focus from EditorView
        e.preventDefault()
        // this is like an `run` field in prosemirror-menu item spec
        command(view.state, view.dispatch, view)
      }}
    >
      {children}
    </Button>
  )
}

const markActiveStyle = css({
  background: '#888',
})

const markDisabledStyle = css({
  color: '#666',
  opacity: 0.3,
})

const markDefaultStyle = css({
  color: '#666',
})

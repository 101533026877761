import { useLayoutEffect, useReducer, useRef } from 'react'

function defaultConverter<V>(v: V): V | null {
  return (v as any) === '' ? null : v
}

export function useFormState<T>(initial: T | null) {
  return function useFormStateImpl({
    comparators = {},
    converters = {},
  }: {
    comparators?: Partial<{ [k in keyof T]: (a: T[k], b: T[k]) => boolean }>
    converters?: Partial<{ [k in keyof T]: (v: T[k]) => any }>
  } = {}):
    | {
        state: {
          [k in keyof T]: {
            change: (v: T[k]) => void
            value: T[k]
            error: string | null
          }
        }
        values: T
        changed: boolean
        patch: Partial<{ [k in keyof T]: any }>
        fullSubmit: { [k in keyof T]: any }
        hasError: boolean
        failed: false
      }
    | {
        state: {}
        values: null
        changed: false
        patch: {}
        fullSubmit: {}
        hasError: boolean
        failed: true
      } {
    const [values, dispatch] = useReducer(
      (
        p: typeof initial,
        e: { key: string; value: any } | { initial: typeof initial },
      ): any =>
        'initial' in e
          ? e.initial
          : {
              ...(p as any),
              [e.key]: e.value,
            },
      initial!,
    )
    const hadInitial = useRef(!!initial)
    useLayoutEffect(() => {
      if (!hadInitial.current && initial) {
        hadInitial.current = true
        dispatch({ initial })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initial])
    if (initial === null || !values) {
      return {
        state: {},
        values: null,
        changed: false,
        patch: {},
        fullSubmit: {},
        hasError: false,
        failed: true as true,
      }
    }

    const state: {
      [k in keyof T]: {
        change: (v: T[k]) => void
        value: T[k]
        error: string | null
      }
    } = {} as any
    for (const key of Object.keys(initial)) {
      ;(state as any)[key] = {
        change: (value: any) => dispatch({ key, value }),
        value: (values as any)[key],
        error: null,
      }
    }

    let changed = false
    const patch: Partial<{ [k in keyof T]: T[k] }> = {}
    const fullSubmit: { [k in keyof T]: T[k] } = {} as any
    let hasError = false
    for (const k of Object.keys(initial) as (keyof T)[]) {
      const comp = comparators[k] as any
      const conv = (converters[k] || defaultConverter) as any

      try {
        const convertedValue = conv(values[k])
        const thisNotChanged = comp
          ? (comp as any)(values[k], initial[k])
          : convertedValue === conv(initial[k])

        if (!thisNotChanged) {
          changed = true
          patch[k] = convertedValue
        }
        fullSubmit[k] = convertedValue
      } catch (e) {
        hasError = true
        state[k].error = e.message
      }
    }
    return {
      values,
      state,
      changed,
      patch,
      fullSubmit,
      hasError,
      failed: false,
    }
  }
}

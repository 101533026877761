import { ColumnMap } from './activity-select-columns'
import { DateTime } from 'luxon'

type SuccessRow = {
  group: number
  proposer: string[]
  topic: string
  description: string
  signedWeight: number
  date: DateTime
}
export type ConvertedRows = {
  success: SuccessRow[]
  failed: {
    error: string
    columns: {
      [key: string]: number | string
    }
    index: number
  }[]
}

function convertRow(
  row: { [key: string]: number | string | Date },
  columnMap: ColumnMap,
): SuccessRow | string {
  const group = row[columnMap.group]
  const proposer = row[columnMap.proposer]
  const topic = row[columnMap.topic]
  const description = row[columnMap.description]
  const signedWeight = row[columnMap.finalWeight]
  const date = row[columnMap.date]

  if (typeof group !== 'number' || !Number.isInteger(group)) {
    return 'Chybná skupina'
  }
  if (typeof proposer !== 'string') {
    return 'Účastná osoba musí být seznam jmen oddělených čárkami'
  }
  if (typeof topic !== 'string') return 'Téma musí být text'
  if (!topic) return 'Téma musí být vyplněno'
  if (typeof description !== 'string') return 'Popis musí být text'
  if (!description) return 'Popis musí být vyplněn'
  if (typeof signedWeight !== 'number' || signedWeight < -1 || signedWeight > 1)
    return 'Váha musí být číslo v rozsahu -1, 1'
  if (
    !(date instanceof Date) &&
    (typeof date !== 'string' ||
      !/[0-9][0-9]?\. ?[0-9][0-9]?\. ?[0-9][0-9][0-9][0-9]/.test(date))
  ) {
    return 'Datum musí být text ve formátu dd. mm. rrrr'
  }
  const parsedDate =
    typeof date === 'string'
      ? DateTime.fromString(date.replace(/ /g, ''), 'd.m.yyyy', {
          zone: 'utc',
        })
      : DateTime.fromObject({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          zone: 'UTC',
        })
  if (!parsedDate.isValid || parsedDate >= DateTime.utc())
    return 'Datum musí být platné a v minulosti'
  return {
    group,
    proposer: proposer.split(',').map((p) => p.trim()),
    topic,
    description,
    signedWeight,
    date: parsedDate,
  }
}

export function convertRows(
  rows: { [key: string]: number | string }[],
  columnMap: ColumnMap,
) {
  const ret: ConvertedRows = { success: [], failed: [] }
  let i = 1
  for (const row of rows) {
    i += 1
    const parsed = convertRow(row, columnMap)
    if (typeof parsed === 'string')
      ret.failed.push({ error: parsed, columns: row, index: i })
    else ret.success.push(parsed)
  }
  return ret
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import Tooltip from '@material-ui/core/Tooltip'
import { DatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
} from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import gql from 'graphql-tag'
import { useQuery, useApolloClient } from '@apollo/client/react/hooks'
import { SingleTwemoji } from 'components/twemoji'
import { Raven } from 'utils/globals'
import { notNull } from '@codewitchbella/ts-utils'
import { Paper, Dialog } from '@material-ui/core'
import {
  AdminSubmandatePickerQuery,
  AdminSubmandatePickerSubmandateFragment,
  AdminSubmandatePickerQueryVariables,
  AdminSubmandatePickerMissingQuery,
  AdminSubmandatePickerMissingQueryVariables,
  AdminSubmandatePickerDocument,
  AdminSubmandatePickerMissingDocument,
} from 'queries/queries'

gql`
  fragment AdminSubmandatePickerSubmandate on Submandate {
    __typename
    id
    start
    end
    mandate {
      id
      chamber
      region {
        id
        name
      }
      politician {
        id
        person {
          id
          name
          surname
        }
      }
    }
  }
`

gql`
  query AdminSubmandatePicker($date: DateTime!) {
    submandatesByDate(date: $date) {
      count
      list {
        ...AdminSubmandatePickerSubmandate
      }
    }
  }
`

gql`
  query AdminSubmandatePickerMissing($ids: [GlobalID!]!) {
    submandatesByIds(ids: $ids) {
      ...AdminSubmandatePickerSubmandate
    }
  }
`

function PersonName({ person }: { person: { name: string; surname: string } }) {
  return (
    <>
      {person.name} {person.surname}
    </>
  )
}

function comparePeople(
  a: { name: string; surname: string },
  b: { name: string; surname: string },
) {
  const ret = a.surname.localeCompare(b.surname)
  if (ret !== 0) return ret
  return a.name.localeCompare(b.name)
}

function useUnchangeableRef<T>(initial: T) {
  const v = useRef(initial)
  return v.current
}

const submadatePickerContext = createContext<{
  map: Map<string, AdminSubmandatePickerSubmandateFragment>
  counter: number
  forceUpdate: () => void
} | null>(null)

function useSubmandatePickerContext() {
  const ctx = useContext(submadatePickerContext)
  if (!ctx) throw new Error('Missing SubmandateContextProvider')
  return ctx
}

export function SubmandatePickerProvider({ children }: PropsWithChildren<{}>) {
  const map = useUnchangeableRef(
    new Map<string, AdminSubmandatePickerSubmandateFragment>(),
  )
  const [counter, setCounter] = useState(0)
  const ctx = useMemo(
    () => ({ forceUpdate: () => setCounter((v) => v + 1), map, counter }),
    [counter, map],
  )
  return (
    <submadatePickerContext.Provider value={ctx}>
      {children}
    </submadatePickerContext.Provider>
  )
}

function AdminSubmandatePickerChanger({
  selected,
  onChange,
  cache,
}: {
  selected: string[]
  onChange: (v: (prev: string[]) => string[]) => void
  cache: ReturnType<typeof useSubmandatePickerContext>
}) {
  const [date, setDate] = useState<DateTime | null>(DateTime.utc())
  const [senate, setSenate] = useState(false)
  const result = useQuery<
    AdminSubmandatePickerQuery,
    AdminSubmandatePickerQueryVariables
  >(AdminSubmandatePickerDocument, {
    variables: { date: (date || DateTime.utc()).toISO() },
  })

  const [list, setList] = useState<
    AdminSubmandatePickerSubmandateFragment[] | null
  >(null)

  const fullList =
    result.loading || !result.data ? null : result.data.submandatesByDate.list

  useEffect(() => {
    const filtered = !fullList
      ? null
      : fullList
          .filter((sub) =>
            sub.mandate.chamber === 'senate' ? senate : !senate,
          )
          .sort((a, b) =>
            comparePeople(
              a.mandate.politician.person,
              b.mandate.politician.person,
            ),
          )
    setList(filtered)
    if (fullList) {
      let changed = false
      for (const item of fullList) {
        if (!cache.map.has(item.id)) {
          cache.map.set(item.id, item)
          changed = true
        }
      }
      if (changed) cache.forceUpdate()
    }
  }, [cache, fullList, senate])

  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        Vyberte{' '}
        <Tooltip title="Volební období znamená konkrétní hodnotitelné období. Jeden mandát v senátu se může skládat až ze tří volebních období.">
          <span css={{ fontStyle: 'italic' }}>volební období,</span>
        </Tooltip>{' '}
        komoru a následně politika
      </div>

      <DatePicker
        label="Datum ve volebním období"
        value={date}
        onChange={setDate}
        cancelLabel={false}
        disableFuture={true}
        format="d. MMMM yyyy"
        InputProps={{ value: date!.toFormat('d. MMMM yyyy') }}
      />
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Poslanci</Grid>
          <Grid item>
            <Switch
              checked={senate}
              onChange={(v) => setSenate(v.target.checked)}
              color="default"
            />
          </Grid>
          <Grid item>Senátoři</Grid>
        </Grid>
      </Typography>
      <div css={{ display: 'flex' }}>
        <div css={{ marginRight: 10 }}>
          <div>Vybraní</div>
          {selected
            .map((s) => cache.map.get(s) || null)
            .filter(notNull)
            .map((sub) => (
              <div
                key={sub.id}
                css={{
                  '.hover-visible': { opacity: 0 },
                  ':hover .hover-visible': { opacity: 1 },
                  whiteSpace: 'nowrap',
                }}
              >
                <PersonName person={sub.mandate.politician.person} />
                <Tooltip title="Odebrat" className="hover-visible">
                  <button
                    css={{
                      all: 'unset',
                      padding: 5,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      onChange((prev) => prev.filter((v) => v !== sub.id))
                    }
                  >
                    <SingleTwemoji emoji="❌" />
                  </button>
                </Tooltip>
              </div>
            ))}
        </div>
        <div>
          <div>Dostupní</div>
          {!list
            ? 'Načítám seznam politiků...'
            : list
                .filter((sub) => !selected.includes(sub.id))
                .map((sub) => (
                  <div
                    key={sub.id}
                    css={{
                      '.hover-visible': { opacity: 0 },
                      ':hover .hover-visible': { opacity: 1 },
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <PersonName person={sub.mandate.politician.person} />
                    <Tooltip title="Vybrat" className="hover-visible">
                      <button
                        css={{
                          all: 'unset',
                          padding: 5,
                          cursor: 'pointer',
                        }}
                        onClick={() => onChange((prev) => prev.concat(sub.id))}
                      >
                        <SingleTwemoji emoji="✔" />
                      </button>
                    </Tooltip>
                  </div>
                ))}
        </div>
      </div>
    </div>
  )
}

export function AdminSubmandatePicker({
  selected,
  onChange,
}: {
  selected: string[]
  onChange: (v: (prev: string[]) => string[]) => void
}) {
  const cache = useSubmandatePickerContext()
  const [editing, setEditing] = useState(false)
  const client = useApolloClient()

  useEffect(() => {
    const missing = selected.filter((s) => !cache.map.has(s))
    if (missing.length > 0) {
      client
        .query<
          AdminSubmandatePickerMissingQuery,
          AdminSubmandatePickerMissingQueryVariables
        >({
          query: AdminSubmandatePickerMissingDocument,
          variables: { ids: missing },
        })
        .then((v) => {
          let changed = false
          for (const sub of v.data.submandatesByIds) {
            if (!cache.map.has(sub.id)) {
              cache.map.set(sub.id, sub)
              changed = true
            }
          }
          if (changed) cache.forceUpdate()
        })
        .catch((e) => {
          console.error(e)
          if (Raven) Raven.captureException(e)
        })
    }
  }, [cache, client, selected])
  return (
    <>
      <div css={{ display: 'flex', flexWrap: 'wrap' }}>
        {selected
          .map((s) => cache.map.get(s) || null)
          .filter(notNull)
          .map((sub) => (
            <div key={sub.id} css={{ margin: 5 }}>
              <PersonName person={sub.mandate.politician.person} />
            </div>
          ))}
        <button type="button" onClick={() => setEditing(true)}>
          Upravit
        </button>
      </div>
      <Dialog open={editing} onClose={() => setEditing(false)} maxWidth="xl">
        <Paper css={{ padding: 20 }}>
          <AdminSubmandatePickerChanger
            selected={selected}
            onChange={onChange}
            cache={cache}
          />
        </Paper>
      </Dialog>
    </>
  )
}

import Link from 'components/link'
import styled from '@emotion/styled'
import { LocalizedDate } from 'components/localized-date'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useAdminPollEvaluationList } from 'admin/queries/poll-evaluation'
import LoaderSpinner from 'components/loader-spinner'
import { AdminSelectTopic } from 'admin/components/admin-select-topic'

const ListItem = styled(Link)({
  display: 'block',
  padding: 5,
})

const AdminPollEvalList = () => {
  const [topic, setTopic] = useState(null as null | string)
  const list = useAdminPollEvaluationList(topic)

  return (
    <div>
      <ListItem to="/admin/poll-eval/new">Přidat hodnocení hlasování</ListItem>
      <ListItem to="/admin/poll-eval/committee-import">
        Importovat hlasování výboru
      </ListItem>
      <h2>Seznam</h2>
      <AdminSelectTopic
        topic={{ value: topic, change: setTopic }}
        filter={(t) => t.availableInEvaluation}
      />
      {!topic ? null : !list ? (
        <LoaderSpinner />
      ) : (
        <>
          {list.map((item) => (
            <ListItem key={item.id} to={`/admin/poll-eval/${item.id}`}>
              <LocalizedDate date={DateTime.fromISO(item.poll.date)} />
              {' - '}
              {item.committeePoll ? '(výbor) ' : null}
              {item.description}
            </ListItem>
          ))}
          {list.length < 1
            ? 'V tomto tématu nebyla vyhodnocena žádná hlasování'
            : null}
        </>
      )}
    </div>
  )
}
export default AdminPollEvalList

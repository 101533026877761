import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const color = '#000'

const topBottom = css`
  position: absolute;
  display: block;
  height: 4px;
  width: 36px;
  border-radius: 2px;
  background: ${color};

  transform-origin: 34px 2px;
  transition: all 500ms cubic-bezier(0.8, -1.1, 0.5, 1.9);
`

const Top = styled('div')<{ open: boolean }>`
  ${topBottom};
  top: 19px;
  left: 14px;

  transform: ${(props) =>
    props.open ? 'translateX(-4.5px) rotate(-45deg)' : 'rotate(0deg)'};
`
const Bottom = styled('div')<{ open: boolean }>`
  ${topBottom};
  top: 41px;
  left: 14px;

  transform: ${(props) =>
    props.open ? 'translateX(-4.5px) rotate(45deg)' : 'rotate(0deg)'};
`

const CirclePath = styled('path')<{ open: boolean }>`
  stroke-dasharray: ${({ open }) => (open ? '1 100 190 300' : '1 100 32 300')};
  stroke-dashoffset: ${({ open }) => (open ? '1' : '101')};
  transition: all 750ms ease;

  opacity: ${({ open }) => (open ? 0 : 1)};
`

const Burger = styled('div')`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  transform: scale(1);
  position: relative;
  display: block;
  width: 64px;
  height: 64px;
  user-select: none;
  margin: 3px;

  path,
  line {
    stroke: ${color};
    stroke-linecap: round;
  }
`

export default ({
  open,
  onClick,
  className,
}: {
  open: boolean
  onClick: () => void
  className?: string
}) => (
  <Burger onClick={onClick} className={className}>
    <Top open={open} />
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
    >
      <CirclePath
        open={open}
        fill="none"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M16,32h32c0,0,11.723-0.306,10.75-11
          c-0.25-2.75-1.644-4.971-2.869-7.151C50.728,7.08,42.767,2.569,33.733,2.054C33.159,2.033,32.599,2,32,2C15.432,2,2,15.432,2,32
          c0,16.566,13.432,30,30,30c16.566,0,30-13.434,30-30C62,15.5,48.5,2,32,2S1.875,15.5,1.875,32"
      />
    </svg>
    <Bottom open={open} />
  </Burger>
)

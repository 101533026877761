import { useState } from 'react'
import { Card } from 'admin/components/card'
import AdminInput from 'admin/components/admin-input'
import { useAdminSendTestNotificationMutation } from 'admin/queries/notification'

export default function AdminTestNotification() {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [status, setStatus] = useState('')

  const notif = useAdminSendTestNotificationMutation({
    onSuccess: (d) => {
      if (!d.sendTestNotification) {
        setStatus('Odeslání selhalo')
      } else {
        setStatus(d.sendTestNotification)
      }
    },
    onError: (e) => {
      setStatus('Odeslání selhalo')
    },
  })

  return (
    <>
      {notif.error && <pre>{JSON.stringify(notif.error)}</pre>}
      <Card title="Odeslat testovací notifikaci">
        <form
          onSubmit={(evt) => {
            evt.preventDefault()
            if (!notif.mutating) {
              notif.mutate({ variables: { body, title } })
              setStatus('')
            }
          }}
        >
          <div>{status}</div>
          <AdminInput
            label="Nadpis"
            state={{ value: title, change: setTitle }}
          />
          <AdminInput label="Tělo" state={{ value: body, change: setBody }} />
          <button disabled={notif.mutating || !title || !body}>Odeslat</button>
        </form>
      </Card>
    </>
  )
}

import gql from 'graphql-tag'

import { useQuery } from '@apollo/client'
import { createUseMutation } from 'admin/utils/create-use-mutation'
import {
  AdminPartyListQuery,
  AdminPartyQuery,
  AdminPartyQueryVariables,
  AdminUpdatePartyMutation,
  AdminUpdatePartyMutationVariables,
} from 'queries/queries'

const partyListQuery = gql`
  query adminPartyList {
    parties {
      list {
        id
        abbreviation
        name
        displayText(editable: true)
      }
    }
  }
`

export const useAdminPartyList = () => {
  const ret = useQuery<AdminPartyListQuery, {}>(partyListQuery)
  if (!ret) return null
  const { data } = ret
  if (!data || !('parties' in data)) return null
  return data.parties.list
}

const partyFragment = gql`
  fragment party on Party {
    id
    name
    abbreviation
    displayText(editable: true)
    color
    contrastingColor
    availableInReflektor
  }
`

const partyQuery = gql`
  query adminParty($id: GlobalID!) {
    node(id: $id) {
      ...party
    }
  }
  ${partyFragment}
`

export const useAdminParty = (id: string) => {
  const ret = useQuery<AdminPartyQuery, AdminPartyQueryVariables>(partyQuery, {
    variables: { id },
  })
  if (!ret) return null
  const { data } = ret
  if (
    !data ||
    !('node' in data) ||
    !data.node ||
    data.node.__typename !== 'Party'
  )
    return null
  return data.node
}

const updatePartyMutation = gql`
  mutation adminUpdateParty($id: GlobalID!, $data: PartyPatchInput!) {
    updateParty(id: $id, data: $data) {
      party {
        ...party
      }
    }
  }
  ${partyFragment}
`

export const useAdminUpdatePartyMutation = createUseMutation<
  AdminUpdatePartyMutation,
  AdminUpdatePartyMutationVariables
>(updatePartyMutation, {
  refetchQueries: (v) => (v.id ? [] : [{ query: partyListQuery }]),
})

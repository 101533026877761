import React from 'react'
import AdminError from 'admin/components/error'
import { withRouter, RouteComponentProps } from 'react-router'
import { SingleTwemoji } from 'components/twemoji'

// We do not want admin interface to be server-side rendered

type ErrorBoundaryState = { error: (() => JSX.Element) | null }
class ErrorBoundaryImpl extends React.Component<
  RouteComponentProps<any>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { error: null }

  render() {
    const C = this.state.error
    if (!C) return this.props.children
    return <C />
  }

  unregister?: () => void

  componentDidMount() {
    this.unregister = this.props.history.listen(() => {
      this.setState({ error: null })
    })
  }

  componentWillUnmount() {
    if (this.unregister) this.unregister()
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      error: () => (
        <div>
          <AdminError>
            Něco se pokazilo <SingleTwemoji emoji="😢" />
          </AdminError>
          <pre>{error.stack}</pre>
          <hr />
          <pre>
            ComponentPath
            {info.componentStack}
          </pre>
        </div>
      ),
    })
  }
}
const ErrorBoundary = withRouter(ErrorBoundaryImpl)
export default ErrorBoundary

import styled from '@emotion/styled'

const AdminError = styled.div({
  backgroundColor: '#a40',
  color: 'white',
  display: 'inline-block',
  padding: 10,
  marginBottom: 20,
  borderRadius: 10,
})
export default AdminError

import styled from '@emotion/styled'

export const CardBase = styled('div')`
  background-color: white;

  margin: 0 auto 15px auto;
  box-shadow: 0 2px 4px #e3e9f3;
  max-width: 1200px;
`

export const CardHead = styled('div')`
  padding: 15px;
  font-size: 1.5em;
  font-weight: bold;
  background: #333;
  color: white;
`

export const CardBody = styled('div')`
  padding: 15px;
`
export const oneColumnMaxWidth = '1100px'

export const CardBodyTwoColumn = styled('div')`
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  @media (max-width: ${oneColumnMaxWidth}) {
    grid-template-columns: 1fr;
  }
`

export const Card: React.SFC<{
  title: React.ReactNode
  className?: string
}> = ({ children, title, className }) => (
  <CardBase className={className}>
    <CardHead>{title}</CardHead>
    <CardBody>{children}</CardBody>
  </CardBase>
)

import React, { useContext } from 'react'
import ReSt from './rekonstrukcestatu'

const context = React.createContext({
  env: '',
  hosttype: 'web' as 'reflektor' | 'web' | 'admin',
})

export const EnvProvider = context.Provider

export const useEnv = () => useContext(context)

export const Routes = () => <ReSt />

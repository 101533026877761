/** @jsx jsx */
import { jsx, Global } from '@emotion/react'
import { useBariol } from 'utils/fonts'
import { Routes } from 'routes/routes'
import { colors } from 'utils/constants-legacy'

const globalStyles = {
  'html,body': {
    fontSize: '18px',
    color: colors.black,
    backgroundColor: 'white',
    margin: 0,
    padding: 0,

    a: {
      textDecoration: 'none',
      color: 'unset',
    },
  },

  '#app,body,html': {
    height: '100%',
  },

  '#app>div,#app>div>div': { minHeight: '100%' },

  '*': {
    boxSizing: 'border-box' as 'border-box',
  },
}

const App = () => (
  <div
    css={[
      useBariol(),
      { flexGrow: 1, display: 'flex', flexDirection: 'column' },
    ]}
  >
    <Global styles={globalStyles} />
    <Routes />
  </div>
)
const ExportedApp = () => <App />
export default ExportedApp

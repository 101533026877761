import React from 'react'
import styled from '@emotion/styled'
import {
  textElementStyle,
  AdminLabelTextDiv,
  adminInputWrapperStyle,
} from './input-builder'

const Label = styled('label')`
  ${adminInputWrapperStyle};
  display: block;
  div {
    height: 23px;
  }
  select {
    all: unset;
    ${textElementStyle};
    width: 100%;
    :focus {
      border-color: black;
    }
  }
`

type PropertiesExcept<T, E> = {
  [k in keyof T]: k extends E ? never : k
}[keyof T]
type PickExcept<T, E> = Pick<T, PropertiesExcept<T, E>>

const AdminSelect = <Values extends string = string>({
  label,
  state,
  defaultOptionText,
  options,
  allowEmpty = false,
  ...props
}: PickExcept<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >,
  'onChange' | 'value'
> & {
  label: string
  state: { change: (v: Values) => void; value: Values | null }
  defaultOptionText?: string
  options: readonly { value: Values | null; display: string }[]
  allowEmpty?: boolean
}) => (
  <Label>
    <AdminLabelTextDiv>{label}</AdminLabelTextDiv>
    <select
      {...props}
      value={state.value || ''}
      onChange={(evt) => {
        state.change(evt.target.value as any)
      }}
    >
      {(
        (defaultOptionText
          ? [{ value: null, display: defaultOptionText }]
          : []) as { value: Values | null; display: string }[]
      )
        .concat(options)
        .map((option) => (
          <option
            key={option.value || ''}
            value={option.value || ''}
            disabled={option.value === null && !allowEmpty}
          >
            {option.display}
          </option>
        ))}
    </select>
  </Label>
)
export default AdminSelect

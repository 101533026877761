/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useReducer } from 'react'
import { useApolloUri } from 'utils/use-apollo-uri'
import { AdminButtonDark } from './admin-button'
import styled from '@emotion/styled'
import { oneColumnMaxWidth } from './card'
import { useDropzone } from 'react-dropzone'

function fileUploadFormData(file: File) {
  const formData = new FormData()
  formData.append(
    'operations',
    JSON.stringify({
      variables: { file: null },
      query: 'mutation($file: Upload!) {upload(file: $file) { id url } }',
    }),
  )
  formData.append('map', JSON.stringify({ 0: ['variables.file'] }))
  formData.append('0', file)
  return formData
}

function uploadFile({
  uri,
  file,
  onProgress,
}: {
  uri: string
  file: File
  onProgress?: (progress: number) => void
}) {
  const xhr = new XMLHttpRequest()
  xhr.upload.addEventListener('progress', (evt) => {
    if (evt.lengthComputable && onProgress) {
      onProgress(evt.loaded / evt.total)
    }
  })
  xhr.responseType = 'json'

  xhr.open('POST', uri)
  xhr.send(fileUploadFormData(file))

  return {
    promise: new Promise<{ id: string; url: string }>((res, rej) => {
      xhr.addEventListener('load', () => {
        const v = xhr.response
        if (v && v.data && v.data.upload) {
          res(v.data.upload)
        } else {
          console.error(v && v.errors ? v.errors : v)
          rej(new Error('File upload failed'))
        }
      })
      xhr.addEventListener('error', () => rej(new Error('Upload failed')))
      xhr.addEventListener('abort', () => {
        const error = new Error('Upload aborted')
        ;(error as any).aborted = true
        rej(error)
      })
    }),
    abort: () => xhr.abort(),
  }
}

export function useUploadFile() {
  const { uri } = useApolloUri()
  return (args: { file: File; onProgress?: (progress: number) => void }) =>
    uploadFile({ uri, ...args })
}

const useFileInput = ({
  state: { value, change },
}: {
  state: {
    value: { id: string; url: string } | null
    change: (v: null | { id: string; url: string }) => void
  }
}) => {
  const { uri } = useApolloUri()
  const initial = {
    latestBlob: null as Blob | null,
    progress: null as number | null,
    abort: null as (() => void) | null,
  }
  // I use this complicated stuff because of folowing edge case:
  //  - User selects file A
  //  - Upload of A begins
  //  - User selects file B
  //  - Upload of B begins
  //  - Upload of B finishes - it gets onChange event
  //  - Upload of A finishes - it should not get onChange event
  const [state, dispatch] = useReducer(
    (
      prev: typeof initial,
      action:
        | { action: 'START'; blob: Blob; abort: () => void }
        | { action: 'PROGRESS'; blob: Blob; progress: number }
        | {
            action: 'DONE'
            blob: Blob
            file: { id: string; url: string }
          }
        | { action: 'CLEAR' },
    ) => {
      if (action.action === 'PROGRESS') {
        if (prev.latestBlob === action.blob) {
          return { ...prev, progress: action.progress }
        }
        return prev
      }
      if (action.action === 'DONE') {
        if (prev.latestBlob === action.blob) {
          change(action.file)
        }
        return { latestBlob: null, progress: null, abort: null }
      }
      if (action.action === 'CLEAR') {
        if (prev.abort) prev.abort()
        change(null)
        return { latestBlob: null, progress: null, abort: null }
      }
      // action = 'START'
      return { latestBlob: action.blob, progress: null, abort: action.abort }
    },
    initial,
  )

  const zone = useDropzone({
    onDrop(files) {
      if (files && files.length > 0) {
        const blob = files[0]
        const uploader = uploadFile({
          uri,
          file: blob,
          onProgress: (progress) => {
            dispatch({ action: 'PROGRESS', progress, blob })
          },
        })

        dispatch({ action: 'START', blob, abort: uploader.abort })

        uploader.promise
          .then((v) => dispatch({ action: 'DONE', file: v, blob }))
          .catch((e) => {
            // TODO report raven error
            console.error(e)
          })
        // eslint-disable-next-line no-param-reassign
        //evt.target.value = ''
      } else {
        dispatch({ action: 'CLEAR' })
      }
    },

    multiple: false,
    onDragEnter() {},
    onDragLeave() {},
    onDragOver() {},
  })

  return {
    value,
    progress: state.progress,
    zone,
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {},
    clear: (evt?: React.MouseEvent<any>) => {
      if (evt) evt.preventDefault()
      dispatch({ action: 'CLEAR' })
    },
  }
}

export const AdminImageInput = (args: {
  state: {
    value: { id: string; url: string } | null
    change: (v: null | { id: string; url: string }) => any
  }
}) => {
  const { value, clear, progress, zone } = useFileInput(args)

  return (
    <div {...zone.getRootProps()}>
      <div
        style={{
          backgroundColor: '#ddd',
          border: '3px solid #aaa',
          margin: 0,
        }}
      >
        <div
          style={{
            height: 68 * 2 + 15 * 2 - 2,
            backgroundSize: 'contain',
            backgroundImage: value ? `url(${value.url})` : undefined,

            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {value ? null : 'Obrázek nevybrán'}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '3px solid #aaa',
          borderTop: 0,
          height: 42,
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <div
          css={css`
            padding: 7px 0;
            position: absolute;
            text-align: center;
            width: 100%;
            pointer-events: none;
          `}
        >
          {progress && progress <= 0.99
            ? `${(progress * 100).toFixed(0)}%`
            : null}
        </div>
        <AdminButtonDark
          onClick={(evt) => {
            evt.preventDefault()
            zone.inputRef.current!.click()
          }}
        >
          Nahrát nový soubor
        </AdminButtonDark>
        <input
          type="file"
          style={{
            visibility: 'hidden',
            pointerEvents: 'none',
            position: 'absolute',
          }}
          ref={zone.inputRef}
          {...zone.getInputProps()}
        />
        <AdminButtonDark onClick={clear}>Odstranit</AdminButtonDark>
      </div>
    </div>
  )
}

export const AdminImageInputContainer = styled('div')`
  @media (min-width: calc(${oneColumnMaxWidth} + 1px)) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }
`

import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { AdminPoliticianListQuery } from 'queries/queries'

const politicianList = gql`
  query adminPoliticianList {
    politicians(limit: -1) {
      list {
        id
        person {
          id
          name
          surname
        }
        email
      }
    }
  }
`

export const useAdminPoliticianList = () => {
  const res = useQuery<AdminPoliticianListQuery>(politicianList)
  const list = !res || !res.data ? null : res.data.politicians.list
  return useMemo(() => {
    if (!list) return null
    return [...list].sort((a, b) => {
      if (a.person.surname !== b.person.surname)
        return a.person.surname.localeCompare(b.person.surname)
      return a.person.name.localeCompare(b.person.name)
    })
  }, [list])
}

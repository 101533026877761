/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Twemoji from 'components/twemoji'

const AdminHome = () => (
  <div
    css={css`
      font-size: 72px;
    `}
  >
    <Twemoji>
      🎉🌮🎊
      <br />
      ✨🌈🦄
      <br />
      🍣🍭☕
    </Twemoji>
  </div>
)
export default AdminHome

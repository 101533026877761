import LoaderSpinner from 'components/loader-spinner'
import { useAdminUserDeleteMutation } from 'admin/queries/user'
import ChangeRolesContainer from 'admin/containers/use-change-user'
import useChangeUser from 'admin/containers/use-change-user'
import { useAdminUserListQuery, AdminUserListQuery } from 'queries/queries'
import AdminSelect from 'admin/components/select'
import { useState, useMemo } from 'react'
import { useQueryParam } from 'components/use-router'
import AdminInput from 'admin/components/admin-input'

export default function AdminUsers() {
  const deleteMutation = useAdminUserDeleteMutation({})
  const mutations = useChangeUser()
  const { data, loading } = useAdminUserListQuery()
  const [filter, setFilter] = useState<'all' | 'confirmed' | 'unconfirmed'>(
    'all',
  )
  const [search, setSearch] = useQueryParam('q')
  const list = data?.userList?.users
  const sublist = useMemo(
    () =>
      !list
        ? null
        : list
            .filter((u) => {
              if (!u.emails[0]) return false
              return filter === 'confirmed'
                ? u.emails.some((e) => e.verified)
                : filter === 'unconfirmed'
                ? !u.emails.some((e) => e.verified)
                : true
            })
            .filter((item) =>
              item.emails.some((email) =>
                email.value
                  .toLowerCase()
                  .includes((search || '').toLowerCase()),
              ),
            ),
    [filter, list, search],
  )

  if (loading) return <LoaderSpinner />

  if (!list || !sublist) return <>Načítání uživatelů selhalo</>
  const slice = sublist.slice(0, 20)

  return (
    <div>
      <AdminSelect
        state={{ value: filter, change: setFilter }}
        label="Zobrazit"
        options={[
          { display: 'Všechny', value: 'all' },
          { display: 'Pouze ověřené', value: 'confirmed' },
          { display: 'Pouze neověřené', value: 'unconfirmed' },
        ]}
      />
      <AdminInput
        label="Vyhledávání"
        state={{ value: search || '', change: (v) => setSearch(v || null) }}
      />
      {search ? (
        <>
          <i>
            Zobrazuji {slice.length} výsledků z {sublist.length}
          </i>
          <div style={{ height: 8 }} />
          {slice.map((user) => (
            <User user={user} mutations={mutations} key={user.id} />
          ))}
        </>
      ) : (
        <i>Pro nalezení uživatele použij vyhledávání...</i>
      )}
    </div>
  )
}
type NotNull<T> = T extends null ? never : T extends undefined ? never : T
function User({
  user,
  mutations,
}: {
  user: NotNull<AdminUserListQuery['userList']>['users'][0]
  mutations: ReturnType<typeof useChangeUser>
}) {
  return (
    <div>
      {user.emails[0].value}{' '}
      {user.emails.some((e) => e.verified) ? '' : '(neověřen)'}{' '}
      {user.roles.includes('admin') ? (
        <button
          onClick={() => mutations.removeRole({ user: user.id, role: 'admin' })}
        >
          Odebrat admin práva
        </button>
      ) : (
        <>
          <button
            role="button"
            onClick={() => mutations.addRole({ user: user.id, role: 'admin' })}
          >
            Udělat adminem
          </button>
          <button
            role="button"
            onClick={() => mutations.delete({ user: user.id })}
          >
            Smazat
          </button>
        </>
      )}
    </div>
  )
}

function userHasVerifiedEmail(
  user: NotNull<
    NotNull<ReturnType<typeof useAdminUserListQuery>['data']>['userList']
  >['users'][0],
) {
  return user.emails.some((e) => e.verified)
}

import { Switch, Route } from 'react-router'
import NotFound from 'routes/not-found'
import AdminHome from 'admin/routes/home'
import Login from 'admin/routes/login'
import React, { Suspense } from 'react'
import WithAdminMenu from 'admin/components/with-menu'
import AdminUsers from './users'
import LoaderSpinner from 'components/loader-spinner'
import AdminReflektorUpdateList from './list-reflektor-update'
import UpsertReflektorUpdate from './upsert-reflektor-update/upsert-reflektor-update'
import AdminTopicList from './list-topic'
import AdminUpsertTopic from './upsert-topic'
import AdminPollEvalList from './list-poll-eval'
import AdminUpsertPollEval from './upsert-poll-eval'
import AdminTestNotification from './test-notification'
import { useAdminMe } from 'admin/queries/user'
import AdminPartyList from './list-party'
import AdminUpdateParty from './update-party'
import AdminUpsertActivityGroup from './upsert-activity-group'
import AdminListActivity from './list-activity'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import AdminActivityImport from 'admin/import/activity/import-activity'
import AdminCommitteeVoteImport from 'admin/import/comittee-vote/import-committee-vote'
import AdminAudit from './audit'
import AdminApiToken from './api-token'
import AdminPoliticianList from './list-politicians'
import AdminUpsertPolitician from './upsert-politician'
import { AdminDbExplorer } from './db-explorer/db-explorer'
import { AdminGlobalSettings } from './global-settings/global-settings'
import { AdminRecalculateEvaluation } from './recalculate-evaluation/recalculate-evaluation'
import { PDS } from './pds'

const Routes = () => (
  <WithAdminMenu>
    <Suspense fallback={<LoaderSpinner />}>
      <Switch>
        <Route path="/admin" exact>
          <AdminHome />
        </Route>
        <Route path="/admin/users">
          <AdminUsers />
        </Route>
        <Route path="/admin/api-token">
          <AdminApiToken />
        </Route>
        <Route
          path="/admin/reflektor-update/list"
          render={({ match }) => <AdminReflektorUpdateList />}
        />
        <Route
          path="/admin/reflektor-update/:id"
          render={({ match }) => <UpsertReflektorUpdate id={match.params.id} />}
        />
        <Route path="/admin/topic/list" render={() => <AdminTopicList />} />
        <Route
          path="/admin/topic/:id"
          render={({ match }) => <AdminUpsertTopic id={match.params.id} />}
        />
        <Route path="/admin/party/list" render={() => <AdminPartyList />} />
        <Route
          path="/admin/party/:id"
          render={({ match }) => <AdminUpdateParty id={match.params.id} />}
        />
        <Route
          path="/admin/politician/list"
          render={() => <AdminPoliticianList />}
        />
        <Route
          path="/admin/politician/:id"
          render={({ match }) => <AdminUpsertPolitician id={match.params.id} />}
        />

        <Route
          path="/admin/poll-eval/list"
          render={() => <AdminPollEvalList />}
        />
        <Route
          path="/admin/poll-eval/committee-import"
          render={() => <AdminCommitteeVoteImport />}
        />
        <Route
          path="/admin/poll-eval/:id"
          render={({ match }) => <AdminUpsertPollEval id={match.params.id} />}
        />
        <Route
          path="/admin/activity/import"
          render={() => <AdminActivityImport />}
        />
        <Route
          path="/admin/activity/list"
          render={() => <AdminListActivity />}
        />
        <Route
          path="/admin/activity/:id"
          render={({ match }) => (
            <AdminUpsertActivityGroup id={match.params.id} />
          )}
        />
        <Route path="/admin/audit" render={() => <AdminAudit />} />
        <Route
          path="/admin/test-notification"
          render={() => <AdminTestNotification />}
        />
        <Route path="/admin/db-explorer" render={() => <AdminDbExplorer />} />
        <Route
          path="/admin/global-settings"
          render={() => <AdminGlobalSettings />}
        />
        <Route
          path="/admin/recalculate-evaluation"
          render={() => <AdminRecalculateEvaluation />}
        />
        <Route path="/admin/pds" render={() => <PDS />} />
        <Route render={() => <NotFound />} />
      </Switch>
    </Suspense>
  </WithAdminMenu>
)

function RoutesOrLogin() {
  const user = useAdminMe()
  if (user === 'loading') return <LoaderSpinner />
  if (!user) return <Login />
  if (!user.roles.includes('admin')) {
    return <Login message="Nejste admin" />
  }
  return <Routes />
}

function RoutesOrLoginSuspense() {
  return (
    <Suspense fallback={<LoaderSpinner />}>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale="cs-CZ">
        <RoutesOrLogin />
      </MuiPickersUtilsProvider>
    </Suspense>
  )
}

export default RoutesOrLoginSuspense

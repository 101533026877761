/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useCallback } from 'react'
import AdminInput from 'admin/components/admin-input'
import AdminButton from 'admin/components/admin-button'
import gql from 'graphql-tag'
import {
  useAdminSetGlobalSettingMutation,
  useAdminGlobalSettingsQuery,
  AdminGlobalSettingsDocument,
} from 'queries/queries'
import LoaderSpinner from 'components/loader-spinner'
import AdminSelect from 'admin/components/select'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

gql`
  query AdminGlobalSettings {
    globalSettings {
      value
      name
      description
      readonly: graphqlReadonly
      enum {
        display
        value
      }
    }
  }
`
gql`
  mutation AdminSetGlobalSetting($name: String!, $value: String) {
    setGlobalSetting(name: $name, value: $value) {
      value
    }
  }
`

function Edit({
  name,
  initialValue,
  enum: enumValues,
}: {
  name: string
  initialValue: string
  enum: readonly { display: string; value: string }[] | null
}) {
  const [mutate, { loading, error }] = useAdminSetGlobalSettingMutation({
    refetchQueries: [{ query: AdminGlobalSettingsDocument }],
    awaitRefetchQueries: true,
  })
  const [value, change] = useState(initialValue)

  let input = (
    <AdminInput label="Hodnota" name="value" state={{ change, value }} />
  )

  if (enumValues) {
    input = (
      <AdminSelect
        options={enumValues}
        label="Hodnota"
        name="value"
        state={{ change, value }}
      />
    )
  }

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault()
        const data = new FormData(evt.currentTarget)
        mutate({ variables: { name, value: data.get('value') as any } })
      }}
    >
      {input}
      {!error ? null : error.graphQLErrors.some(
          (e) => e.extensions?.code === 'BAD_USER_INPUT',
        ) ? (
        <div css={{ color: 'red' }}>
          {error.graphQLErrors.find((e) => e.extensions?.czech)?.extensions
            ?.czech ?? 'Neplatná hodnota'}
        </div>
      ) : (
        <div css={{ color: 'red' }}>Něco se pokazilo</div>
      )}
      <AdminButton type="submit" disabled={value === initialValue || loading}>
        {loading ? 'Ukládám' : 'Uložit'}
      </AdminButton>
    </form>
  )
}

function Link({ href, children }: { href?: string; children: any }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      style={{ textDecoration: 'underline' }}
    >
      {children}
    </a>
  )
}

export function AdminGlobalSettings() {
  const settings = useAdminGlobalSettingsQuery()
  if (settings.loading) return <LoaderSpinner />
  const gs = settings.data?.globalSettings
  if (!gs) return <div>Něco se pokazilo</div>

  return (
    <div>
      <h1>Globální nastavení</h1>
      {gs.map((g) => (
        <div key={g.name}>
          <h2 css={{ marginBottom: 8, marginTop: 32 }}>{g.name}</h2>
          <div css={{ marginBottom: 8 }}>
            <ReactMarkdown>{g.description}</ReactMarkdown>
          </div>
          {g.readonly ? (
            <div>{g.value}</div>
          ) : (
            <Edit name={g.name} initialValue={g.value} enum={g.enum ?? null} />
          )}
        </div>
      ))}
    </div>
  )
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Card, CardBodyTwoColumn } from 'admin/components/card'
import { EditCard } from 'admin/components/edit-card'
import {
  AdminImageInput,
  AdminImageInputContainer,
} from 'admin/components/file-input'
import AdminInput from 'admin/components/admin-input'
import { useHistory } from 'react-router-dom'
import { useFormState } from 'admin/utils/use-form-state'
import { AdminLabelTextDiv } from 'admin/components/input-builder'
import { useAdminTopic, useAdminUpsertTopicMutation } from 'admin/queries/topic'
import AdminTextEditor, {
  compareAdminTextEditorValue,
} from 'admin/components/admin-text-editor'
import { AdminCheckbox } from 'admin/components/checkbox'

const AdminUpsertTopic = ({ id }: { id: string }) => {
  const topic = useAdminTopic(id)

  const formState = useFormState(
    topic && {
      name: topic.name,
      icon: topic.icon ? { url: topic.icon.url, id: topic.icon.id } : null,
      description: topic.description,
      shareLink: topic.shareLink,
      availableInReflektor: topic.availableInReflektor,
      availableInEvaluation: topic.availableInEvaluation,
    },
  )({
    converters: {
      icon: (v) => (v ? v.id : null),
    },
    comparators: {
      description: compareAdminTextEditorValue,
    },
  })

  const history = useHistory()
  const { mutate, error, mutating } = useAdminUpsertTopicMutation({
    onSuccess: (v) => {
      if (id === 'new') {
        if (v.upsertTopic) {
          history.replace(`/admin/topic/${v.upsertTopic.topic.id}`)
        }
      }
    },
    onError: () => {},
  })

  if (formState.failed) return <>Téma nenalezeno</>
  const { changed, patch, state } = formState

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <EditCard
        title={id === 'new' ? 'Vložit nové téma' : 'Upravit téma'}
        changed={changed}
        saving={mutating}
        onSave={() => {
          mutate({
            variables: {
              id: id === 'new' ? undefined : id,
              data: patch,
            },
          })
        }}
        CardBody={CardBodyTwoColumn}
      >
        <AdminInput label="Jméno" state={state.name} />
        <AdminInput
          label="Odkaz pro sdílení"
          state={state.shareLink}
          placeholder="Výchozí hodnota: /"
        />

        <AdminTextEditor label="Popis" state={state.description} />

        <AdminImageInputContainer>
          <AdminLabelTextDiv>Obrázek</AdminLabelTextDiv>
          <AdminImageInput state={state.icon} />
        </AdminImageInputContainer>

        <AdminCheckbox
          state={state.availableInReflektor}
          label="Dostupné v reflektoru"
        />
        <AdminCheckbox
          state={state.availableInEvaluation}
          label="Dostupné v hodnocení"
        />
      </EditCard>
    </>
  )
}
export default AdminUpsertTopic

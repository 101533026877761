/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AdminButtonDark } from 'admin/components/admin-button'
import { Card } from 'admin/components/card'
import { DateTime } from 'luxon'
import { Fragment, useEffect, useReducer, useState } from 'react'
import { View } from 'react-native'

export function PDS() {
  const [logs, setLogs] = useState({
    log: <></>,
    time: DateTime.invalid('bleh'),
  })
  useEffect(() => {
    let timeout = setTimeout(load, 0)
    return () => clearTimeout(timeout)
    function load() {
      fetch('/api/pds/logs')
        .then(async (v) => ({ status: v.status, log: await v.arrayBuffer() }))
        .then(
          ({ log, status }) => {
            if (status === 200) {
              setLogs({ log: parseLog(log), time: DateTime.now() })
            }
          },
          (err) => void console.error(err),
        )
        .then(() => void setTimeout(load, 2000))
    }
  }, [])
  const [running, setRunning] = useState(false)
  const [mainLogs, appendMainLogs] = useReducer(
    (state: readonly JSX.Element[], action: string) =>
      state.concat(
        <div key={state.length}>
          <b>{DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS)}</b>{' '}
          {action}
        </div>,
      ),
    [],
  )

  function action(a: string, method = 'POST') {
    if (running) return
    setRunning(true)
    fetch('/api/pds/' + a, { method })
      .then((v) => v.text())
      .then(appendMainLogs, (err) => {
        console.error(err)
        appendMainLogs('Něco se pokazilo')
      })
      .then(() => {
        setRunning(false)
      })
  }

  return (
    <>
      <Card title="PDS importér">
        <View style={{ flexDirection: 'row' }}>
          <AdminButtonDark
            disabled={running}
            css={{ border: '2px solid #222' }}
            onClick={() => void action('start')}
          >
            Spustit PDS
          </AdminButtonDark>
          <View style={{ width: 16 }} />
          <AdminButtonDark
            disabled={running}
            css={{ border: '2px solid #222' }}
            onClick={() => void action('stop')}
          >
            Ukončit PDS
          </AdminButtonDark>
          <View style={{ width: 16 }} />
          <AdminButtonDark
            disabled={running}
            css={{ border: '2px solid #222' }}
            onClick={() => void action('status', 'GET')}
          >
            Zjistit stav
          </AdminButtonDark>
        </View>
        <code>
          <pre css={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>{mainLogs}</pre>
        </code>
      </Card>
      <Card title="Logy za posledních 24 hodin">
        {logs.time.isValid ? (
          <div>
            Poslední aktualizace:{' '}
            {logs.time.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
          </div>
        ) : null}
        <code>
          <pre css={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>{logs.log}</pre>
        </code>
      </Card>
    </>
  )
}

function parseLog(log: ArrayBuffer) {
  let prev = ''
  let repeat = 1

  const decoder = new TextDecoder()
  const items: (JSX.Element | null)[] = []
  const view = new DataView(log)
  const offset = view.getUint8(0) === 1 || view.getUint8(0) === 2 ? 8 : 0
  for (let i = 0; i < log.byteLength; ) {
    const stream = view.getUint8(i)
    const length = offset ? view.getUint32(i + 4, false) : findN(i, view)

    const text = decoder.decode(new DataView(log, i + offset, length))
    items.push(format(text, stream === 2, items.length))
    i += offset + length
    if (length + offset === 0) break
  }
  return <>{items}</>

  function findN(from: number, view: DataView) {
    for (let i = from; i < view.byteLength; i++) {
      if (view.getInt8(i) === 10) return i - from + 1
    }
    return view.byteLength - from
  }

  function format(line: string, stderr: boolean, i: number) {
    const idx = line.indexOf(' ')
    const date = DateTime.fromISO(line.substring(0, idx))
    if (date.isValid) line = line.substring(idx)
    if (prev && line === prev) {
      repeat++
      return null
    }
    prev = line
    if (repeat > 1) {
      let ret = (
        <Fragment key={i}>
          ... stejná zpráva {repeat}x ...{'\n'}
          <div css={{ height: 5 }} />
          {print(date, line, stderr)}
        </Fragment>
      )
      repeat = 0
      return ret
    }
    repeat = 0
    return <Fragment key={i}>{print(date, line, stderr)}</Fragment>
  }
  function print(date: DateTime, line: string, stderr: boolean) {
    return (
      <Fragment>
        {date.isValid ? (
          <span css={{ fontWeight: 'bold', color: stderr ? 'red' : undefined }}>
            {date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
          </span>
        ) : (
          <span css={{ fontWeight: 'bold', color: stderr ? 'red' : undefined }}>
            [stderr]
          </span>
        )}
        {line}
        <div css={{ height: 5 }} />
      </Fragment>
    )
  }
}

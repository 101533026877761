import { useState, useCallback } from 'react'
import AdminInput from 'admin/components/admin-input'
import { useQueryParam } from 'components/use-router'
import { useAdminDbExplorerQuery } from 'queries/queries'
import LoaderSpinner from 'components/loader-spinner'
import { JSONView } from './json-view'
import AdminButton from 'admin/components/admin-button'
import { idSet } from './db-explorer-node'

function map(o: any): any {
  if (typeof o !== 'object' || !o) return o
  if (Object.keys(o).length === 2 && typeof o.__typename === 'string') {
    if (o.__typename.endsWith('Page') && Array.isArray(o.list)) {
      return o.list.map(map)
    }
    if (typeof o.id === 'string') {
      return o.id
    }
  }
  if (Array.isArray(o)) {
    return o.map(map)
  }
  return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, map(v)]))
}

function isLink(key: string) {
  const parts = key.split(':')
  const id = Number.parseInt(parts[1])
  return id === id && parts.length === 2 && idSet.has(parts[0])
}

function Node({ id, add }: { id: string; add: (v: string) => void }) {
  const q = useAdminDbExplorerQuery({ variables: { id } })
  if (q.loading) return <LoaderSpinner />
  const node = q.data?.node
  if (!node)
    return (
      <div>
        <h2>Něco se pokazilo</h2>
        <code>
          <pre>
            {q.error?.networkError?.stack || JSON.stringify(q.error, null, 2)}
          </pre>
        </code>
      </div>
    )
  return (
    <div>
      <h2>{id}</h2>
      <JSONView
        data={map(node)}
        isLink={isLink}
        onClick={add}
        expandedDepth={1}
      />
    </div>
  )
}

export function AdminDbExplorer() {
  const [nodess, setNodes] = useQueryParam('nodes')

  const nodes = nodess?.split(',').filter((v) => v) ?? []
  const add = useCallback(
    (v: string) => {
      const n = nodess?.split(',').filter((v) => v) ?? []
      if (!n.includes(v))
        setNodes((nodess ? nodess + ',' : '') + v, { push: true })
    },
    // 'cause bugged
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodess, setNodes],
  )
  const [node1, setNode1] = useState('')

  return (
    <div>
      {nodes.length < 1 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.target as any)
            add(data.get('node') as any)
          }}
        >
          <AdminInput
            state={{ value: node1, change: setNode1 }}
            label="id"
            name="node"
          />
          <AdminButton>Zobrazit</AdminButton>
        </form>
      ) : null}
      {nodes.map((node, i) => (
        <Node id={node} key={i} add={add} />
      ))}
    </div>
  )
}

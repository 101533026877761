import React, { useContext, PropsWithChildren } from 'react'

import { CardBase, CardHead, CardBody } from 'admin/components/card'

const XLSXContext = React.createContext(null as typeof import('xlsx') | null)
export function useXLSX() {
  const xlsx = useContext(XLSXContext)
  if (!xlsx) throw new Error('XLSX not found')
  return xlsx
}

function Card({ children }: PropsWithChildren<{}>) {
  return (
    <CardBase>
      <CardHead>Import aktivity</CardHead>
      <CardBody>{children}</CardBody>
    </CardBase>
  )
}

let xlsxCache: null | typeof import('xlsx') = null
export const XLSXProvider = React.lazy(() =>
  import('xlsx').then((xlsx) => {
    xlsxCache = xlsx
    return {
      default: ({ children }: PropsWithChildren<{}>) => (
        <XLSXContext.Provider value={xlsxCache}>
          {children}
        </XLSXContext.Provider>
      ),
    }
  }),
)

import { EditorView } from 'prosemirror-view'
import { useUploadFile } from '../file-input'
import styled from '@emotion/styled'
import { useTextEditorView } from './text-editor-view-context'
import { placeholderPlugin, findPlaceholder } from './placeholder-plugin'
import { textEditorSchema as schema } from './text-editor-schema'
import { Raven } from 'utils/globals'

const FileInput = styled.input({
  // TODO add nicer button for image insertion
  //opacity: 0,
  //pointerEvents: 'none',
  //position: 'absolute',
})

export function TextEditorFileUpload() {
  const view = useTextEditorView()
  const uploadFile = useUploadFile()
  function startImageUpload(file: File) {
    const id = {}

    {
      const tr = view.state.tr
      if (!tr.selection.empty) tr.deleteSelection()
      tr.setMeta(placeholderPlugin, {
        add: { id, pos: tr.selection.from, width: 100, height: 100 },
      })
      view.dispatch(tr)
    }

    uploadFile({ file })
      .promise //.then(url => new Promise(res => setTimeout(() => res(url), 20000)))
      .then(({ url }) => {
        const pos = findPlaceholder(view.state, id)
        // If the content around the placeholder has been deleted, drop
        // the image
        if (pos === null) return
        // Otherwise, insert it at the placeholder's position, and remove
        // the placeholder
        const urlParts = url.split('/')
        const fname = urlParts[urlParts.length - 1]
        const fnameParts = fname.split('.')
        const fnameExt = fnameParts[fnameParts.length - 1]
        const node = ['png', 'jpg', 'jpeg'].includes(fnameExt)
          ? schema.nodes.image.create({ src: url })
          : schema.text(urlParts[urlParts.length - 1], [
              schema.mark('link', { href: url, title: null }),
            ])
        view.dispatch(
          view.state.tr
            .replaceWith(pos, pos, node)
            .setMeta(placeholderPlugin, { remove: { id } }),
        )
        console.log(url)
      })
      .catch((e) => {
        view.dispatch(
          view.state.tr.setMeta(placeholderPlugin, { remove: { id } }),
        )
        if (Raven) Raven.captureException(e)
        console.error(e)
      })
  }
  return (
    <FileInput
      type="file"
      onChange={(evt) => {
        evt.preventDefault()

        if (
          view.state.selection.$from.parent.inlineContent &&
          evt.target.files &&
          evt.target.files.length
        ) {
          startImageUpload(evt.target.files[0])
          // eslint-disable-next-line no-param-reassign
          evt.target.value = ''
        }
        view.focus()
      }}
    />
  )
}

import React from 'react'
import { DateTime } from 'luxon'

function formatDate(date: DateTime | string) {
  if (typeof date === 'string') return date
  return `${date.day}. ${date.month}. ${date.year}`
}

class DatesRenderer extends React.Component<
  {
    dates: (DateTime | string)[]
    children: (date: string[]) => React.ReactNode
  },
  { local: boolean }
> {
  state = { local: false }

  render() {
    const dates = this.props.dates.map((d) =>
      typeof d === 'string'
        ? d
        : d
            .setZone(this.state.local ? 'local' : 'Europe/Prague')
            .toFormat('d. M. yyyy'),
    )
    return this.props.children(dates)
  }

  componentDidMount() {
    this.setState({ local: true })
  }
}

const DateRenderer = ({
  date,
  children,
}: {
  date: DateTime | string
  children: (date: DateTime | string) => React.ReactNode
}) => <DatesRenderer dates={[date]}>{(v) => children(v[0])}</DatesRenderer>

export function LocalizedDate({
  date,
  className,
}: {
  date: DateTime
  className?: string
}) {
  return (
    <DateRenderer date={date}>
      {(datetime) => <span className={className}>{formatDate(datetime)}</span>}
    </DateRenderer>
  )
}

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'

export default function AdminButton(
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) {
  return (
    <button
      {...props}
      css={css`
        /* button reset */
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

        /* our styles */
        font-size: 0.6em;
        padding: ${0.2 / 0.6}em ${0.4 / 0.6}em;
        background: #fff;
        color: black;
        :hover {
          text-decoration: underline;
        }

        border: 2px solid white;
        :focus {
          border-color: lightblue;
        }

        :active {
          transform: translate(0px, 1px);
        }

        :disabled {
          color: #aaa;
          :hover {
            text-decoration: none;
          }
        }

        /* space to the right */
        margin-right: 15px;
      `}
    />
  )
}

export const AdminButtonDark = (props: Parameters<typeof AdminButton>[0]) => (
  <AdminButton
    {...props}
    css={css`
      font-size: 1em;
      padding: 7px;
      height: 45px;
      border: 3px solid #aaa;
      color: white;
      background: #333;
      box-sizing: border-box;
      margin: -3px;
      :focus {
        border-color: black;
      }
    `}
  />
)

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ColumnMap } from './activity-select-columns'
import { WorkSheet } from 'xlsx/types'
import { useMemo, memo, useState } from 'react'
import { useXLSX } from '../import-utils'
import { convertRows, ConvertedRows } from './convert-rows'
import { Tooltip } from '@material-ui/core'
import { notNull } from '@codewitchbella/ts-utils'
import { useMapTopic } from '../use-map-topic'
import { useUpsertActivityGroup } from 'admin/routes/upsert-activity-group'
import { Raven } from 'utils/globals'
import { SingleTwemoji } from 'components/twemoji'
import { useGetSubmandate } from '../use-get-submandate'
import {
  UpsertActivityGroup,
  UpsertActivityGroupActivity,
} from 'queries/queries'
import LoaderSpinner from 'components/loader-spinner'

function Insert({ data }: { data: readonly UpsertActivityGroup[] }) {
  const topicNames = useMemo(
    () =>
      data
        .map((d) => d.topic)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx),
    [data],
  )
  const { mapping: topicMap, component: topicMapComponent } = useMapTopic({
    topicNames,
  })
  const [mut] = useUpsertActivityGroup()

  const [status, setStatus] = useState<JSX.Element | string>('')
  async function upsert(map: { [topic: string]: string }) {
    setStatus('Vkládám')
    try {
      let i = 0
      for (const d of data) {
        i += 1
        setStatus(`Vkládám ${i}/${data.length}`)
        await mut({
          variables: { data: { ...d, topic: map[d.topic] } },
        })
      }
      setStatus('Přepočítávám hodnocení')
      await new Promise((r) => setTimeout(r, 750))
      const ret = await fetch('/api/recalculate-evaluation')
      const json = await ret.json()
      if (json.success) {
        setStatus(
          <>
            Hotovo! <SingleTwemoji emoji="🎉" />
          </>,
        )
      } else {
        console.log(json)
        setStatus(
          <>
            Import úspěšný <SingleTwemoji emoji="🎉" /> ale hodnotící script
            selhal <SingleTwemoji emoji="😢" />
          </>,
        )
      }
    } catch (e) {
      console.error(e)
      if (Raven) Raven.captureException(e)
      setStatus('Nastala chyba')
    }
  }
  return (
    <>
      <div>
        7. Vyber témata odpovídajícím názvům témat v tabulce
        {topicMapComponent}
      </div>
      {topicMap && (
        <div>
          8. Stiskni následující tlačítko
          <div>
            {status || (
              <button type="button" onClick={() => upsert(topicMap)}>
                Vložit
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

function Group({
  data,
  inspectSubmandate,
}: {
  data: UpsertActivityGroup
  inspectSubmandate: (sub: string) => string | null
}) {
  return (
    <div css={{ border: '1px solid black', margin: 3 }}>
      <div>Pozitivní: {data.isPositive ? 'Ano' : 'Ne'}</div>
      <div>Téma: {data.topic}</div>
      <div>
        Aktivity:{' '}
        {data.activities.map((act, actIdx) => (
          <div
            key={actIdx}
            css={{
              marginLeft: 20,
              marginTop: 10,
              borderLeft: '1px solid black',
            }}
          >
            <div>Váha: {act.weight}%</div>
            <div>popis: {act.description} </div>
            <div>počet submandátů: {act.submandates.length}</div>
            <div>
              Submandáty:{' '}
              {act.submandates.map((sub) => inspectSubmandate(sub)).join(' ')}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function ActivitiyImportMapSubmandates({
  data,
}: {
  data: ConvertedRows['success']
}) {
  const [submandateSrc, refetch] = useGetSubmandate()
  if (submandateSrc === 'loading') return <LoaderSpinner />
  if (submandateSrc === 'error')
    return (
      <div>
        Načítání selhalo
        <br />
        <button type="button" onClick={() => refetch()}>
          Zkusit znovu
        </button>
      </div>
    )
  const inserts = data.map((d) => {
    const submandates = d.proposer.map((proposer) => {
      const submandate = submandateSrc.get(proposer, d.date)
      if (typeof submandate === 'string')
        return { tag: 'fail' as const, reason: submandate }
      return { tag: 'success' as const, submandate, data }
    })
    const fail = submandates.find((s) => s.tag === 'fail')
    if (fail && fail.tag === 'fail') return fail.reason
    return {
      ...d,
      submandates: submandates.map((s) => s.submandate || null).filter(notNull),
    }
  })

  const fail = inserts.find((i) => typeof i === 'string')
  if (fail) return <div>Vyhledávání politiků selhalo: {fail}</div>

  const filteredInserts = inserts
    .map((i) => (typeof i === 'string' ? null : i))
    .filter(notNull)
  const grouped = new Map<
    number,
    // make activities writable
    Omit<UpsertActivityGroup, 'activities'> & {
      activities: UpsertActivityGroupActivity[]
    }
  >()
  const electionPeriods = new Map<number, string>()
  let error = ''
  for (const insert of filteredInserts) {
    if (!grouped.has(insert.group)) {
      grouped.set(insert.group, {
        isPositive: insert.signedWeight > 0,
        topic: insert.topic, // TODO: convert this to ID
        activities: [],
      })
    }
    if (insert.submandates.length < 1) {
      error = `Aktivita skupiny ${insert.group} nemá žádný submandát`
      break
    }
    const differentEpError = `Všechny submandáty ve skupině ${insert.group} nejsou ze stejného volebního období. Nesmíchal jsi poslaneckou sněmovnu a senát?`
    if (
      !insert.submandates.every(
        (sub, _, list) => sub.electionPeriod === list[0].electionPeriod,
      )
    ) {
      error = differentEpError
      break
    }
    const otherEP = electionPeriods.get(insert.group)
    if (otherEP) {
      if (otherEP !== insert.submandates[0].electionPeriod) {
        error = differentEpError
        break
      }
    } else {
      electionPeriods.set(insert.group, insert.submandates[0].electionPeriod)
    }
    const val = grouped.get(insert.group)!
    val.activities.push({
      description: insert.description,
      submandates: insert.submandates.map((sub) => sub.submandate),
      weight: Math.abs(insert.signedWeight) * 100,
      hidden: false,
    })
    if (val.isPositive !== insert.signedWeight > 0)
      error = `Aktivity skupiny ${insert.group} mají různou pozitivitu`
    if (val.topic !== insert.topic)
      error = `U aktivit skupiny ${insert.group} není všude stejné téma`
    if (error) break
  }

  if (error) {
    return <div>Nastala chyba: {error}</div>
  }

  const result = Array.from(grouped.values())

  const failedGroups = result.filter((r) =>
    r.activities
      .map((a) => a.submandates)
      .reduce((a, b) => a.concat(b))
      .some((v, idx, arr) => arr.indexOf(v) !== idx),
  )
  const okGroups = result.filter((r) => !failedGroups.includes(r))

  return (
    <>
      <div>
        5. Tyto skupiny obsahují stejný submandát několikrát a proto byly
        odfiltrovány. Zkontroluj je a v ideálním případě je oprav.
        <div css={{ maxHeight: 700, overflow: 'auto' }}>
          {failedGroups.map((ins, insIdx) => (
            <Group
              data={ins}
              inspectSubmandate={submandateSrc.inspect}
              key={insIdx}
            />
          ))}
        </div>
      </div>
      <div>
        6.Bude vloženo následující
        <div css={{ maxHeight: 700, overflow: 'auto' }}>
          {okGroups.map((ins, insIdx) => (
            <Group
              data={ins}
              inspectSubmandate={submandateSrc.inspect}
              key={insIdx}
            />
          ))}
        </div>
      </div>
      <Insert data={okGroups} />
    </>
  )
}

export const FinishActivityImport = memo(
  ({ columnMap, sheet }: { columnMap: ColumnMap; sheet: WorkSheet }) => {
    const xlsx = useXLSX()

    const border = '1px solid gray'
    const header = useMemo(
      () =>
        Object.keys(sheet)
          .filter((cellName) => /^[a-z]+1$/i.test(cellName))
          .sort()
          .map((headerCell) => sheet[headerCell]!.v)
          .filter((v) => !!v),
      [sheet],
    )
    const rows: any[] = xlsx.utils.sheet_to_json(sheet)

    const converted = convertRows(rows, columnMap)

    return (
      <>
        <div>
          4.{' '}
          <Tooltip title="Tyto řádky obsahují chyby. Zkontroluj prosím, že to nejsou řádky u kterých očekáváš že budou importovány. V prvním sloupci je krátký popis první nalezené chyby.">
            <span>Zkontroluj chybné řádky</span>
          </Tooltip>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: `repeat(${header.length + 2}, 1fr)`,
              '> div': {
                padding: 2,
                borderRight: border,
                borderBottom: border,
              },
              border,
              maxHeight: 500,
              overflow: 'auto',
            }}
          >
            <div>Chyba</div>
            <div>Číslo řádku</div>
            {header.map((h, i) => (
              <div key={i}>{h}</div>
            ))}
            {converted.failed.map((el, i) => [
              <div key={`error${i}`}>{el.error}</div>,
              <div key={`index${i}`}>{el.index}</div>,
              ...header.map((h) => (
                <div key={i + h}>{JSON.stringify(el.columns[h])}</div>
              )),
            ])}
          </div>
        </div>
        <ActivitiyImportMapSubmandates data={converted.success} />
      </>
    )
  },
)

import Link from 'components/link'
import styled from '@emotion/styled'
import { useAdminTopicList } from 'admin/queries/topic'

const ListItem = styled(Link)({
  display: 'block',
  padding: 5,
})

const AdminTopicList = () => {
  const { list } = useAdminTopicList()
  if (!list) return null
  return (
    <div>
      <ListItem to="/admin/topic/new">Vložit nové téma</ListItem>
      {list.map((item) => (
        <ListItem key={item.id} to={`/admin/topic/${item.id}`}>
          {item.name}
        </ListItem>
      ))}
    </div>
  )
}
export default AdminTopicList

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useRef, useLayoutEffect, useMemo } from 'react'
import twemoji from 'twemoji'

export default function Twemoji(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) {
  const ref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    twemoji.parse(ref.current)
  })
  return <div css={{ 'img.emoji': { height: '1em' } }} ref={ref} {...props} />
}

export function SingleTwemoji({
  emoji,
  className,
}: {
  emoji: string
  className?: string
}) {
  const html = useMemo(() => {
    const parsed = twemoji.parse(emoji).trim()
    // regex means: < followed by many non> and finished by /> and nothing else
    if (!/^<[^>]+\/>$/.test(parsed) || parsed === emoji)
      throw new Error(`SingleTwemoji accepts only one emoji got ${emoji}`)
    return parsed
  }, [emoji])

  return (
    <span
      dangerouslySetInnerHTML={{ __html: html }}
      css={{ 'img.emoji': { height: '1em' } }}
      className={className}
    />
  )
}

import {
  Mutation,
  MutationComponentOptions,
} from '@apollo/client/react/components'
import React from 'react'

type MutationProperties =
  //| 'mutation' we remove this one
  | 'ignoreResults'
  | 'optimisticResponse'
  | 'variables'
  | 'refetchQueries'
  | 'update'
  | 'children'
  | 'onCompleted'
  | 'onError'
  | 'client'
  | 'context'

export function mutation<Data, Variables = undefined>(m: any) {
  return function CreatedMutation(
    props: Pick<MutationComponentOptions<Data, Variables>, MutationProperties>,
  ) {
    return (
      <Mutation mutation={m} {...(props as any)}>
        {props.children}
      </Mutation>
    )
  }
}

import Link from 'components/link'
import styled from '@emotion/styled'
import gql from 'graphql-tag'
import { useAdminListPoliticiansQuery } from 'queries/queries'
import AdminInput from 'admin/components/admin-input'
import latinize from 'latinize'
import { useQueryParam } from 'components/use-router'

const ListItem = styled(Link)({
  display: 'block',
  padding: 5,
})

gql`
  query AdminListPoliticians {
    politicians(limit: -1) {
      list {
        id
        person {
          name
          surname
        }
      }
    }
  }
`

export default function AdminPoliticianList() {
  const list = useAdminListPoliticiansQuery()?.data?.politicians?.list
  const [search, setSearch] = useQueryParam('q')
  if (!list) return null
  const sublist = list.filter((item) =>
    latinize(`${item.person.name} ${item.person.surname}`)
      .toLowerCase()
      .includes(latinize(search || '').toLowerCase()),
  )
  const slice = sublist.slice(0, 20)
  return (
    <div>
      <ListItem to="/admin/politician/new">Vložit nového politika</ListItem>
      <div style={{ height: 20 }} />
      <AdminInput
        label="Vyhledávání"
        state={{ value: search || '', change: (v) => setSearch(v || null) }}
      />
      {search ? (
        <>
          <i>
            Zobrazuji {slice.length} výsledků z {sublist.length}
          </i>
          <div style={{ height: 8 }} />
          {slice.map((item) => (
            <ListItem key={item.id} to={`/admin/politician/${item.id}`}>
              {item.person.name} {item.person.surname}
            </ListItem>
          ))}
        </>
      ) : (
        <i>Pro nalezení politika použij vyhledávání...</i>
      )}
    </div>
  )
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'

import {
  InvisibleButSelectable,
  Key,
  KeyValueWrap,
  NonSelectable,
  ObjectContent,
  ObjectLiteral,
  PlusMinus,
} from './json-view-parts'
import { JSONPrimitive } from './json-view-primitive'
import { JSONViewOptions } from './json-view-types'

type KeyValueProps = {
  k: string
  v: any
  last: boolean
  depth: number
  options: JSONViewOptions
  inArray: boolean
}

function KeyValue({
  children = null,
  onKeyClick,
  prefix = null,
  ...props
}: KeyValueProps & {
  children?: React.ReactNode | null
  onKeyClick?: () => void
  prefix?: React.ReactNode
}) {
  return (
    <React.Fragment key={props.k}>
      {prefix}
      <KeyValueWrap>
        <InvisibleButSelectable>
          {' '.repeat(props.depth * 2)}
        </InvisibleButSelectable>
        {props.inArray ? null : (
          <>
            <Key
              css={onKeyClick ? { cursor: 'pointer' } : undefined}
              onClick={onKeyClick}
            >
              {JSON.stringify(props.k.replace(/_[0-9]+$/, ''))}
            </Key>
            :{' '}
          </>
        )}
        {children}
        {!props.last ? ',' : ''}
      </KeyValueWrap>
    </React.Fragment>
  )
}

function getExpandedDefault(
  props: Pick<KeyValueProps, 'depth' | 'options' | 'v'>,
) {
  return (
    props.depth < props.options.expandedDepth &&
    (!Array.isArray(props.v) || props.v.length < 10)
  )
}

function KeyValueObject(props: KeyValueProps) {
  const expandedDefault = getExpandedDefault(props)

  const [expanded, setExpanded] = React.useState<boolean | null>(null)
  const expandable = Array.isArray(props.v)
    ? props.v.length > 0
    : Object.keys(props.v).length > 0
  const toggle = () => setExpanded((v) => (v === null ? !expandedDefault : !v))
  return (
    <KeyValue
      {...props}
      prefix={
        props.options.folding && expandable ? (
          <PlusMinus
            expanded={expanded === null ? expandedDefault : expanded}
            onClick={toggle}
          />
        ) : null
      }
      onKeyClick={toggle}
    >
      <JSONObject
        data={props.v}
        options={props.options}
        depth={props.depth}
        expanded={expanded}
      />
    </KeyValue>
  )
}

// eslint-disable-next-line complexity
function JSONObject({
  data,
  options,
  depth,
  expanded = true,
}: {
  data: any
  options: JSONViewOptions
  depth: number
  expanded?: boolean | null
}) {
  const isArray = Array.isArray(data)
  const expandedDefault = getExpandedDefault({ depth, options, v: data })
  const indent = '  '.repeat(depth)
  const entries = isArray
    ? (data as any[]).map((v: any, i) => [i, v])
    : Object.entries(data)
  const foldedFallback = !isArray && typeof data.id === 'string' ? data.id : '…'
  if ((expanded === null && !expandedDefault) || entries.length <= 0) {
    return (
      <>
        <ObjectLiteral>{isArray ? '[' : '{'}</ObjectLiteral>
        {entries.length > 0 ? (
          <NonSelectable>{foldedFallback}</NonSelectable>
        ) : null}
        <ObjectLiteral>{isArray ? ']' : '}'}</ObjectLiteral>
      </>
    )
  }

  const isExpanded = expanded === null ? expandedDefault : expanded
  return (
    <>
      <ObjectLiteral>{isArray ? '[' : '{'}</ObjectLiteral>

      {isExpanded ? null : <NonSelectable>{foldedFallback}</NonSelectable>}
      <ObjectContent
        style={
          isExpanded ? {} : { fontSize: 0, opacity: 0, position: 'absolute' }
        }
      >
        {entries
          .filter(([, v]) => v !== undefined)
          .map(([k, v], i, list) => {
            const props = {
              k,
              v,
              last: list.length - 1 === i,
              depth: depth + 1,
              options,
              inArray: isArray,
            }
            // typeof null is object
            if (typeof v === 'object' && v) {
              return <KeyValueObject {...props} key={k} />
            }
            return (
              <KeyValue {...props} key={k}>
                <JSONViewInternal
                  data={props.v}
                  options={props.options}
                  depth={props.depth}
                />
              </KeyValue>
            )
          })}
      </ObjectContent>
      <InvisibleButSelectable>{indent}</InvisibleButSelectable>

      <ObjectLiteral>{isArray ? ']' : '}'}</ObjectLiteral>
    </>
  )
}

export function JSONViewInternal({
  data,
  options,
  depth,
}: {
  data: unknown
  options: JSONViewOptions
  depth: number
}) {
  if (
    typeof data === 'string' ||
    typeof data === 'number' ||
    typeof data === 'boolean' ||
    (typeof data === 'object' && !data) // typeof null is object
  ) {
    return <JSONPrimitive data={data} options={options} />
  }
  return <JSONObject data={data} options={options} depth={depth} />
}

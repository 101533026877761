import gql from 'graphql-tag'
import { createUseMutation } from 'admin/utils/create-use-mutation'
import {
  useAdminGetReflektorUpdateQuery,
  AdminReflektorUpdateListDocument,
  useAdminReflektorUpdateListQuery,
  AdminReflektorUpsertUpdateMutation,
  AdminReflektorUpsertUpdateMutationVariables,
  AdminPollDatesQuery,
  useAdminPollsAtDateQuery,
  useAdminPollByIdQuery,
} from 'queries/queries'
import { useQuery } from '@apollo/client'

const reflektorUpdateFragment = gql`
  fragment AdminReflektorUpdate on ReflektorUpdate {
    id
    title
    titleGoodPoliticians
    titleNotGoodPoliticians
    linkHref(editable: true)
    linkLabel
    leadImage {
      id
      url
    }
    publishDate
    type
    endDate
    politicians {
      list {
        id
        politician {
          id
          person {
            id
            name(editable: true)
          }
        }
        good
        isAboveFold
      }
    }
    summary(editable: true)
    text(editable: true)
    topic {
      id
    }
    poll {
      id
    }
    onlyCorrespondingEmoji
    archive
  }
`

gql`
  query adminGetReflektorUpdate($id: GlobalID!) {
    node(id: $id) {
      ...AdminReflektorUpdate
    }
  }
  ${reflektorUpdateFragment}
`

type PropertiesExcept<T, E> = {
  [k in keyof T]: k extends E ? never : k
}[keyof T]
type PickExcept<T, E> = Pick<T, PropertiesExcept<T, E>>

export const useAdminReflektorUpdate = (id: string) => {
  const ret = useAdminGetReflektorUpdateQuery({
    variables: { id },
    skip: id === 'new',
  })
  if (ret.loading) return 'loading' as const
  if (id === 'new')
    return {
      id,
      title: '',
      titleGoodPoliticians: '',
      titleNotGoodPoliticians: '',
      linkHref: '',
      linkLabel: '',
      leadImage: null,
      publishDate: null,
      type: null,
      endDate: null,
      politicians: { list: [], __typename: 'ReflektorUpdatePoliticianPage' },
      poll: null,
      summary: null,
      text: null,
      topic: null,
      onlyCorrespondingEmoji: false,
      archive: false,
      __typename: 'ReflektorUpdate',
    }
  const node = ret && ret.data && 'node' in ret.data ? ret.data.node : null
  return node?.__typename === 'ReflektorUpdate' ? node : null
}

const reflektorUpsertUpdateMutation = gql`
  mutation adminReflektorUpsertUpdate(
    $id: GlobalID
    $data: ReflektorUpdatePatchInput!
  ) {
    reflektorUpsertUpdate(id: $id, data: $data) {
      update {
        ...AdminReflektorUpdate
      }
    }
  }
  ${reflektorUpdateFragment}
`

export const useReflektorUpsertUpdate = createUseMutation<
  AdminReflektorUpsertUpdateMutation,
  AdminReflektorUpsertUpdateMutationVariables
>(reflektorUpsertUpdateMutation, {
  refetchQueries: (v) =>
    // refetch reflektor update list on insertion
    !v.id ? [{ query: AdminReflektorUpdateListDocument }] : [],
})

gql`
  query adminReflektorUpdateList {
    authorities {
      list {
        topics {
          list {
            reflektorUpdates(onlyPublished: false) {
              list {
                id
                title
                archive
                publishDate
              }
            }
          }
        }
      }
    }
  }
`

export const useAdminReflektorUpdateList = () => {
  const res = useAdminReflektorUpdateListQuery()
  return (
    res?.data?.authorities.list
      .map((a) => a.topics.list)
      .reduce((a, b) => a.concat(b), [])
      .map((topic) => topic.reflektorUpdates.list)
      .reduce((a, b) => a.concat(b), []) ?? null
  )
}

const adminPollsAtDateQuery = gql`
  query adminPollsAtDate($date: DateOnly!) {
    polls(filter: { date: $date }) {
      list {
        id
        description
        context
        importId
        url
        electionPeriod {
          id
          isSenate
        }
      }
    }
  }
`

export const useAdminPollsAtDate = (date: string | null) =>
  useAdminPollsAtDateQuery({
    variables: { date: date || '' },
    skip: !date,
  })

const adminPollByIdQuery = gql`
  query adminPollById($id: GlobalID!) {
    node(id: $id) {
      ... on Poll {
        id
        votes {
          list {
            id
            submandate {
              mandate {
                politician {
                  id
                  person {
                    id
                    name
                    surname
                    gender
                  }
                }
              }
            }
            vote
          }
        }
      }
    }
  }
`

export const useAdminPollById = (id: string | null) => {
  const ret = useAdminPollByIdQuery({
    variables: { id: id! },
    skip: !id,
  })
  if (!ret) return { fetching: false, data: null }
  if (!ret.data?.node) return { fetching: ret.loading, data: null }
  return {
    fetching: ret.loading,
    data:
      ret.data.node && ret.data.node.__typename === 'Poll'
        ? ret.data.node
        : null,
  }
}

const adminPollDatesQuery = gql`
  query adminPollDates {
    pollDates {
      date
      count
    }
  }
`

export const useAdminPollDates = () => {
  const ret = useQuery<AdminPollDatesQuery>(adminPollDatesQuery)
  if (!ret || !ret.data) return { fetching: false, data: null }
  if (!('pollDates' in ret.data)) return { fetching: ret.loading, data: null }
  return {
    fetching: ret.loading,
    data: ret.data.pollDates,
  }
}

import AdminSelect from './select'
import { useAdminTopicList } from 'admin/queries/topic'
import { notNull } from '@codewitchbella/ts-utils'
import { useMemo } from 'react'

type Props<ChangeType> = {
  topic: { value: string | null; change: (v: ChangeType) => void }
  filter?: (v: {
    id: string
    name: string
    availableInReflektor: boolean
    availableInEvaluation: boolean
  }) => boolean
}

function AdminSelectTopicInternal({
  topic,
  filter = () => true,
  allowAll = false,
}: Props<string> & {
  allowAll?: boolean
}) {
  const { list: allTopics } = useAdminTopicList()
  const topics = (allTopics || []).filter(filter)

  if (!allTopics || topics.length < 1) return <div>Nenalezena žádná témata</div>

  const extra =
    topic.value && !topics.some((t) => t.id === topic.value)
      ? allTopics.find((t) => t.id === topic.value) || null
      : null

  return (
    <AdminSelect
      label="Téma"
      state={topic}
      allowEmpty={allowAll}
      options={[
        { value: null, display: allowAll ? 'Všechna témata' : 'Vyberte téma' },
        extra
          ? { value: extra.id, display: `${extra.name} (nedostupné)` }
          : null,
        ...topics.map((t) => ({ value: t.id, display: t.name })),
      ].filter(notNull)}
    />
  )
}

export function AdminSelectTopic(props: Props<string>) {
  return <AdminSelectTopicInternal {...props} />
}

export function AdminSelectTopicAllowsAll({
  topic,
  ...rest
}: Props<string | null>) {
  const wrappedTopic = useMemo(
    () => ({
      value: topic.value,
      change: (v: string) => topic.change(v === '' ? null : v),
    }),
    [topic],
  )
  return (
    <AdminSelectTopicInternal {...rest} topic={wrappedTopic} allowAll={true} />
  )
}

import { useLocation, useHistory } from 'react-router-dom'
import { useCallback } from 'react'

export function useSearchParam(key: string, def: string) {
  const loc = useLocation()
  const history = useHistory()
  const value = new URLSearchParams(loc.search).get(key)
  const set = useCallback(
    (n: string | null) => {
      const s = new URLSearchParams(history.location.search)
      if (n) s.set(key, n)
      else s.delete(key)
      const ss = s + ''
      history.replace(history.location.pathname + (ss ? '?' : '') + ss)
    },
    [history, key],
  )
  return [value ?? def, set] as const
}

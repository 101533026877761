import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { createUseMutation } from 'admin/utils/create-use-mutation'
import * as q from 'queries/queries'

const pollEvaluationListQuery = gql`
  query adminPollEvaluationList($topic: GlobalID!) {
    pollEvaluations(filter: { topic: $topic }) {
      list {
        id
        committeePoll
        poll {
          id
          date
        }
        description
      }
    }
  }
`

export const useAdminPollEvaluationList = (topic: string | null) => {
  const ret = useQuery<
    q.AdminPollEvaluationListQuery,
    q.AdminPollEvaluationListQueryVariables
  >(pollEvaluationListQuery, {
    variables: { topic: topic! },
    skip: !topic,
  })
  if (!ret) return null
  const { data } = ret
  if (!data || !('pollEvaluations' in data)) return null
  return data.pollEvaluations.list
}

const pollEvaluationFragment = gql`
  fragment pollEvaluation on PollEvaluation {
    id
    poll {
      id
    }
    topic {
      id
    }
    weight
    description(editable: true)
    standpoint
    positive
    committeePoll
  }
`

const upsertPollEvaluationMutation = gql`
  mutation adminUpsertPollEvaluation(
    $id: GlobalID
    $data: PollEvaluationPatchInput!
  ) {
    upsertPollEvaluation(id: $id, data: $data) {
      pollEvaluation {
        ...pollEvaluation
      }
    }
  }
  ${pollEvaluationFragment}
`

export const useAdminUpsertPollEvaluationMutation = createUseMutation<
  q.AdminUpsertPollEvaluationMutation,
  q.AdminUpsertPollEvaluationMutationVariables
>(upsertPollEvaluationMutation, {
  refetchQueries: (v, extra) => {
    console.log('refetchQueries', v, extra)
    if (v.id) {
      // update
      if (!extra || !v.data.topic || v.data.topic === extra.topic) {
        // we are not changing topic
        return []
      }
      return [v.data.topic, extra.topic].map((t) => ({
        query: pollEvaluationListQuery,
        variables: { topic: t },
      }))
    }

    // insert
    return [
      {
        query: pollEvaluationListQuery,
        variables: { topic: v.data.topic },
      },
    ]
  },
})

gql`
  query adminPollEvaluation($id: GlobalID!) {
    node(id: $id) {
      ...pollEvaluation
    }
  }
  ${pollEvaluationFragment}
`

export const useAdminPollEvaluation = (id: string) => {
  const ret = q.useAdminPollEvaluationQuery({
    variables: { id },
    skip: id === 'new',
  })
  if (id === 'new') {
    return {
      __typename: 'PollEvaluation' as 'PollEvaluation',
      id,
      poll: null,
      topic: null,
      weight: 100,
      description: null,
      standpoint: '',
      positive: true,
      committeePoll: false,
    }
  }
  if (!ret) return null
  const { data } = ret
  if (
    !data ||
    !('node' in data) ||
    !data.node ||
    data.node.__typename !== 'PollEvaluation'
  )
    return null
  return data.node
}

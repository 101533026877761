/** @jsx jsx */
import { jsx } from '@emotion/react'
import Link from 'components/link'
import { useLocation } from 'react-router'
import { useAdminLogoutMutation } from 'queries/queries'
import { gql } from '@apollo/client'

const menu = [
  { text: 'Home', link: '/admin' },
  { text: 'Reflektor výzvy', link: '/admin/reflektor-update/list' },
  { text: 'Témata', link: '/admin/topic/list' },
  { text: 'Strany', link: '/admin/party/list' },
  { text: 'Politici', link: '/admin/politician/list' },
  { text: 'Vyhodnocení hlasování', link: '/admin/poll-eval/list' },
  { text: 'Vyhodnocení aktivity', link: '/admin/activity/list' },
  { text: 'Přepočítat hodnocení', link: '/admin/recalculate-evaluation' },
  { text: 'PDS Importér', link: '/admin/pds' },
  { text: 'Audit hodnocení', link: '/admin/audit' },
  { text: 'Uživatelé', link: '/admin/users' },
  { text: 'Test notifikací', link: '/admin/test-notification' },
  { text: 'API klíče', link: '/admin/api-token' },
  { text: 'Globální nastavení', link: '/admin/global-settings' },
  { text: 'Průzkumník databáze', link: '/admin/db-explorer' },
]

const menuLinkStyle = {
  display: 'block',
  padding: '5px 10px',
  ':hover': {
    textDecoration: 'underline',
  },
} as const

gql`
  mutation adminLogout {
    logout
  }
`

export default function MenuItems() {
  const location = useLocation()
  const [logout] = useAdminLogoutMutation({ ignoreResults: true })
  return (
    <>
      {menu.map((entry, i) => (
        <Link
          css={[
            menuLinkStyle,
            location.pathname === entry.link
              ? { textDecoration: 'underline' }
              : null,
          ]}
          key={i}
          to={entry.link}
        >
          {entry.text}
        </Link>
      ))}
      <a
        href="#"
        css={menuLinkStyle}
        onClick={(evt) => {
          evt.preventDefault()
          logout({ refetchQueries: ['adminMe'] })
        }}
      >
        Odhlásit se
      </a>
    </>
  )
}

import gql from 'graphql-tag'
import { mutation } from 'containers/create-graphql-component'
import type { MutationFunction } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { createUseMutation } from 'admin/utils/create-use-mutation'
import type * as q from 'queries/queries'

const adminMeQuery = gql`
  query adminMe {
    me {
      id
      roles
    }
  }
`

export const useAdminMe = () => {
  const v = useQuery<q.AdminMeQuery>(adminMeQuery)
  if (!v) return null
  if ('loading' in v && v.loading) return 'loading' as 'loading'
  if (!v.data) return null
  return v.data.me
}

export const AdminLoginMutation = mutation<
  q.AdminLoginMutation,
  q.AdminLoginMutationVariables
>(gql`
  mutation adminLogin($input: LoginInput!) {
    login(input: $input) {
      user {
        id
      }
    }
  }
`)
export type AdminLoginMutationFn = MutationFunction<
  q.AdminLoginMutation,
  q.AdminLoginMutationVariables
>
const userList = gql`
  query adminUserList {
    userList: users {
      count
      users: list {
        id
        roles
        emails(onlyPrimary: true) {
          value
          verified
        }
      }
    }
  }
`

const adminUserDeleteMutation = gql`
  mutation adminUserDelete($id: GlobalID!) {
    userDelete(id: $id)
  }
`

export const useAdminUserDeleteMutation = createUseMutation<
  q.AdminUserDeleteMutation,
  q.AdminUserDeleteMutationVariables
>(adminUserDeleteMutation, { refetchQueries: [{ query: userList }] })

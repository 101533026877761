/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import LoaderSpinner from 'components/loader-spinner'
import { useQuery } from '@apollo/client/react/hooks'
import * as q from 'queries/queries'
import { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Fab } from '@material-ui/core'
import { AdminSelectTopic } from 'admin/components/admin-select-topic'

const ListItem = styled(Link)({
  display: 'block',
  padding: 5,
})

gql`
  query AdminActivityGroupsByTopic($topic: GlobalID!) {
    activityGroupsByTopic(topic: $topic) {
      list {
        id
        isPositive
        activities {
          list {
            weight
            id
            description
            hidden
          }
        }
      }
    }
  }
`

export default function AdminListActivity() {
  const [topic, setTopic] = useState(null as null | string)
  const query = useQuery<
    q.AdminActivityGroupsByTopicQuery,
    q.AdminActivityGroupsByTopicQueryVariables
  >(q.AdminActivityGroupsByTopicDocument, {
    variables: { topic: topic! },
    skip: !topic,
  })
  const list = query.data?.activityGroupsByTopic?.list || null
  return (
    <div>
      <ListItem to="/admin/activity/new">
        <Fab>
          <AddIcon />
        </Fab>
        <br />
        Přidat
      </ListItem>
      <ListItem to="/admin/activity/import">Importovat z tabulky</ListItem>
      <h2>Seznam</h2>
      <AdminSelectTopic
        topic={{ value: topic, change: setTopic }}
        filter={(t) => t.availableInEvaluation}
      />
      {!topic ? null : !list ? (
        <LoaderSpinner />
      ) : (
        <>
          {list.map((item) => (
            <ListItem key={item.id} to={`/admin/activity/${item.id}`}>
              <div css={{ display: 'flex' }}>
                <div css={{ marginRight: 5 }}>
                  {item.isPositive ? 'Pozitivní' : 'Negativní'}
                </div>
                <div>
                  {item.activities.list.map((ac) => (
                    <div key={ac.id}>
                      {ac.weight}%: {ac.hidden ? '(skrytá) ' : ''}
                      {ac.description}
                    </div>
                  ))}
                </div>
              </div>
            </ListItem>
          ))}
          {list.length < 1
            ? 'V tomto tématu nebyla vyhodnocena žádná aktivita'
            : null}
        </>
      )}
    </div>
  )
}

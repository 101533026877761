/** @jsx jsx */
import { jsx } from '@emotion/react'
import { View, Text } from 'react-native'
import gql from 'graphql-tag'
import { SingleTwemoji } from 'components/twemoji'
import LoaderSpinner from 'components/loader-spinner'
import { DateTime } from 'luxon'
import React, { useMemo, useReducer, useContext, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import * as q from 'queries/queries'
import { notNull } from '@codewitchbella/ts-utils'
import { AdminSelectTopicAllowsAll } from 'admin/components/admin-select-topic'
import { useSearchParam } from 'admin/utils/use-search-param'

// Adapted from https://gist.github.com/blixt/f17b47c62508be59987b
class Random {
  private seed: number

  constructor(seed: number) {
    this.seed = seed % 2147483647
    if (this.seed <= 0) this.seed += 2147483646
  }

  next() {
    this.seed = (this.seed * 16807) % 2147483647
    return this.seed
  }
}
const random = new Random(11)

const emojis = `
😀 😁 😂 🤣 😃 😄 😅 😆 😉 😊 😋 😎 😍 😘 🥰 😗 😙 😚 ☺️ 🙂 🤗 🤩 🤔 🤨 😐 😑 😶 🙄 😏 😣 😥 😮 🤐 😯 😪 😫 😴 😌 😛 😜 😝 🤤 😒 😓 😔 😕 🙃 🤑 😲 ☹️ 🙁 😖 😞 😟 😤 😢 😭 😦 😧 😨 😩 🤯 😬 😰 😱 🥵 🥶 😳 🤪 😵 😡 😠 🤬 😷 🤒 🤕 🤢 🤮 🤧 😇 🤠 🤡 🥳 🥴 🥺 🤥 🤫 🤭 🧐 🤓 😈 👿 👹 👺 💀 👻 👽 🤖 💩 😺 😸 😹 😻 😼 😽 🙀 😿 😾
👶 👧 🧒 👦 👩 🧑 👨 👵 🧓 👴 👲 🧕 🧔 👨‍🦰 👩‍🦰 👨‍🦱 👩‍🦱 👨‍🦲 👩‍🦲 👨‍🦳 👩‍🦳 👩‍⚕️ 👨‍⚕️ 👩‍🌾 👨‍🌾 👩‍🍳 👨‍🍳 👩‍🎓 👨‍🎓 👩‍🎤 👨‍🎤 👩‍🏫 👨‍🏫 👩‍🏭 👨‍🏭 👩‍💻 👨‍💻 👩‍💼 👨‍💼 👩‍🔧 👨‍🔧 👩‍🔬 👨‍🔬 👩‍🎨 👨‍🎨 👩‍🚒 👨‍🚒 👩‍✈️ 👨‍✈️ 👩‍🚀 👨‍🚀 👩‍⚖️ 👨‍⚖️ 👰 🤵 👸 🤴 🤶 🎅 👼 🤰 🤱 💅 🤳 💃 🕺 🕴 👫 👭 👬 💑 👩‍❤️‍👩 👨‍❤️‍👨 💏 👩‍❤️‍💋‍👩 👨‍❤️‍💋‍👨 👪 🤲 👐 🙌 👏 🤝 👍 👎 👊 ✊ 🤛 🤜 🤞 ✌️ 🤟 🤘 👌 👈 👉 👆 👇 ☝️ ✋ 🤚 🖐 🖖 👋 🤙 💪 🦵 🦶 🖕 ✍️ 🙏 💍 💄 💋 👄 👅 👂 👃 👣 👁 👀 🧠 🦴 🦷 🗣 👤 👥
🧥 👚 👕 👖 👔 👗 👙 👘 👠 👡 👢 👞 👟 🥾 🥿 🧦 🧤 🧣 🎩 🧢 👒 🎓 ⛑ 👑 👝 👛 👜 💼 🎒 👓 🕶 🥽 🥼 🌂 🧵 🧶
🐶 🐱 🐭 🐹 🐰 🦊 🦝 🐻 🐼 🦘 🦡 🐨 🐯 🦁 🐮 🐷 🐽 🐸 🐵 🙈 🙉 🙊 🐒 🐔 🐧 🐦 🐤 🐣 🐥 🦆 🦢 🦅 🦉 🦚 🦜 🦇 🐺 🐗 🐴 🦄 🐝 🐛 🦋 🐌 🐚 🐞 🐜 🦗 🕷 🕸 🦂 🦟 🦠 🐢 🐍 🦎 🦖 🦕 🐙 🦑 🦐 🦀 🐡 🐠 🐟 🐬 🐳 🐋 🦈 🐊 🐅 🐆 🦓 🦍 🐘 🦏 🦛 🐪 🐫 🦙 🦒 🐃 🐂 🐄 🐎 🐖 🐏 🐑 🐐 🦌 🐕 🐩 🐈 🐓 🦃 🕊 🐇 🐁 🐀 🐿 🦔 🐾 🐉 🐲 🌵 🎄 🌲 🌳 🌴 🌱 🌿 ☘️ 🍀 🎍 🎋 🍃 🍂 🍁 🍄 🌾 💐 🌷 🌹 🥀 🌺 🌸 🌼 🌻 🌞 🌝 🌛 🌜 🌚 🌕 🌖 🌗 🌘 🌑 🌒 🌓 🌔 🌙 🌎 🌍 🌏 💫 ⭐️ 🌟 ✨ ⚡️ ☄️ 💥 🔥 🌪 🌈 ☀️ 🌤 ⛅️ 🌥 ☁️ 🌦 🌧 ⛈ 🌩 🌨 ❄️ ☃️ ⛄️ 🌬 💨 💧 💦 ☔️ ☂️ 🌊 🌫
🍏 🍎 🍐 🍊 🍋 🍌 🍉 🍇 🍓 🍈 🍒 🍑 🍍 🥭 🥥 🥝 🍅 🍆 🥑 🥦 🥒 🥬 🌶 🌽 🥕 🥔 🍠 🥐 🍞 🥖 🥨 🥯 🧀 🥚 🍳 🥞 🥓 🥩 🍗 🍖 🌭 🍔 🍟 🍕 🥪 🥙 🌮 🌯 🥗 🥘 🥫 🍝 🍜 🍲 🍛 🍣 🍱 🥟 🍤 🍙 🍚 🍘 🍥 🥮 🥠 🍢 🍡 🍧 🍨 🍦 🥧 🍰 🎂 🍮 🍭 🍬 🍫 🍿 🧂 🍩 🍪 🌰 🥜 🍯 🥛 🍼 ☕️ 🍵 🥤 🍶 🍺 🍻 🥂 🍷 🥃 🍸 🍹 🍾 🥄 🍴 🍽 🥣 🥡 🥢
⚽️ 🏀 🏈 ⚾️ 🥎 🏐 🏉 🎾 🥏 🎱 🏓 🏸 🥅 🏒 🏑 🥍 🏏 ⛳️ 🏹 🎣 🥊 🥋 🎽 ⛸ 🥌 🛷 🛹 🎿 ⛷ 🏂 🏇 🏇🏻 🏇🏼 🏇🏽 🏇🏾 🏇🏿 🏆 🥇 🥈 🥉 🏅 🎖 🏵 🎗 🎫 🎟 🎪 🎭 🎨 🎬 🎤 🎧 🎼 🎹 🥁 🎷 🎺 🎸 🎻 🎲 🧩 🎯 🎳 🎮 🎰
🚗 🚕 🚙 🚌 🚎 🏎 🚓 🚑 🚒 🚐 🚚 🚛 🚜 🛴 🚲 🛵 🏍 🚨 🚔 🚍 🚘 🚖 🚡 🚠 🚟 🚃 🚋 🚞 🚝 🚄 🚅 🚈 🚂 🚆 🚇 🚊 🚉 ✈️ 🛫 🛬 🛩 💺 🛰 🚀 🛸 🚁 🛶 ⛵️ 🚤 🛥 🛳 ⛴ 🚢 ⚓️ ⛽️ 🚧 🚦 🚥 🚏 🗺 🗿 🗽 🗼 🏰 🏯 🏟 🎡 🎢 🎠 ⛲️ ⛱ 🏖 🏝 🏜 🌋 ⛰ 🏔 🗻 🏕 ⛺️ 🏠 🏡 🏘 🏚 🏗 🏭 🏢 🏬 🏣 🏤 🏥 🏦 🏨 🏪 🏫 🏩 💒 🏛 ⛪️ 🕌 🕍 🕋 ⛩ 🛤 🛣 🗾 🎑 🏞 🌅 🌄 🌠 🎇 🎆 🌇 🌆 🏙 🌃 🌌 🌉 🌁
⌚️ 📱 📲 💻 ⌨️ 🖥 🖨 🖱 🖲 🕹 🗜 💽 💾 💿 📀 📼 📷 📸 📹 🎥 📽 🎞 📞 ☎️ 📟 📠 📺 📻 🎙 🎚 🎛 ⏱ ⏲ ⏰ 🕰 ⌛️ ⏳ 📡 🔋 🔌 💡 🔦 🕯 🗑 🛢 💸 💵 💴 💶 💷 💰 💳 🧾 💎 ⚖️ 🔧 🔨 ⚒ 🛠 ⛏ 🔩 ⚙️ ⛓ 🔫 💣 🔪 🗡 ⚔️ 🛡 🚬 ⚰️ ⚱️ 🏺 🧭 🧱 🔮 🧿 🧸 📿 💈 ⚗️ 🔭 🧰 🧲 🧪 🧫 🧬 🧯 🔬 🕳 💊 💉 🌡 🚽 🚰 🚿 🛁 🛀 🛀🏻 🛀🏼 🛀🏽 🛀🏾 🛀🏿 🧴 🧷 🧹 🧺 🧻 🧼 🧽 🛎 🔑 🗝 🚪 🛋 🛏 🛌 🖼 🛍 🧳 🛒 🎁 🎈 🎏 🎀 🎊 🎉 🧨 🎎 🏮 🎐 🧧 ✉️ 📩 📨 📧 💌 📥 📤 📦 🏷 📪 📫 📬 📭 📮 📯 📜 📃 📄 📑 📊 📈 📉 🗒 🗓 📆 📅 📇 🗃 🗳 🗄 📋 📁 📂 🗂 🗞 📰 📓 📔 📒 📕 📗 📘 📙 📚 📖 🔖 🔗 📎 🖇 📐 📏 📌 📍 ✂️ 🖊 🖋 ✒️ 🖌 🖍 📝 ✏️ 🔍 🔎 🔏 🔐 🔒 🔓
❤️ 🧡 💛 💚 💙 💜 🖤 💔 ❣️ 💕 💞 💓 💗 💖 💘 💝 💟 ☮️ ✝️ ☪️ 🕉 ☸️ ✡️ 🔯 🕎 ☯️ ☦️ 🛐 ⛎ ♈️ ♉️ ♊️ ♋️ ♌️ ♍️ ♎️ ♏️ ♐️ ♑️ ♒️ ♓️ 🆔 ⚛️ 🉑 ☢️ ☣️ 📴 📳 🈶 🈚️ 🈸 🈺 🈷️ ✴️ 🆚 💮 🉐 ㊙️ ㊗️ 🈴 🈵 🈹 🈲 🅰️ 🅱️ 🆎 🆑 🅾️ 🆘 ❌ ⭕️ 🛑 ⛔️ 📛 🚫 💯 💢 ♨️ 🚷 🚯 🚳 🚱 🔞 📵 🚭 ❗️ ❕ ❓ ❔ ‼️ ⁉️ 🔅 🔆 〽️ ⚠️ 🚸 🔱 ⚜️ 🔰 ♻️ ✅ 🈯️ 💹 ❇️ ✳️ ❎ 🌐 💠 Ⓜ️ 🌀 💤 🏧 🚾 ♿️ 🅿️ 🈳 🈂️ 🛂 🛃 🛄 🛅 🚹 🚺 🚼 🚻 🚮 🎦 📶 🈁 🔣 ℹ️ 🔤 🔡 🔠 🆖 🆗 🆙 🆒 🆕 🆓 0️⃣ 1️⃣ 2️⃣ 3️⃣ 4️⃣ 5️⃣ 6️⃣ 7️⃣ 8️⃣ 9️⃣ 🔟 🔢 #️⃣ *️⃣ ⏏️ ▶️ ⏸ ⏯ ⏹ ⏺ ⏭ ⏮ ⏩ ⏪ ⏫ ⏬ ◀️ 🔼 🔽 ➡️ ⬅️ ⬆️ ⬇️ ↗️ ↘️ ↙️ ↖️ ↕️ ↔️ ↪️ ↩️ ⤴️ ⤵️ 🔀 🔁 🔂 🔄 🔃 🎵 🎶 ➕ ➖ ➗ ✖️ ♾ 💲 💱 ™️ ©️ ®️ 〰️ ➰ ➿ 🔚 🔙 🔛 🔝 🔜 ✔️ ☑️ 🔘 ⚪️ ⚫️ 🔴 🔵 🔺 🔻 🔸 🔹 🔶 🔷 🔳 🔲 ▪️ ▫️ ◾️ ◽️ ◼️ ◻️ ⬛️ ⬜️ 🔈 🔇 🔉 🔊 🔔 🔕 📣 📢 💬 💭 🗯 ♠️ ♣️ ♥️ ♦️ 🃏 🎴 🀄️ 🕐 🕑 🕒 🕓 🕔 🕕 🕖 🕗 🕘 🕙 🕚 🕛 🕜 🕝 🕞 🕟 🕠 🕡 🕢 🕣 🕤 🕥 🕦 🕧
🏳️ 🏴 🏁 🚩 🏳️‍🌈
`
  .trim()
  .replace(/[\t\n]/g, ' ')
  .split(' ')
  .map((emoji) => ({ emoji, sortable: random.next() }))
  .sort((a, b) => a.sortable - b.sortable)
  .map((v) => v.emoji)

const columns: {
  [key: string]: {
    name: string
    visibleByDefault: boolean
    noSort?: boolean
    onlyFullEval?: boolean
    noHide?: boolean
    wrap?: (value: string, pol: any) => JSX.Element
  }
} = {
  lineno: { name: 'Řádek', visibleByDefault: true, noSort: true },
  displayName: {
    name: 'Jméno a příjmení',
    visibleByDefault: true,
    noHide: true,
    wrap: (value, { slug }) => (
      <a
        href={`https://embed.rekonstrukcestatu.cz/seznam-politiku/${slug}?extra`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    ),
  },
  supportCategory: {
    name: 'Kategorizace',
    visibleByDefault: true,
    noSort: true,
    onlyFullEval: true,
  },
  activityPositive: { name: 'Aktivita pozitivní', visibleByDefault: true },
  activityNegative: { name: 'Aktivita negativní', visibleByDefault: true },
  votePositive: { name: 'Hlasování pozitivní', visibleByDefault: true },
  voteNegative: { name: 'Hlasování negativní', visibleByDefault: true },
  voteNeutral: { name: 'Hlasování neutrální', visibleByDefault: true },
  overallPositive: { name: 'Celkově pozitivní', visibleByDefault: false },
  overallNegative: { name: 'Celkově negativní', visibleByDefault: false },
  overallNeutral: { name: 'Celkově neutrální', visibleByDefault: false },
  rating: { name: 'Pořadí', visibleByDefault: true, onlyFullEval: true },
  sortable: {
    name: 'Řaditelné skóre',
    visibleByDefault: false,
    onlyFullEval: true,
  },
}

function isAllEPs(filterEP: string | null) {
  return [null, 'lower', 'senate'].includes(filterEP)
}

function isFullEval(
  filterEP: string | null,
  {
    sMop,
    sSenate,
    epInfo,
  }: {
    sMop: string
    sSenate: string
    epInfo: {
      id: string
      start: DateTime
      end: DateTime | null
      isSenate: boolean
      emoji: JSX.Element
    } | null
  },
) {
  if (filterEP === null && sMop === 'all' && sSenate === 'all') return true
  if (filterEP === 'lower' && sMop === 'all') return true
  if (filterEP === 'senate' && sSenate === 'all') return true

  if (!epInfo) return false
  const s = epInfo.isSenate ? sSenate : sMop
  return s !== 'all' && epInfo.end === null
}

gql`
  query AdminAudit {
    authorities {
      list {
        topics {
          list {
            id
            name
          }
        }
      }
    }
    politicians(onlyWithEvaluations: true, limit: -1) {
      list {
        id
        slug
        person {
          id
          name
          surname
        }
        evaluation {
          id
          rating
          supportCategory
          sortable
        }
        filteredEvaluations {
          list {
            id
            votePositive
            voteNegative
            voteNeutral
            activityPositive
            activityNegative
            overallPositive
            overallNegative
            overallNeutral
            filterElectionPeriod {
              id
              start
              end
              isSenate
            }
            filterTopic {
              id
            }
          }
        }
      }
    }
  }
`

export default function AdminAudit() {
  const settings = q.useAdminGlobalSettingsQuery()
  const politicians = q.useAdminAuditQuery()
  const list = politicians?.data?.politicians?.list
  const electionPeriods = useMemo(() => {
    const map = new Map<
      string,
      {
        id: string
        start: DateTime
        end: DateTime | null
        isSenate: boolean
        emoji: JSX.Element
      }
    >()
    if (!list) return map
    for (const politician of list) {
      for (const ev of politician.filteredEvaluations.list) {
        const ep = ev.filterElectionPeriod
        if (ep) {
          const start = DateTime.fromISO(ep.start)
          const end = ep.end ? DateTime.fromISO(ep.end) : null
          map.set(ep.id, {
            ...ep,
            start,
            end,
            emoji: (
              <Tooltip
                title={`${ep.isSenate ? 'Senát' : 'Poslanecká sn.'}: ${start
                  .setLocale('cs')
                  .toFormat('d. LLLL y')} - ${
                  end ? end.setLocale('cs').toFormat('d. LLLL y') : 'současnost'
                }`}
              >
                <span>
                  <SingleTwemoji
                    emoji={
                      emojis[Number.parseInt(ep.id.replace(/[^0-9]/g, ''), 10)]
                    }
                  />
                </span>
              </Tooltip>
            ),
          })
        }
      }
    }
    return map
  }, [list])

  const [sortStack, changeSortStack] = useReducer(
    (
      state: { column: string; direction: 'asc' | 'desc' }[],
      pushed: { column: string; direction: 'asc' | 'desc' },
    ) => {
      const next = state.filter((rule) => rule.column !== pushed.column)
      next.unshift(pushed)
      return next
    },
    [{ column: 'sortName', direction: 'asc' }],
  )

  const [visibleColumnsState, changeVisibleColumns] = useReducer(
    (state: { [key in string]: boolean }, column: string) => ({
      ...state,
      [column]: !state[column],
    }),
    {},
    () => {
      const ret: any = {}
      for (const [col, val] of Object.entries(columns))
        ret[col] = val.visibleByDefault
      return ret
    },
  )

  const [filterEp, setFilterEp] = useSearchParam('ep', 'invalid')
  const [topic, setTopic] = useState(null as string | null)

  const epInfo = filterEp ? electionPeriods.get(filterEp) ?? null : null
  const isEpFullEval = useMemo(() => {
    if (settings.loading) return null
    const s = settings.data?.globalSettings
    if (!s) return null
    const sMop = s.find((ss) => ss.name === 'ep_filter_rating_mop')?.value
    const sSenate = s.find((ss) => ss.name === 'ep_filter_rating_senate')?.value
    if (!sMop || !sSenate) return null

    return isFullEval(filterEp, { sMop, sSenate, epInfo })
  }, [epInfo, filterEp, settings])

  const visibleColumns = useMemo(() => {
    if (isEpFullEval === null) return null

    const copy = { ...visibleColumnsState }
    if (!isEpFullEval) {
      for (const [key, column] of Object.entries(columns)) {
        if (column.onlyFullEval) copy[key] = false
      }
    }
    return copy
  }, [visibleColumnsState, isEpFullEval])

  const [onlyWithRating, setOnlyWithRating] = useState(true)

  if (settings.loading) return <LoaderSpinner />
  if (!visibleColumns) return <div>Něco se pokazilo</div>

  if (politicians.loading) return <LoaderSpinner />
  if (!list) return null

  const columnCount = Object.values(visibleColumns).reduce(
    (a, b) => a + (b ? 1 : 0),
    0,
  )
  return (
    <sortContext.Provider value={changeSortStack}>
      <View>
        <Text style={{ fontSize: 30, marginBottom: 20 }}>Audit hodnocení</Text>
        <View>
          <AdminSelectTopicAllowsAll
            topic={{ value: topic, change: setTopic }}
            filter={(t) => t.availableInEvaluation}
          />
          <Text style={{ fontSize: 20 }}>Volební období</Text>
          <div
            css={{
              display: 'flex',
              button: {
                all: 'unset',
                fontSize: 75,
                width: 90,
                height: 90,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&.active': { border: '1px solid black' },
                span: {
                  height: 75,
                  width: 75,
                },
              },
            }}
          >
            <Tooltip title="Všechna volební období">
              <button
                className={filterEp === null ? 'active' : ''}
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setFilterEp(null)
                }}
              >
                <SingleTwemoji emoji="☯️" />
              </button>
            </Tooltip>
            <Tooltip title="Senát">
              <button
                className={filterEp === 'senate' ? 'active' : ''}
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setFilterEp('senate')
                }}
              >
                <SingleTwemoji emoji="🏛️" />
              </button>
            </Tooltip>
            <Tooltip title="Poslanecká sněmovna">
              <button
                className={filterEp === 'lower' ? 'active' : ''}
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setFilterEp('lower')
                }}
              >
                <SingleTwemoji emoji="📋" />
              </button>
            </Tooltip>
            {Array.from(electionPeriods.values())
              .sort((a, b) =>
                a.end === b.end
                  ? a.start < b.start
                    ? 1
                    : -1
                  : a.end === null
                  ? -1
                  : b.end === null
                  ? 1
                  : a.end < b.end
                  ? 1
                  : -1,
              )
              .map((ep) => (
                <button
                  className={filterEp === ep.id ? 'active' : ''}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    setFilterEp(ep.id)
                  }}
                  key={ep.id}
                >
                  {ep.emoji}
                </button>
              ))}
          </div>
          <View>
            <Text style={{ fontSize: 20 }}>Další filtry</Text>

            <label>
              <input
                type="checkbox"
                checked={onlyWithRating}
                onChange={() => setOnlyWithRating((v) => !v)}
              />
              Pouze s přiřazeným pořadím
            </label>
          </View>
          <View>
            <Text style={{ fontSize: 20 }}>Viditelné sloupce</Text>
            {Object.entries(columns)
              .filter(
                ([key, column]) =>
                  (!column.onlyFullEval || isEpFullEval) && !column.noHide,
              )
              .map(([key, column]) => (
                <label key={key}>
                  <input
                    type="checkbox"
                    checked={visibleColumns[key]}
                    onChange={() => changeVisibleColumns(key)}
                  />
                  {column.name}
                </label>
              ))}
          </View>
          <Text style={{ fontSize: 20 }}>Data</Text>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: `repeat(${columnCount}, auto)`,
            }}
          >
            {Object.entries(columns)
              .filter((column) => visibleColumns[column[0]])
              .map((column) =>
                column[1].noSort ? (
                  <div key={column[0]}>{column[1].name}</div>
                ) : (
                  <Header
                    name={column[0]}
                    title={column[1].name}
                    key={column[0]}
                    visible={
                      sortStack[0]?.column === column[0]
                        ? sortStack[0].direction
                        : null
                    }
                  />
                ),
              )}
            {list
              .map((pol) => {
                if (onlyWithRating && !pol.evaluation?.rating) return null
                if (filterEp === 'senate' || filterEp === 'lower') {
                  const hasMatchingFilteredEval =
                    pol.filteredEvaluations.list.some(
                      (ev) =>
                        ev.filterElectionPeriod?.isSenate ===
                        (filterEp === 'senate'),
                    )
                  if (!hasMatchingFilteredEval) return null
                }
                if (!pol.evaluation) return null
                const ev = pol.filteredEvaluations.list.find((e) => {
                  const epMatch =
                    (isAllEPs(filterEp) && e.filterElectionPeriod === null) ||
                    filterEp === e.filterElectionPeriod?.id
                  const topicMatch =
                    (topic === null && e.filterTopic === null) ||
                    (e.filterTopic && topic === e.filterTopic.id)

                  return epMatch && topicMatch
                })
                if (!ev) return null
                return {
                  ...ev,
                  id: pol.id,
                  supportCategory: pol.evaluation.supportCategory,
                  sortName: `${pol.person.surname} ${pol.person.name}`,
                  displayName: `${pol.person.name} ${pol.person.surname}`,
                  rating: pol.evaluation?.rating,
                  sortable: pol.evaluation?.sortable,
                  slug: pol.slug,
                }
              })
              .filter(notNull)
              .sort((a: any, b: any) => {
                for (const sortRule of sortStack) {
                  if (a[sortRule.column] > b[sortRule.column])
                    return sortRule.direction === 'asc' ? 1 : -1
                  if (a[sortRule.column] < b[sortRule.column])
                    return sortRule.direction === 'asc' ? -1 : 1
                }
                return 0
              })
              .map((pol, i) => (
                <React.Fragment key={pol.id}>
                  {Object.keys(columns)
                    .filter((column) => visibleColumns[column])
                    .map((column) => {
                      const wrap = columns[column].wrap
                      const value =
                        column === 'lineno' ? `${i + 1}` : (pol as any)[column]
                      return (
                        <div key={column}>
                          {wrap ? wrap(value, pol) : value}
                        </div>
                      )
                    })}
                </React.Fragment>
              ))}
          </div>
        </View>
      </View>
    </sortContext.Provider>
  )
}

function Header({
  title,
  name,
  visible,
}: {
  title: string
  name: string
  visible: 'asc' | 'desc' | null
}) {
  const dispatch = useContext(sortContext)
  return (
    <div
      css={{
        flexDirection: 'column',
        display: 'flex',
        '.buttons': {
          opacity: 0,
        },
        ':hover .buttons': {
          opacity: 1,
        },
      }}
    >
      <div>{title}</div>
      <div css={{ alignSelf: 'center' }}>
        <button
          type="button"
          onClick={() => dispatch({ column: name, direction: 'desc' })}
          className="buttons"
          style={visible === 'desc' ? { opacity: 1 } : undefined}
        >
          <SingleTwemoji emoji="🔽" />
        </button>{' '}
        <button
          type="button"
          onClick={() => dispatch({ column: name, direction: 'asc' })}
          className="buttons"
          style={visible === 'asc' ? { opacity: 1 } : undefined}
        >
          <SingleTwemoji emoji="🔼" />
        </button>
      </div>
    </div>
  )
}

const sortContext = React.createContext((() => {}) as (v: {
  column: string
  direction: 'asc' | 'desc'
}) => void)

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { CardBody } from 'admin/components/card'
import { PropsWithChildren } from 'react'

function Link({ href, children }: PropsWithChildren<{ href: string }>) {
  return (
    <a
      css={{ textDecoration: 'underline' }}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export function CommitteeSheetDescription() {
  return (
    <CardBody>
      <p>
        Tato struktura vychází z tabulky{' '}
        <Link href="https://docs.google.com/spreadsheets/d/1gi7L60rWj4cMrqDAOcLnsktRO8vbKrmL8EnfDSWMkLg/edit#gid=377627726">
          Hodnocení poslanců - datamined, list vyborova_hlasovani
        </Link>
        . Odpovídající{' '}
        <Link href="https://docs.google.com/spreadsheets/d/1YUArb9CG1Yi5m-in0rFa0HRnsmglcmC9xiNeHe9KVKw/edit#gid=129013582">
          list v tabulce senátorů
        </Link>{' '}
        je podobný, ale má přeskládané pořadí řádků (a má o jednu hlavičku
        méně). Takže tam bude potřeba před importem udělat drobné úpravy.
      </p>
      <p>Očekávaná struktura tohoto listu tedy vypadá následovně:</p>
      <ul>
        <li>Obsah A1:D5 ignoruji</li>
        <li>
          Prvních pět řádek jsou nadpisy
          <ul>
            <li>První řádek: téma</li>
            <li>Druhý řádek: váha hodnocení</li>
            <li>Třetí řádek: Pozitivita (-1, 0, 1)</li>
            <li>
              {
                'Čtvrtý řádek: datum /// popis pro lidi /// (schváleno, zamítnuto) /// stanovisko RS ve formátu (Pozitivní, Negativní, Neutrální) - stanovisko'
              }
            </li>
            <li>Pátý řádek: cokoli - ignoruji</li>
          </ul>
        </li>
        <li>
          První 4 sloupce popisují politika
          <ul>
            <li>První sloupec: cokoli - ignoruji</li>
            <li>Druhý sloupec: jméno</li>
            <li>Třetí sloupec: příjmení</li>
            <li>Čtvrtý a pátý sloupec: cokoli - ignoruji</li>
          </ul>
        </li>
        <li>
          Buňky v průsečíku obsahují jednu z následujících hodnot
          <ul>
            <li>
              Nic - nemohl být účasten hlasování (nebyl členem daného výboru)
            </li>
            <li>ANO - hlasoval pro návrh</li>
            <li>NE - hlasoval proti návrhu</li>
            <li>
              zdržel se - byl přítomen ale zdržel se (alternativní texty:
              zdržela se)
            </li>
            <li>
              @ - nebyl přítomen (alternativní texty: nepřítomen, nepřítomna)
            </li>
            <li>
              omluven - měl mandát, ale byl omluven z hlasování (alternativní
              texty: omluvena)
            </li>
          </ul>
        </li>
      </ul>
    </CardBody>
  )
}

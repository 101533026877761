import styled from '@emotion/styled'
import { textElementStyle, adminInputWrapperStyle } from './input-builder'

const Label = styled('label')<{
  textOn: string
  textOff: string
  width: number
}>`
  ${adminInputWrapperStyle};
  input {
    all: unset;
    display: inline-block;
    perspective: 100px;

    ::before,
    ::after {
      width: ${(props) => props.width}px;
      display: inline-block;
      color: black;

      ${textElementStyle};

      transition: transform 500ms ease;
      backface-visibility: hidden;
      text-align: center;
    }
    :focus {
      ::before,
      ::after {
        border-color: black;
      }
    }
    ::before {
      position: absolute;
      content: '${(props) => props.textOn}';
      transform: rotateY(180deg);
    }
    ::after {
      content: '${(props) => props.textOff}';
      transform: rotateY(0deg);
    }
    :checked {
      ::before {
        transform: rotateY(0deg);
      }
      ::after {
        transform: rotateY(-180deg);
      }
    }
  }
`

export const AdminCheckbox = ({
  state,
  textOn = 'Ano',
  textOff = 'Ne',
  width = 60,
  label,
}: {
  state: {
    change: (value: boolean) => void
    value: boolean
  }
  textOn?: string
  textOff?: string
  width?: number
  label: string
}) => (
  <Label textOn={textOn} textOff={textOff} width={width}>
    <div>{label}</div>
    <input
      type="checkbox"
      checked={state.value}
      onChange={(evt) => state.change(evt.target.checked)}
    />
  </Label>
)

export const AdminCheckboxGroup = styled.div`
  ${adminInputWrapperStyle};
  display: flex;
  > label {
    margin-left: 15px;
  }
  > label:first-of-type {
    margin-left: 0px;
  }
`

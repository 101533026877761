import { useQuery } from '@apollo/client/react/hooks'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { AdminImportActivityPoliticiansQuery } from 'queries/queries'

function usePoliticiansWithMandates() {
  const { loading, data, refetch } =
    useQuery<AdminImportActivityPoliticiansQuery>(gql`
      query AdminImportActivityPoliticians {
        politicians(limit: -1) {
          list {
            id
            person {
              name
              surname
            }
            mandates {
              list {
                submandates {
                  list {
                    id
                    start
                    end
                    electionPeriod {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
  const politicians = data && data.politicians
  return [
    useMemo(() => {
      if (loading) return 'loading' as const
      if (!politicians) return 'error' as const
      return politicians.list.map((p) => ({
        name: `${p.person.name} ${p.person.surname}`.trim(),
        submandates: p.mandates.list
          .map((m) =>
            m.submandates.list.map((s) => ({
              ...s,
              start: DateTime.fromISO(s.start),
              end: s.end ? DateTime.fromISO(s.end) : null,
            })),
          )
          .reduce((a, b) => a.concat(b), []),
      }))
    }, [politicians, loading]),
    refetch,
  ] as const
}

type RemoveStrings<T> = T extends string ? never : T
type PolicianSubmandates = RemoveStrings<
  ReturnType<typeof usePoliticiansWithMandates>[0]
>

export function useGetSubmandate() {
  const [politicians, refetch] = usePoliticiansWithMandates()
  const submandate = useMemo(():
    | {
        get: (
          person: { name: string; surname: string } | string,
          date: DateTime,
        ) => string | { submandate: string; electionPeriod: string }
        inspect: (sub: string) => string | null
      }
    | 'loading'
    | 'error' => {
    if (typeof politicians === 'string') return politicians
    const map = new Map<string, PolicianSubmandates>()
    const inspectMap = new Map<string, string>()
    const duplicates: any[] = []
    for (const pol of politicians) {
      const name = pol.name
      const v = map.get(name) || []
      v.push(pol)
      map.set(name, v)
      for (const sub of pol.submandates) {
        inspectMap.set(sub.id, `${pol.name} ${sub.start.get('year')}`)
      }
      if (v.length !== 1 && !duplicates.includes(v)) duplicates.push(v)
    }
    return {
      get: (
        person: { name: string; surname: string } | string,
        date: DateTime,
      ) => {
        const proposer =
          typeof person === 'string'
            ? person
            : `${person.name} ${person.surname}`.trim()
        const pols = map.get(proposer)
        if (!pols) {
          const ret = `Politik jménem ${JSON.stringify(proposer)} nenalezen`
          console.log(ret)
          return ret
        }
        let matchedSubmandate: {
          submandate: string
          electionPeriod: string
        } | null = null
        for (const pol of pols) {
          for (const submandate of pol.submandates) {
            if (submandate.start > date) continue
            if (submandate.end !== null && submandate.end < date) continue
            if (matchedSubmandate)
              return `Nalezeno víc submandátu v datu ${date.toISO()} pro politika jménem "${proposer}"`

            matchedSubmandate = {
              submandate: submandate.id,
              electionPeriod: submandate.electionPeriod!.id,
            }
          }
        }

        if (!matchedSubmandate) {
          const ret = `Pro politika "${proposer}" nenalezen submandát odpovídající danému datu`
          console.log(ret)
          return ret
        }
        return matchedSubmandate
      },
      inspect: (submandate: string) => inspectMap.get(submandate) || null,
    }
  }, [politicians])
  return [submandate, refetch] as const
}

export function ImporterHelp({ definitions = true }) {
  return (
    <div>
      <p>
        Následuj kroky. Některé kroky jsem napsala tak, aby se automaticky
        splnily pokud soubor odpovídá tomu, pro který jsem to psala. Také by by
        to mělo fungovat tak, že pokud nastane chyba tak se to na daném kroku
        zastaví.
      </p>
      {definitions ? (
        <p>
          Pokud nebudeš vědět co některý výraz znamená tak nad něj najeď myší. K
          některým pojmům jsem napsala nápovědu co si pod tím představuji.
        </p>
      ) : null}
    </div>
  )
}

import { DocumentNode } from 'graphql'
import { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

type UndefinableProperties<T> = {
  [K in keyof T]: T[K] extends undefined ? never : K
}[keyof T]

type NonUndefinableProperties<T> = {
  [K in keyof T]: T[K] extends undefined ? K : never
}[keyof T]

type UndefinedToOptional<T> = {
  [k in keyof Pick<T, UndefinableProperties<T>>]?: T[k]
} & { [k in keyof Pick<T, NonUndefinableProperties<T>>]: T[k] }

type OrFunction<R, A> = R | ((a: A, extra?: any) => R)

export const createUseMutation =
  <D, V>(
    mutation: DocumentNode,
    {
      refetchQueries,
    }: {
      refetchQueries?: OrFunction<{ query: DocumentNode; variables?: any }[], V>
    } = {},
  ) =>
  ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (d: D) => void
    onError?: (e: any) => void
  }) => {
    const [state, setState] = useState({ error: null as any, mutating: false })
    const client = useApolloClient()

    return {
      error: state.error,
      mutating: state.mutating,
      mutate: (
        props: (V extends undefined ? { variables?: V } : { variables: V }) & {
          extra?: any
        },
      ) => {
        if (state.mutating)
          throw new Error('Only one mutation can be in flight')
        const variables = (props as any).variables as V

        setState({ mutating: true, error: null })

        client
          .mutate({
            mutation,
            variables,
            awaitRefetchQueries: true,
            refetchQueries:
              typeof refetchQueries === 'function'
                ? refetchQueries(variables, props.extra)
                : refetchQueries,
          })
          .then((v: any) => {
            if (v.data) {
              setState({ mutating: false, error: null })
              if (onSuccess) onSuccess(v.data as any)
            } else {
              if (onError) onError(v.errors)
              setState({ mutating: false, error: v.errors })
            }
          })
          .catch((e: any) => {
            setState({ mutating: false, error: e })
            if (onError) onError(e)
          })
      },
    }
  }

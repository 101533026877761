/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Card, CardBodyTwoColumn } from 'admin/components/card'
import { EditCard } from 'admin/components/edit-card'
import AdminInput from 'admin/components/admin-input'
import { useFormState } from 'admin/utils/use-form-state'
import { useAdminParty, useAdminUpdatePartyMutation } from 'admin/queries/party'
import { AdminCheckbox } from 'admin/components/checkbox'

const AdminUpdateParty = ({ id }: { id: string }) => {
  const party = useAdminParty(id)

  const formState = useFormState(
    party && {
      displayText: party.displayText,
      availableInReflektor: party.availableInReflektor,
    },
  )()

  const { mutate, error, mutating } = useAdminUpdatePartyMutation({})

  if (formState.failed || !party) return <>Strana nenalezeno</>
  const { changed, patch, state } = formState

  const name = { value: party.name || '' }
  const abbreviation = { value: party.abbreviation || '' }
  const color = { value: party.color || '' }
  const contrastingColor = { value: party.contrastingColor || '' }

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <EditCard
        title="Upravit stranu"
        changed={changed}
        saving={mutating}
        onSave={() => {
          mutate({
            variables: {
              id,
              data: patch,
            },
          })
        }}
        CardBody={CardBodyTwoColumn}
      >
        <AdminInput label="Jméno" state={name} />
        <AdminInput label="Zkratka" state={abbreviation} />
        <AdminInput label="Barva" state={color} />
        <AdminInput label="Kontrastní barva" state={contrastingColor} />
        <AdminInput
          label="Zobrazované jméno"
          placeholder="Pokud je ponecháno prázdné použije se zkratka"
          state={state.displayText}
        />
        <AdminCheckbox
          state={state.availableInReflektor}
          label="Dostupná v reflektoru"
        />
      </EditCard>
      <Card title="Náhled">
        Na webu nemáme komponentu pro zobrazování témat
      </Card>
    </>
  )
}
export default AdminUpdateParty

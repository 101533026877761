/** @jsx jsx */
import { jsx } from '@emotion/react'
import { EditCard } from 'admin/components/edit-card'
import {
  useReflektorUpsertUpdate,
  useAdminPollsAtDate,
  useAdminPollById,
  useAdminReflektorUpdate,
} from 'admin/queries/reflektor-update'
import isEqual from 'lodash.isequal'
import { useFormState } from 'admin/utils/use-form-state'
import { useState, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import AdminInput from 'admin/components/admin-input'
import { notNull } from '@codewitchbella/ts-utils'
import { Card } from 'admin/components/card'
import InsertPoliticiansFromPoll from './insert-politicians-from-poll'
import { useAdminPoliticianList } from 'admin/queries/politicians'
import styled from '@emotion/styled'
import latinize from 'latinize'
import { Tooltip } from '@material-ui/core'
import { SingleTwemoji } from 'components/twemoji'
import { AdminReflektorUpdateFragment } from 'queries/queries'

function coerceDate(date: string | null) {
  if (!date) return null
  const v = DateTime.fromFormat(date.replace(/ /g, ''), 'd.M.yyyy', {
    zone: 'UTC',
  })
  if (!v.isValid) return null
  return v.toISO()
}

type PoliticianListEntry = {
  id: string
  good: boolean
  isAboveFold: boolean
}

function sortPoliticianList(arr: PoliticianListEntry[]) {
  return [...arr].sort((a, b) => (a.id < b.id ? -1 : a.id === b.id ? 0 : 1))
}

function comparePoliticianList(
  a: PoliticianListEntry[],
  b: PoliticianListEntry[],
) {
  const sortedA = sortPoliticianList(a)
  const sortedB = sortPoliticianList(b)
  return isEqual(sortedA, sortedB)
}

const Politician = styled.div`
  button {
    opacity: 0;
    pointer-events: none;
  }
  :hover button {
    opacity: 1;
    pointer-events: all;
  }
`

export default function UpsertReflektorUpdatePoliticianList({
  updateId,
  list,
  poll,
  enabled,
}: {
  updateId: string
  list: AdminReflektorUpdateFragment['politicians']['list']
  poll: { id: string } | null
  enabled: boolean
}) {
  const formState = useFormState({
    politicians: list.map((p) => ({
      id: p.politician.id,
      good: p.good,
      isAboveFold: p.isAboveFold,
    })),
    poll: poll ? poll.id : null,
  })({
    converters: {
      politicians: (v) =>
        v.map((p) => ({
          politician: p.id,
          good: p.good,
          isAboveFold: p.isAboveFold,
        })),
    },
    comparators: {
      politicians: comparePoliticianList,
    },
  })
  const allPoliticians = useAdminPoliticianList()
  const politicianMap = useMemo(() => {
    const ret = new Map<
      string,
      { id: string; person: { id: string; name: string; surname: string } }
    >()
    if (allPoliticians) allPoliticians.forEach((p) => ret.set(p.id, p))
    return ret
  }, [allPoliticians])

  const { mutating, error, mutate } = useReflektorUpsertUpdate({})

  const [filter, setFilter] = useState('')
  const filterState = { value: filter, change: setFilter }

  if (formState.failed) return <>Načítání selhalo</>
  const { state, patch, changed } = formState

  if (!enabled || updateId === 'new') {
    return (
      <Card title="Seznam politiků">
        {!enabled
          ? 'Seznam politiků nelze nastavit pro informační výzvu'
          : 'Seznam politiků budete moct nastavit až uložíte hlavní informace o výzvě'}
      </Card>
    )
  }

  function change(v: PoliticianListEntry[], pollId: string | null = null) {
    state.politicians.change(v)
    state.poll.change(pollId)
  }

  function showList(l: PoliticianListEntry[]) {
    return l
      .sort((a, b) =>
        a.isAboveFold === b.isAboveFold ? 0 : a.isAboveFold ? -1 : 1,
      )
      .map((p) => {
        const v = politicianMap.get(p.id)
        const handler = () => {
          change(state.politicians.value.filter((val) => val.id !== p.id))
        }
        const toggleAboveFold = () => {
          change(
            state.politicians.value.map((val) =>
              val.id !== p.id ? val : { ...val, isAboveFold: !val.isAboveFold },
            ),
          )
        }
        if (!v)
          return (
            <Politician key={p.id}>
              Neplatný politik {p.id}
              <button type="button" onClick={handler}>
                Odstranit
              </button>
            </Politician>
          )
        return (
          <Politician key={p.id}>
            {v.person.name} {v.person.surname}{' '}
            <button type="button" onClick={handler}>
              <SingleTwemoji emoji="❌" />
            </button>
            <button type="button" onClick={toggleAboveFold}>
              <SingleTwemoji emoji={p.isAboveFold ? '⬇' : '⬆'} />
            </button>
          </Politician>
        )
      })
  }

  const leftList = (allPoliticians || [])
    .filter((v) => !state.politicians.value.some((p) => p.id === v.id))
    .filter((v) =>
      latinize(`${v.person.name} ${v.person.surname}`)
        .toLowerCase()
        .includes(latinize(filterState.value).toLowerCase()),
    )
    .sort((a, b) =>
      !!a.email === !!b.email ? 0 : !!a.email && !b.email ? -1 : 1,
    )

  return (
    <>
      {error && Object.keys(error).length > 0 && (
        <pre>{JSON.stringify(error, null, 2)}</pre>
      )}
      <EditCard
        title="Seznam politiků"
        changed={changed}
        saving={mutating}
        onSave={() => {
          mutate({
            variables: {
              id: updateId,
              data: patch,
            },
          })
        }}
      >
        {state.politicians.value.length === 0 ? (
          <InsertPoliticiansFromPoll
            onChange={(v, pollId) => {
              change(v, pollId)
            }}
          />
        ) : (
          <button
            onClick={(evt) => {
              evt.preventDefault()
              change([])
            }}
          >
            Vymazat
          </button>
        )}
        {allPoliticians && (
          <>
            <AdminInput
              state={filterState}
              label="Vyhledávání"
              placeholder="Zadejte jméno politika"
            />
            <div css={{ display: 'flex', '> div': { border: '1px' } }}>
              <div>
                <div>Nezahrnutí</div>
                {leftList.slice(0, 50).map((p) => {
                  const handler = (good: boolean) => (evt: any) => {
                    evt.preventDefault()
                    change([
                      { id: p.id, good, isAboveFold: false },
                      ...state.politicians.value,
                    ])
                  }
                  return (
                    <Politician key={p.id}>
                      <span>
                        {p.person.name} {p.person.surname}
                      </span>
                      {p.email ? null : (
                        <Tooltip title="Na tohoto politika nemáme email">
                          <span>
                            <SingleTwemoji emoji="⚠" />
                          </span>
                        </Tooltip>
                      )}{' '}
                      <button onClick={handler(true)}>
                        <SingleTwemoji emoji="👍" />
                      </button>{' '}
                      <button onClick={handler(false)}>
                        <SingleTwemoji emoji="👎" />
                      </button>
                    </Politician>
                  )
                })}
                {leftList.length > 50 &&
                  'Nezobrazuji všechny politiky, použijte vyhledávání...'}
              </div>
              <div>
                <div>V souladu</div>
                {showList(state.politicians.value.filter((p) => p.good))}
              </div>
              <div>
                <div>V nesouladu</div>
                {showList(state.politicians.value.filter((p) => !p.good))}
              </div>
            </div>
          </>
        )}
      </EditCard>
    </>
  )
}

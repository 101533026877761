import gql from 'graphql-tag'

import { createUseMutation } from 'admin/utils/create-use-mutation'
import {
  AdminSendTestNotificationMutation,
  AdminSendTestNotificationMutationVariables,
  AdminReflektorUpdateSendTestNotificationMutation,
  AdminReflektorUpdateSendTestNotificationMutationVariables,
} from 'queries/queries'

const adminSendTestNotificationMutation = gql`
  mutation adminSendTestNotification($title: String!, $body: String!) {
    sendTestNotification(title: $title, body: $body)
  }
`

export const useAdminSendTestNotificationMutation = createUseMutation<
  AdminSendTestNotificationMutation,
  AdminSendTestNotificationMutationVariables
>(adminSendTestNotificationMutation, {})

const adminReflektorUpdateSendTestNotificationMutation = gql`
  mutation adminReflektorUpdateSendTestNotification($id: GlobalID!) {
    reflektorUpdateSendTestNotification(id: $id)
  }
`

export const useAdminReflektorUpdateSendTestNotificationMutation =
  createUseMutation<
    AdminReflektorUpdateSendTestNotificationMutation,
    AdminReflektorUpdateSendTestNotificationMutationVariables
  >(adminReflektorUpdateSendTestNotificationMutation, {})

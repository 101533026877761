/** @jsx jsx */
import { jsx } from '@emotion/react'
import { StringC, NumberC, BooleanC } from './json-view-parts'
import { JSONViewOptions } from './json-view-types'

export function JSONPrimitive({
  data,
  options,
}: {
  data: number | string | null | boolean
  options: JSONViewOptions
}) {
  if (typeof data === 'string') {
    if (options.isLink(data)) {
      return <StringC onClick={() => options.onClick(data)}>{data}</StringC>
    }
    if (data.startsWith('https://') || data.startsWith('http://')) {
      return (
        <StringC>
          <Link>{data}</Link>
        </StringC>
      )
    }
    return <StringC>{data}</StringC>
  }
  if (typeof data === 'number') {
    return <NumberC>{JSON.stringify(data)}</NumberC>
  }
  if (typeof data === 'boolean') {
    return <BooleanC>{JSON.stringify(data)}</BooleanC>
  }
  return <>{JSON.stringify(data)}</>
}

function Link({ children }: { children: string }) {
  return (
    <a target="_blank" rel="noreferrer noopener" href={children}>
      {children}
    </a>
  )
}

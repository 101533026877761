/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  AdminSubmandatePicker,
  SubmandatePickerProvider,
} from 'admin/components/submandate-picker'
import { useState, useReducer, useEffect, useMemo } from 'react'
import { Fab, Tooltip } from '@material-ui/core'
import { EditCard } from 'admin/components/edit-card'
import AddIcon from '@material-ui/icons/Add'
import gql from 'graphql-tag'
import * as q from 'queries/queries'
import { useQuery, useMutation } from '@apollo/client/react/hooks'
import LoaderSpinner from 'components/loader-spinner'
import { AdminCheckbox, AdminCheckboxGroup } from 'admin/components/checkbox'
import AdminInput from 'admin/components/admin-input'
import { AdminSelectTopic } from 'admin/components/admin-select-topic'
import { useBariolBold } from 'utils/fonts'
import { useHistory } from 'react-router-dom'
import AdminButton from 'admin/components/admin-button'
import Raven from 'raven-js'
import { useDeleteActivityGroupMutation } from 'queries/queries'

const fragment = gql`
  fragment AdminUpsertActivityGroupGroup on ActivityGroup {
    id
    isPositive
    topic {
      id
    }
    activities {
      list {
        id
        weight
        description
        hidden
        submandates {
          list {
            id
          }
        }
      }
    }
  }
`

const groupById = gql`
  query AdminUpsertActivityGroupById($id: GlobalID!) {
    activityGroupById(id: $id) {
      ...AdminUpsertActivityGroupGroup
    }
  }
  ${fragment}
`
const mutation = gql`
  mutation AdminUpsertActivityGroup(
    $id: GlobalID
    $data: UpsertActivityGroup!
  ) {
    upsertActivityGroup(id: $id, data: $data) {
      ...AdminUpsertActivityGroupGroup
    }
  }
  ${fragment}
`

export function useUpsertActivityGroup() {
  return useMutation<
    q.AdminUpsertActivityGroupMutation,
    q.AdminUpsertActivityGroupMutationVariables
  >(mutation)
}

const useActivityGroupById = (id: string, { skip }: { skip?: boolean }) =>
  useQuery<
    q.AdminUpsertActivityGroupByIdQuery,
    q.AdminUpsertActivityGroupByIdQueryVariables
  >(groupById, { variables: { id }, skip })

type NotNullish<T> = T extends null ? never : T extends undefined ? never : T

function convertFragment(
  res: NotNullish<q.AdminUpsertActivityGroupByIdQuery['activityGroupById']>,
) {
  return {
    id: res.id,
    isPositive: res.isPositive,
    topic: res.topic.id,
    activities: res.activities.list.map((ac) => ({
      id: ac.id,
      weight: ac.weight,
      submandates: ac.submandates.list.map((sub) => sub.id),
      description: ac.description,
      hidden: ac.hidden,
    })),
  }
}

function useActivityGroup(id: string) {
  const data = useActivityGroupById(id, { skip: id === 'new' })
  return useMemo(() => {
    if (id === 'new') {
      return {
        id,
        isPositive: true,
        topic: null as string | null,
        activities: [
          {
            id: `${Date.now()}`,
            submandates: [] as string[],
            description: '',
            weight: 0,
            hidden: false,
          },
        ],
        loadDate: Date.now(),
      }
    }
    if (data.loading) return 'loading'
    if (!data.data || !data.data.activityGroupById) return null
    return {
      ...convertFragment(data.data.activityGroupById),
      loadDate: Date.now(),
    }
  }, [data.data, data.loading, id])
}

type Filtered<T> = T extends string ? never : T extends null ? never : T
type Data = Filtered<ReturnType<typeof useActivityGroup>>

type Action =
  | { field: 'isPositive'; value: boolean }
  | { field: 'all'; value: Data }
  | { field: 'description'; item: number; value: string }
  | { field: 'weight'; item: number; value: number }
  | { field: 'topic'; value: string }
  | { field: 'activities'; value: 'add' }
  | { field: 'hidden'; item: number }
  | { field: 'submandates'; item: number; value: (prev: string[]) => string[] }

function reducer(prev: Data, a: Action): Data {
  if (a.field === 'isPositive') return { ...prev, isPositive: a.value }
  if (a.field === 'topic') return { ...prev, topic: a.value }
  if (a.field === 'all') return a.value
  if (a.field === 'hidden')
    return {
      ...prev,
      activities: prev.activities.map((ac, id) =>
        id === a.item ? { ...ac, hidden: !ac.hidden } : ac,
      ),
    }
  if (a.field === 'activities') {
    if (a.value === 'add')
      return {
        ...prev,
        activities: prev.activities.concat({
          id: `${Date.now()}`,
          submandates: [],
          description: '',
          weight: 0,
          hidden: false,
        }),
      }
  }
  if (a.field === 'description')
    return {
      ...prev,
      activities: prev.activities.map((ac, id) =>
        id === a.item ? { ...ac, description: a.value } : ac,
      ),
    }
  if (a.field === 'weight')
    return {
      ...prev,
      activities: prev.activities.map((ac, id) =>
        id === a.item ? { ...ac, weight: a.value } : ac,
      ),
    }
  if (a.field === 'submandates')
    return {
      ...prev,
      activities: prev.activities.map((ac, id) =>
        id === a.item ? { ...ac, submandates: a.value(ac.submandates) } : ac,
      ),
    }

  throw new Error('Unknown field')
}

function validate(data: Data) {
  if (!data.topic) return 'Musíte vybrat téma'
  if (data.activities.length < 1)
    return 'Musíte specifikovat alespoň jednu aktivitu'
  if (data.activities.some((ac) => !ac.description))
    return 'Každá aktivita musí mít popis'
  if (data.activities.some((ac) => ac.submandates.length < 1))
    return 'Každá aktivita musí mít přiřazen alespoň 1 submandát'
  if (
    data.activities
      .map((ac) => ac.submandates)
      .reduce((a, b) => a.concat(b), [])
      .some((sub, idx, list) => list.indexOf(sub) !== idx)
  )
    return 'Každý submandát může být vybrán pouze jednou'
  return null
}

function WeightField({
  value,
  onChange,
}: {
  value: number
  onChange: (v: number) => void
}) {
  const [v, setV] = useState(`${value}`)
  useEffect(() => {
    const weight = Number.parseInt(v, 10)
    if (Number.isInteger(weight) && value !== weight) onChange(weight)
  })
  return (
    <AdminInput
      label="Váha (v %)"
      state={{
        value: v,
        change: (vv) => {
          if (/^(100|[1-9]?[0-9]?)$/.test(v)) setV(vv)
        },
      }}
    />
  )
}

gql`
  mutation deleteActivityGroup($id: GlobalID!) {
    deleteActivityGroup(group: $id)
  }
`

function AdminUpsertActivityGroupImpl({ data }: { data: Data }) {
  const [{ group, changed, error }, dispatch] = useReducer(
    (
      state: { group: Data; changed: boolean; error: string | null },
      action: Action,
    ) => {
      const next = reducer(state.group, action)
      return {
        changed: true,
        group: next,
        error: validate(next),
      }
    },
    { group: data, changed: false, error: validate(data) },
  )

  const bariolBold = useBariolBold()
  const [mut, upsertInfo] = useUpsertActivityGroup()
  const history = useHistory()

  const [deleteActivityGroup, deleteInfo] = useDeleteActivityGroupMutation()

  return (
    <EditCard
      title={`${
        group.id === 'new' ? 'Přidat' : 'Upravit'
      } vyhodnocení aktivity`}
      changed={changed}
      saving={upsertInfo.loading || deleteInfo.loading}
      hasError={error !== null}
      onSave={() =>
        mut({
          variables: {
            id: group.id === 'new' ? undefined : group.id,
            data: {
              isPositive: group.isPositive,
              topic: group.topic!,
              activities: group.activities.map((ac) => ({
                weight: ac.weight,
                description: ac.description,
                submandates: ac.submandates,
                hidden: ac.hidden,
              })),
            },
          },
        }).then((v) => {
          const id =
            v &&
            v.data &&
            v.data.upsertActivityGroup &&
            v.data.upsertActivityGroup.id
          if (group.id === 'new' && id) {
            history.push(`/admin/activity/${id}`)
          }
        })
      }
    >
      {error !== null ? (
        <div css={[bariolBold, { color: 'red' }]}>{error}</div>
      ) : null}
      {group ? (
        <AdminButton
          type="button"
          css={{ border: '3px solid #aaa', fontSize: '1em' }}
          onClick={(evt) => {
            evt.preventDefault()
            if (!confirm('Opravdu?')) return
            deleteActivityGroup({
              variables: { id: group.id },
              refetchQueries: [
                {
                  query: q.AdminActivityGroupsByTopicDocument,
                  variables: { topic: group.topic },
                },
              ],
            })
              .then(() => {
                history.replace('/admin/activity/list')
              })
              .catch((e) => {
                console.warn(e)
                Raven.captureException(e)
              })
          }}
        >
          Smazat skupinu aktivit
        </AdminButton>
      ) : null}
      <AdminCheckbox
        label="Pozitivní"
        state={{
          value: group.isPositive,
          change: (v) => dispatch({ field: 'isPositive', value: v }),
        }}
      />

      <AdminSelectTopic
        topic={{
          value: group.topic,
          change: (v) => dispatch({ field: 'topic', value: v }),
        }}
        filter={(t) => t.availableInEvaluation}
      />

      {group.activities.map((ac, i) => (
        <div css={{ border: '1px solid black', marginTop: 20 }} key={ac.id}>
          <WeightField
            value={ac.weight}
            onChange={(v) => dispatch({ field: 'weight', item: i, value: v })}
          />
          <AdminInput
            label="Popis"
            state={{
              value: ac.description,
              change: (v) =>
                dispatch({ field: 'description', item: i, value: v }),
            }}
          />
          <AdminSubmandatePicker
            selected={ac.submandates}
            onChange={(v) =>
              dispatch({ field: 'submandates', item: i, value: v })
            }
          />
          <AdminCheckboxGroup>
            <AdminCheckbox
              label="Skrýt od veřejnosti"
              state={{
                value: ac.hidden,
                change: (v) => dispatch({ field: 'hidden', item: i }),
              }}
            />
          </AdminCheckboxGroup>
        </div>
      ))}

      <Tooltip title="Přidat další aktivitu">
        <Fab onClick={() => dispatch({ field: 'activities', value: 'add' })}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <aside>
        <i>Všichni politici musí být ze stejného volebního období</i>
      </aside>
    </EditCard>
  )
}

export default function AdminUpsertActivityGroup({ id }: { id: string }) {
  const data = useActivityGroup(id)
  if (data === 'loading') return <LoaderSpinner />
  if (!data) return null
  return (
    <SubmandatePickerProvider>
      <AdminUpsertActivityGroupImpl key={id + data.loadDate} data={data} />
    </SubmandatePickerProvider>
  )
}

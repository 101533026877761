/*
 * automatically generated file
 * do not edit. Regenerate using `yarn codegen`
 */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateOnly: string
  DateTime: string
  GlobalID: string
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  Upload: any
}

export type Activity = {
  readonly __typename: 'Activity'
  readonly id: Scalars['GlobalID']
  readonly submandates: SubmandatePage
  readonly description: Scalars['String']
  readonly weight: Scalars['Float']
  readonly group: ActivityGroup
  readonly hidden: Scalars['Boolean']
}

export type ActivityGroup = {
  readonly __typename: 'ActivityGroup'
  readonly id: Scalars['GlobalID']
  readonly isPositive: Scalars['Boolean']
  readonly topic: Topic
  readonly activities: ActivityPage
}

export type ActivityGroupPage = {
  readonly __typename: 'ActivityGroupPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ActivityGroup>
}

export type ActivityPage = {
  readonly __typename: 'ActivityPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Activity>
}

export type Authority = {
  readonly __typename: 'Authority'
  readonly id: Scalars['GlobalID']
  readonly name: Scalars['String']
  readonly topics: TopicPage
  readonly filteredTopics: TopicPage
  readonly reflektorHomepageUpdates?: Maybe<ReflektorUpdatePage>
}

export type AuthorityTopicsArgs = {
  reflektorOnly?: Maybe<Scalars['Boolean']>
}

export type AuthorityFilteredTopicsArgs = {
  only?: Maybe<TopicFilter>
}

export type AuthorityPage = {
  readonly __typename: 'AuthorityPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Authority>
}

export type Chamber = 'senate' | 'lower'

export type CommitteePollEvalInput = {
  readonly topic: Scalars['GlobalID']
  readonly isPositive: Scalars['Boolean']
  readonly weight: Scalars['Int']
  readonly date: Scalars['DateOnly']
  readonly description: Scalars['String']
  readonly result: PollResult
  readonly standpoint: Scalars['String']
  readonly votes: ReadonlyArray<CommitteePollEvalInputVote>
}

export type CommitteePollEvalInputVote = {
  readonly submandate: Scalars['GlobalID']
  readonly vote: VoteValue
}

export type CreatePoliticianInput = {
  readonly name: Scalars['String']
  readonly surname: Scalars['String']
  readonly gender: Gender
  readonly image?: Maybe<Scalars['GlobalID']>
  readonly email: Scalars['String']
  readonly statement?: Maybe<Scalars['String']>
}

export type CreatePoliticianPayload = {
  readonly __typename: 'CreatePoliticianPayload'
  readonly politician: Politician
}

export type ElectionPeriod = {
  readonly __typename: 'ElectionPeriod'
  readonly id: Scalars['GlobalID']
  readonly start: Scalars['DateTime']
  readonly end?: Maybe<Scalars['DateTime']>
  readonly isSenate: Scalars['Boolean']
  readonly importId?: Maybe<Scalars['String']>
}

export type File = {
  readonly __typename: 'File'
  readonly id: Scalars['GlobalID']
  readonly sha256: Scalars['String']
  readonly mime: Scalars['String']
  readonly url: Scalars['String']
  readonly size: Scalars['Int']
  readonly imageMetadata?: Maybe<ImageMetadata>
}

export type FileUrlArgs = {
  full?: Maybe<Scalars['Boolean']>
}

export type FilePage = {
  readonly __typename: 'FilePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<File>
}

export type Gender = 'male' | 'female'

export type GenerateApiTokenPayload = {
  readonly __typename: 'GenerateApiTokenPayload'
  readonly token: UserApiToken
  readonly value: Scalars['String']
}

export type GlobalSettingKv = {
  readonly __typename: 'GlobalSettingKV'
  readonly name: Scalars['String']
  readonly value: Scalars['String']
  readonly description: Scalars['String']
  readonly graphqlReadonly: Scalars['Boolean']
  readonly enum?: Maybe<ReadonlyArray<SettingEnum>>
}

export type ImageMetadata = {
  readonly __typename: 'ImageMetadata'
  readonly width: Scalars['Int']
  readonly height: Scalars['Int']
}

export type IsEmailUsedPayload = {
  readonly __typename: 'IsEmailUsedPayload'
  readonly used: Scalars['Boolean']
  readonly verified: Scalars['Boolean']
  readonly canResendAt?: Maybe<Scalars['DateTime']>
  readonly serverTime: Scalars['DateTime']
}

export type LoginInput = {
  readonly email: Scalars['String']
  readonly password: Scalars['String']
  readonly reflektor?: Maybe<Scalars['Boolean']>
}

export type LoginPayload = {
  readonly __typename: 'LoginPayload'
  readonly user?: Maybe<User>
}

export type Mandate = {
  readonly __typename: 'Mandate'
  readonly id: Scalars['GlobalID']
  readonly chamber: Chamber
  readonly start: Scalars['DateTime']
  readonly end?: Maybe<Scalars['DateTime']>
  readonly region?: Maybe<Region>
  readonly politician: Politician
  readonly submandates: SubmandatePage
}

export type MandatePage = {
  readonly __typename: 'MandatePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Mandate>
}

export type Migration = {
  readonly __typename: 'Migration'
  readonly name: Scalars['String']
  readonly up: Scalars['String']
  readonly down: Scalars['String']
  readonly description: Scalars['String']
}

export type MigrationPage = {
  readonly __typename: 'MigrationPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Migration>
}

export type Mutation = {
  readonly __typename: 'Mutation'
  readonly debugInsert: Scalars['String']
  readonly debugInit: Scalars['String']
  readonly upsertPerson?: Maybe<UpsertPersonPayload>
  readonly createPolitician?: Maybe<CreatePoliticianPayload>
  readonly updatePolitician?: Maybe<UpdatePoliticianPayload>
  readonly upsertTopic?: Maybe<UpsertTopicPayload>
  readonly setGlobalSetting: GlobalSettingKv
  readonly upload?: Maybe<File>
  readonly updateParty?: Maybe<UpdatePartyPayload>
  readonly reflektorUpdateProfile?: Maybe<ReflektorUpdateProfilePayload>
  readonly reflektorUpdateSetSeen?: Maybe<ReflektorUpdateSetSeenPayload>
  readonly reflektorUserVote?: Maybe<ReflektorUserVotePayload>
  readonly reflektorUpsertUpdate?: Maybe<ReflektorUpsertUpdatePayload>
  readonly reflektorUpdateSendTestNotification?: Maybe<Scalars['String']>
  readonly sendTestNotification?: Maybe<Scalars['String']>
  readonly reflektorReportSentEmail?: Maybe<ReflektorReportSentEmail>
  readonly reflektorUnreportSentEmail?: Maybe<ReflektorUnreportSentEmail>
  readonly addRole: Scalars['Boolean']
  readonly login: LoginPayload
  readonly logout: Scalars['Boolean']
  readonly register: RegisterPayload
  readonly removeRole: Scalars['Boolean']
  readonly resendVerificationEmail: Scalars['Boolean']
  readonly userAddEmail?: Maybe<UserAddEmailPayload>
  readonly userDelete?: Maybe<Scalars['Boolean']>
  readonly userChangePassword: Scalars['Boolean']
  readonly userRemoveEmail?: Maybe<UserRemoveEmailPayload>
  readonly verifyEmail?: Maybe<VerifyEmailPayload>
  readonly userRequestPasswordRecovery: Scalars['Boolean']
  readonly userRecoverPassword?: Maybe<UserRecoverPasswordPayload>
  readonly userAddNotificationToken: UserAddNotificationTokenPayload
  readonly upsertActivityGroup?: Maybe<ActivityGroup>
  readonly upsertPollEvaluation?: Maybe<UpsertPollEvaluationPayload>
  readonly createCommitteePollEval: Scalars['Boolean']
  readonly deleteActivityGroup: Scalars['Boolean']
  readonly deletePollEval: Scalars['Boolean']
  readonly generateApiToken?: Maybe<GenerateApiTokenPayload>
  readonly deleteApiToken?: Maybe<Scalars['Boolean']>
  readonly testSentry?: Maybe<Scalars['Boolean']>
}

export type MutationDebugInsertArgs = {
  type: Scalars['String']
  value: Scalars['JSON']
}

export type MutationUpsertPersonArgs = {
  id?: Maybe<Scalars['GlobalID']>
  data: PersonPatchInput
}

export type MutationCreatePoliticianArgs = {
  input: CreatePoliticianInput
}

export type MutationUpdatePoliticianArgs = {
  input: UpdatePoliticianInput
}

export type MutationUpsertTopicArgs = {
  id?: Maybe<Scalars['GlobalID']>
  data: TopicPatchInput
}

export type MutationSetGlobalSettingArgs = {
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type MutationUploadArgs = {
  file: Scalars['Upload']
}

export type MutationUpdatePartyArgs = {
  id: Scalars['GlobalID']
  data: PartyPatchInput
}

export type MutationReflektorUpdateProfileArgs = {
  id?: Maybe<Scalars['GlobalID']>
  patch: ReflektorUserProfilePatch
}

export type MutationReflektorUpdateSetSeenArgs = {
  update: Scalars['GlobalID']
}

export type MutationReflektorUserVoteArgs = {
  reflektorUpdateId: Scalars['GlobalID']
  votes: ReadonlyArray<ReflektorUserVoteInput>
}

export type MutationReflektorUpsertUpdateArgs = {
  id?: Maybe<Scalars['GlobalID']>
  data: ReflektorUpdatePatchInput
}

export type MutationReflektorUpdateSendTestNotificationArgs = {
  id: Scalars['GlobalID']
}

export type MutationSendTestNotificationArgs = {
  title: Scalars['String']
  body: Scalars['String']
}

export type MutationReflektorReportSentEmailArgs = {
  updatePolitician: Scalars['GlobalID']
}

export type MutationReflektorUnreportSentEmailArgs = {
  updatePolitician: Scalars['GlobalID']
}

export type MutationAddRoleArgs = {
  userId: Scalars['GlobalID']
  role: Scalars['String']
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationRegisterArgs = {
  input: RegisterInput
}

export type MutationRemoveRoleArgs = {
  userId: Scalars['GlobalID']
  role: Scalars['String']
}

export type MutationResendVerificationEmailArgs = {
  email: Scalars['String']
}

export type MutationUserAddEmailArgs = {
  input: UserAddEmailInput
}

export type MutationUserDeleteArgs = {
  id: Scalars['GlobalID']
}

export type MutationUserChangePasswordArgs = {
  input: UserChangePasswordInput
}

export type MutationUserRemoveEmailArgs = {
  input: UserRemoveEmailInput
}

export type MutationVerifyEmailArgs = {
  token: Scalars['String']
}

export type MutationUserRequestPasswordRecoveryArgs = {
  email: Scalars['String']
}

export type MutationUserRecoverPasswordArgs = {
  input: UserRecoverPasswordInput
}

export type MutationUserAddNotificationTokenArgs = {
  input: UserAddNotificationTokenInput
}

export type MutationUpsertActivityGroupArgs = {
  id?: Maybe<Scalars['GlobalID']>
  data: UpsertActivityGroup
}

export type MutationUpsertPollEvaluationArgs = {
  id?: Maybe<Scalars['GlobalID']>
  data: PollEvaluationPatchInput
}

export type MutationCreateCommitteePollEvalArgs = {
  data: CommitteePollEvalInput
}

export type MutationDeleteActivityGroupArgs = {
  group: Scalars['GlobalID']
}

export type MutationDeletePollEvalArgs = {
  evaluation: Scalars['GlobalID']
}

export type MutationGenerateApiTokenArgs = {
  name: Scalars['String']
}

export type MutationDeleteApiTokenArgs = {
  id: Scalars['GlobalID']
}

export type Node =
  | Mandate
  | PartyMembership
  | Politician
  | Topic
  | File
  | Party
  | ReflektorUpdate
  | ReflektorUpdatePolitician
  | ActivityGroup
  | Activity
  | Submandate
  | Poll
  | PollEvaluation
  | ParlamentaryGroup
  | ParlamentaryGroupMembership

export type Pagination = {
  readonly limit: Scalars['Int']
  readonly offset: Scalars['Int']
}

export type ParlamentaryGroup = {
  readonly __typename: 'ParlamentaryGroup'
  readonly id: Scalars['GlobalID']
  readonly start: Scalars['DateTime']
  readonly end?: Maybe<Scalars['DateTime']>
  readonly name: Scalars['String']
  readonly abbreviation: Scalars['String']
  readonly party?: Maybe<Party>
}

export type ParlamentaryGroupMembership = {
  readonly __typename: 'ParlamentaryGroupMembership'
  readonly id: Scalars['GlobalID']
  readonly end?: Maybe<Scalars['DateTime']>
  readonly group: ParlamentaryGroup
  readonly start: Scalars['DateTime']
  readonly submandate: Submandate
}

export type ParlamentaryGroupMembershipPage = {
  readonly __typename: 'ParlamentaryGroupMembershipPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ParlamentaryGroupMembership>
}

export type ParlamentaryGroupPage = {
  readonly __typename: 'ParlamentaryGroupPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ParlamentaryGroup>
}

export type Party = {
  readonly __typename: 'Party'
  readonly id: Scalars['GlobalID']
  readonly color?: Maybe<Scalars['String']>
  readonly contrastingColor?: Maybe<Scalars['String']>
  readonly logo?: Maybe<File>
  readonly abbreviation: Scalars['String']
  readonly name?: Maybe<Scalars['String']>
  readonly partyMemberships: PartyMembershipPage
  readonly displayText: Scalars['String']
  readonly availableInReflektor: Scalars['Boolean']
}

export type PartyDisplayTextArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type PartyMembership = {
  readonly __typename: 'PartyMembership'
  readonly id: Scalars['GlobalID']
  readonly politician: Politician
  readonly party: Party
  readonly start?: Maybe<Scalars['DateTime']>
  readonly end?: Maybe<Scalars['DateTime']>
  readonly function?: Maybe<Scalars['String']>
}

export type PartyMembershipPage = {
  readonly __typename: 'PartyMembershipPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<PartyMembership>
}

export type PartyPage = {
  readonly __typename: 'PartyPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Party>
}

export type PartyPatchInput = {
  readonly displayText?: Maybe<Scalars['String']>
  readonly availableInReflektor?: Maybe<Scalars['Boolean']>
}

export type Person = {
  readonly __typename: 'Person'
  readonly id: Scalars['GlobalID']
  readonly name: Scalars['String']
  readonly surname: Scalars['String']
  readonly titleAfterName: Scalars['String']
  readonly titleBeforeName: Scalars['String']
  readonly gender: Gender
  readonly image?: Maybe<File>
  readonly phone?: Maybe<Scalars['Int']>
  readonly email?: Maybe<Scalars['String']>
  readonly dateOfBirth?: Maybe<Scalars['DateOnly']>
  readonly deceased?: Maybe<Scalars['DateOnly']>
}

export type PersonNameArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type PersonPatchInput = {
  readonly name?: Maybe<Scalars['String']>
  readonly titleAfterName?: Maybe<Scalars['String']>
  readonly titleBeforeName?: Maybe<Scalars['String']>
  readonly gender?: Maybe<Gender>
  readonly image?: Maybe<Scalars['GlobalID']>
  readonly phone?: Maybe<Scalars['Int']>
  readonly email?: Maybe<Scalars['String']>
  readonly dateOfBirth?: Maybe<Scalars['DateOnly']>
  readonly deceased?: Maybe<Scalars['DateOnly']>
}

export type Politician = {
  readonly __typename: 'Politician'
  readonly id: Scalars['GlobalID']
  readonly person: Person
  readonly caption?: Maybe<Scalars['String']>
  readonly senateImportId?: Maybe<Scalars['Int']>
  readonly lowerChamberImportId?: Maybe<Scalars['String']>
  readonly partyMemberships: PartyMembershipPage
  readonly mandates: MandatePage
  readonly evaluation?: Maybe<PoliticianEvaluation>
  readonly dead?: Maybe<Scalars['Boolean']>
  readonly filteredEvaluations: PoliticianFilteredEvaluationPage
  readonly email?: Maybe<Scalars['String']>
  readonly parlamentaryGroup?: Maybe<ParlamentaryGroup>
  readonly partyOverride?: Maybe<Scalars['String']>
  readonly resolvedPartyAbbreviation?: Maybe<Scalars['String']>
  /** @deprecated No longer supported */
  readonly reflektorTitleOverride?: Maybe<Scalars['String']>
  readonly slug: Scalars['String']
  readonly statement: Scalars['String']
}

export type PoliticianParlamentaryGroupArgs = {
  date?: Maybe<Scalars['DateTime']>
}

export type PoliticianEvaluation = {
  readonly __typename: 'PoliticianEvaluation'
  readonly id: Scalars['GlobalID']
  readonly rating?: Maybe<Scalars['Int']>
  readonly supportCategory: Scalars['String']
  readonly sortable: Scalars['Float']
}

export type PoliticianFilteredEvaluation = {
  readonly __typename: 'PoliticianFilteredEvaluation'
  readonly id: Scalars['GlobalID']
  readonly votePositive: Scalars['Float']
  readonly voteNegative: Scalars['Float']
  readonly voteNeutral: Scalars['Float']
  readonly activityPositive: Scalars['Float']
  readonly activityNegative: Scalars['Float']
  readonly overallPositive: Scalars['Float']
  readonly overallNegative: Scalars['Float']
  readonly overallNeutral: Scalars['Float']
  readonly politician: Politician
  readonly filterElectionPeriod?: Maybe<ElectionPeriod>
  readonly filterTopic?: Maybe<Topic>
}

export type PoliticianFilteredEvaluationPage = {
  readonly __typename: 'PoliticianFilteredEvaluationPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<PoliticianFilteredEvaluation>
}

export type PoliticianPage = {
  readonly __typename: 'PoliticianPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Politician>
}

export type Poll = {
  readonly __typename: 'Poll'
  readonly id: Scalars['GlobalID']
  readonly result: PollResult
  readonly date: Scalars['DateTime']
  readonly electionPeriod: ElectionPeriod
  readonly description?: Maybe<Scalars['String']>
  readonly context?: Maybe<Scalars['String']>
  readonly url?: Maybe<Scalars['String']>
  readonly importId?: Maybe<Scalars['String']>
  readonly voteKnown: Scalars['Boolean']
  readonly votes: VotePage
  readonly evaluations: PollEvaluationPage
}

export type PollDate = {
  readonly __typename: 'PollDate'
  readonly date: Scalars['DateOnly']
  readonly count: Scalars['Int']
}

export type PollEvaluation = {
  readonly __typename: 'PollEvaluation'
  readonly id: Scalars['GlobalID']
  readonly poll: Poll
  readonly topic: Topic
  readonly weight: Scalars['Float']
  readonly description: Scalars['String']
  readonly standpoint: Scalars['String']
  readonly positive: Scalars['Boolean']
  readonly committeePoll: Scalars['Boolean']
}

export type PollEvaluationDescriptionArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type PollEvaluationFilter = {
  readonly topic: Scalars['GlobalID']
}

export type PollEvaluationPage = {
  readonly __typename: 'PollEvaluationPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<PollEvaluation>
}

export type PollEvaluationPatchInput = {
  readonly poll?: Maybe<Scalars['GlobalID']>
  readonly topic?: Maybe<Scalars['GlobalID']>
  readonly weight?: Maybe<Scalars['Float']>
  readonly description?: Maybe<Scalars['String']>
  readonly standpoint?: Maybe<Scalars['String']>
  readonly positive?: Maybe<Scalars['Boolean']>
}

export type PollPage = {
  readonly __typename: 'PollPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Poll>
}

export type PollResult = 'accepted' | 'rejected' | 'confused' | 'private'

export type PollsFilter = {
  readonly date: Scalars['DateOnly']
}

export type Query = {
  readonly __typename: 'Query'
  readonly authorities: AuthorityPage
  readonly politicians: PoliticianPage
  readonly politician?: Maybe<Politician>
  readonly globalSetting?: Maybe<GlobalSettingKv>
  readonly globalSettings: ReadonlyArray<GlobalSettingKv>
  readonly filesBySha256: FilePage
  readonly files: FilePage
  readonly parties: PartyPage
  readonly me?: Maybe<User>
  readonly users?: Maybe<UserPage>
  readonly isEmailUsed: IsEmailUsedPayload
  readonly userIsPasswordRecoveryTokenValid: Scalars['Boolean']
  readonly activityGroupById?: Maybe<ActivityGroup>
  readonly activityGroupsByTopic: ActivityGroupPage
  readonly migrations: MigrationPage
  readonly regions: RegionPage
  readonly submandates: SubmandatePage
  readonly submandatesByDate: SubmandatePage
  readonly submandatesByIds: ReadonlyArray<Submandate>
  readonly polls: PollPage
  readonly pollDates: ReadonlyArray<PollDate>
  readonly pollEvaluations: PollEvaluationPage
  readonly preparedQuery?: Maybe<Scalars['JSON']>
  readonly node?: Maybe<Node>
}

export type QueryPoliticiansArgs = {
  limit?: Maybe<Scalars['Int']>
  onlyWithEvaluations?: Maybe<Scalars['Boolean']>
  onlyFromLatestPeriod?: Maybe<Scalars['Boolean']>
}

export type QueryPoliticianArgs = {
  slug: Scalars['String']
}

export type QueryGlobalSettingArgs = {
  name: Scalars['String']
}

export type QueryFilesBySha256Args = {
  sha256: ReadonlyArray<Scalars['String']>
}

export type QueryFilesArgs = {
  mime?: Maybe<Scalars['String']>
}

export type QueryPartiesArgs = {
  reflektor?: Maybe<Scalars['Boolean']>
}

export type QueryIsEmailUsedArgs = {
  email: Scalars['String']
}

export type QueryUserIsPasswordRecoveryTokenValidArgs = {
  token: Scalars['String']
}

export type QueryActivityGroupByIdArgs = {
  id: Scalars['GlobalID']
}

export type QueryActivityGroupsByTopicArgs = {
  topic: Scalars['GlobalID']
}

export type QueryRegionsArgs = {
  onlyCurrent?: Maybe<Scalars['Boolean']>
}

export type QuerySubmandatesArgs = {
  electionPeriod?: Maybe<Scalars['GlobalID']>
}

export type QuerySubmandatesByDateArgs = {
  date: Scalars['DateTime']
}

export type QuerySubmandatesByIdsArgs = {
  ids: ReadonlyArray<Scalars['GlobalID']>
}

export type QueryPollsArgs = {
  filter?: Maybe<PollsFilter>
}

export type QueryPollEvaluationsArgs = {
  filter: PollEvaluationFilter
}

export type QueryPreparedQueryArgs = {
  query?: Maybe<Scalars['String']>
}

export type QueryNodeArgs = {
  id: Scalars['GlobalID']
}

export type ReflektorReportSentEmail = {
  readonly __typename: 'ReflektorReportSentEmail'
  readonly success: Scalars['Boolean']
}

export type ReflektorUnreportSentEmail = {
  readonly __typename: 'ReflektorUnreportSentEmail'
  readonly success: Scalars['Boolean']
}

export type ReflektorUpdate = {
  readonly __typename: 'ReflektorUpdate'
  readonly id: Scalars['GlobalID']
  readonly title: Scalars['String']
  readonly titleGoodPoliticians: Scalars['String']
  readonly titleNotGoodPoliticians: Scalars['String']
  readonly linkHref: Scalars['String']
  readonly linkLabel: Scalars['String']
  readonly leadImage?: Maybe<File>
  readonly publishDate?: Maybe<Scalars['DateTime']>
  readonly type: ReflektorUpdateTypeEnum
  readonly endDate?: Maybe<Scalars['DateTime']>
  readonly politicians: ReflektorUpdatePoliticianPage
  readonly topic: Topic
  readonly shareLink: Scalars['String']
  readonly poll?: Maybe<Poll>
  readonly votesCount: Scalars['Int']
  readonly onlyCorrespondingEmoji: Scalars['Boolean']
  readonly archive: Scalars['Boolean']
  readonly summary: Scalars['JSON']
  readonly text: Scalars['JSON']
  readonly seen: Scalars['Boolean']
}

export type ReflektorUpdateLinkHrefArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type ReflektorUpdateSummaryArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type ReflektorUpdateTextArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type ReflektorUpdatePage = {
  readonly __typename: 'ReflektorUpdatePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ReflektorUpdate>
}

export type ReflektorUpdatePatchInput = {
  readonly title?: Maybe<Scalars['String']>
  readonly titleGoodPoliticians?: Maybe<Scalars['String']>
  readonly titleNotGoodPoliticians?: Maybe<Scalars['String']>
  readonly linkHref?: Maybe<Scalars['String']>
  readonly linkLabel?: Maybe<Scalars['String']>
  readonly type?: Maybe<ReflektorUpdateTypeEnum>
  readonly topic?: Maybe<Scalars['GlobalID']>
  readonly summary?: Maybe<Scalars['JSON']>
  readonly text?: Maybe<Scalars['JSON']>
  readonly politicians?: Maybe<ReadonlyArray<ReflektorUpdatePoliticiansInput>>
  readonly leadImage?: Maybe<Scalars['GlobalID']>
  readonly publishDate?: Maybe<Scalars['DateTime']>
  readonly endDate?: Maybe<Scalars['DateTime']>
  readonly poll?: Maybe<Scalars['GlobalID']>
  readonly onlyCorrespondingEmoji?: Maybe<Scalars['Boolean']>
  readonly archive?: Maybe<Scalars['Boolean']>
}

export type ReflektorUpdatePolitician = {
  readonly __typename: 'ReflektorUpdatePolitician'
  readonly id: Scalars['GlobalID']
  readonly politician: Politician
  readonly good: Scalars['Boolean']
  readonly isAboveFold: Scalars['Boolean']
  readonly voteCount: Scalars['Int']
  readonly emailSenders?: Maybe<ReflektorUserProfilePage>
  readonly votesMap: ReflektorUpdatePoliticianVotesMap
  readonly myVote?: Maybe<ReflektorUserVote>
}

export type ReflektorUpdatePoliticianPage = {
  readonly __typename: 'ReflektorUpdatePoliticianPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ReflektorUpdatePolitician>
}

export type ReflektorUpdatePoliticianVotesMap = {
  readonly __typename: 'ReflektorUpdatePoliticianVotesMap'
  readonly heart_eyes: Scalars['Int']
  readonly scream: Scalars['Int']
}

export type ReflektorUpdatePoliticiansInput = {
  readonly politician: Scalars['GlobalID']
  readonly good: Scalars['Boolean']
  readonly isAboveFold?: Maybe<Scalars['Boolean']>
}

export type ReflektorUpdateProfilePayload = {
  readonly __typename: 'ReflektorUpdateProfilePayload'
  readonly reflektorProfile: ReflektorUserProfile
}

export type ReflektorUpdateSetSeenPayload = {
  readonly __typename: 'ReflektorUpdateSetSeenPayload'
  readonly update: ReflektorUpdate
}

export type ReflektorUpdateTypeEnum = 'info' | 'mail' | 'vote'

export type ReflektorUpsertUpdatePayload = {
  readonly __typename: 'ReflektorUpsertUpdatePayload'
  readonly update: ReflektorUpdate
}

export type ReflektorUserProfile = {
  readonly __typename: 'ReflektorUserProfile'
  readonly id: Scalars['GlobalID']
  readonly zipcode?: Maybe<Scalars['Int']>
  readonly registerDate: Scalars['DateTime']
  readonly subscribedParties: PartyPage
  readonly sentEmailsCount: Scalars['Int']
  readonly votesCount: Scalars['Int']
  readonly unreadCount: Scalars['Int']
  readonly user?: Maybe<User>
}

export type ReflektorUserProfilePage = {
  readonly __typename: 'ReflektorUserProfilePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<ReflektorUserProfile>
}

export type ReflektorUserProfilePatch = {
  readonly zipcode?: Maybe<Scalars['Int']>
  readonly subscribedParties?: Maybe<ReadonlyArray<Scalars['GlobalID']>>
  readonly sentEmailsCount?: Maybe<Scalars['Int']>
}

export type ReflektorUserVote = {
  readonly __typename: 'ReflektorUserVote'
  readonly id: Scalars['GlobalID']
  readonly type: ReflektorUserVoteTypeEnum
}

export type ReflektorUserVoteInput = {
  readonly politicianId: Scalars['GlobalID']
  readonly type: ReflektorUserVoteTypeEnum
}

export type ReflektorUserVotePayload = {
  readonly __typename: 'ReflektorUserVotePayload'
  readonly update: ReflektorUpdate
  readonly updatePoliticians: ReadonlyArray<ReflektorUpdatePolitician>
}

export type ReflektorUserVoteTypeEnum = 'heart_eyes' | 'scream'

export type Region = {
  readonly __typename: 'Region'
  readonly id: Scalars['GlobalID']
  readonly name: Scalars['String']
  readonly current: Scalars['Boolean']
  readonly start: Scalars['DateTime']
}

export type RegionPage = {
  readonly __typename: 'RegionPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Region>
}

export type RegisterInput = {
  readonly email: Scalars['String']
  readonly password: Scalars['String']
}

export type RegisterPayload = {
  readonly __typename: 'RegisterPayload'
  readonly user?: Maybe<User>
}

export type SettingEnum = {
  readonly __typename: 'SettingEnum'
  readonly value: Scalars['String']
  readonly display: Scalars['String']
}

export type Submandate = {
  readonly __typename: 'Submandate'
  readonly id: Scalars['GlobalID']
  readonly start: Scalars['DateTime']
  readonly end?: Maybe<Scalars['DateTime']>
  readonly mandate: Mandate
  readonly electionPeriod: ElectionPeriod
  readonly activities: ActivityPage
  readonly votes: VotePage
  readonly parlamentaryGroupMemberships: ParlamentaryGroupMembershipPage
}

export type SubmandateVotesArgs = {
  onlyEvaluated?: Maybe<Scalars['Boolean']>
}

export type SubmandatePage = {
  readonly __typename: 'SubmandatePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Submandate>
}

export type Topic = {
  readonly __typename: 'Topic'
  readonly id: Scalars['GlobalID']
  readonly name: Scalars['String']
  readonly icon?: Maybe<File>
  readonly shareLink: Scalars['String']
  readonly availableInReflektor: Scalars['Boolean']
  readonly availableInEvaluation: Scalars['Boolean']
  readonly description: Scalars['JSON']
  readonly reflektorUpdates: ReflektorUpdatePage
  readonly reflektorHot: Scalars['Boolean']
}

export type TopicShareLinkArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type TopicDescriptionArgs = {
  editable?: Maybe<Scalars['Boolean']>
}

export type TopicReflektorUpdatesArgs = {
  onlyPublished?: Maybe<Scalars['Boolean']>
}

export type TopicFilter = 'reflektor' | 'evaluation'

export type TopicPage = {
  readonly __typename: 'TopicPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Topic>
}

export type TopicPatchInput = {
  readonly name?: Maybe<Scalars['String']>
  readonly icon?: Maybe<Scalars['GlobalID']>
  readonly description?: Maybe<Scalars['JSON']>
  readonly shareLink?: Maybe<Scalars['String']>
  readonly availableInReflektor?: Maybe<Scalars['Boolean']>
  readonly availableInEvaluation?: Maybe<Scalars['Boolean']>
}

export type UpdatePartyPayload = {
  readonly __typename: 'UpdatePartyPayload'
  readonly party: Party
}

export type UpdatePoliticianInput = {
  readonly id: Scalars['GlobalID']
  readonly name?: Maybe<Scalars['String']>
  readonly surname?: Maybe<Scalars['String']>
  readonly gender?: Maybe<Gender>
  readonly image?: Maybe<Scalars['GlobalID']>
  readonly email?: Maybe<Scalars['String']>
  readonly statement?: Maybe<Scalars['String']>
  readonly partyOverride?: Maybe<Scalars['String']>
}

export type UpdatePoliticianPayload = {
  readonly __typename: 'UpdatePoliticianPayload'
  readonly politician: Politician
}

export type UpsertActivityGroup = {
  readonly isPositive: Scalars['Boolean']
  readonly topic: Scalars['GlobalID']
  readonly activities: ReadonlyArray<UpsertActivityGroupActivity>
}

export type UpsertActivityGroupActivity = {
  readonly description: Scalars['String']
  readonly submandates: ReadonlyArray<Scalars['GlobalID']>
  readonly weight: Scalars['Int']
  readonly hidden: Scalars['Boolean']
}

export type UpsertPersonPayload = {
  readonly __typename: 'UpsertPersonPayload'
  readonly person: Person
}

export type UpsertPollEvaluationPayload = {
  readonly __typename: 'UpsertPollEvaluationPayload'
  readonly pollEvaluation: PollEvaluation
}

export type UpsertTopicPayload = {
  readonly __typename: 'UpsertTopicPayload'
  readonly topic: Topic
}

export type User = {
  readonly __typename: 'User'
  readonly id: Scalars['GlobalID']
  readonly roles: ReadonlyArray<Scalars['String']>
  readonly emails: ReadonlyArray<UserEmail>
  readonly reflektorProfile?: Maybe<ReflektorUserProfile>
  readonly apiTokens: ReadonlyArray<UserApiToken>
}

export type UserEmailsArgs = {
  onlyPrimary?: Maybe<Scalars['Boolean']>
}

export type UserAddEmailInput = {
  readonly email: Scalars['String']
}

export type UserAddEmailPayload = {
  readonly __typename: 'UserAddEmailPayload'
  readonly user: User
}

export type UserAddNotificationTokenInput = {
  readonly token?: Maybe<Scalars['String']>
  readonly extraInfo?: Maybe<Scalars['String']>
  readonly namespace?: Maybe<Scalars['String']>
}

export type UserAddNotificationTokenPayload = {
  readonly __typename: 'UserAddNotificationTokenPayload'
  readonly success: Scalars['Boolean']
}

export type UserApiToken = {
  readonly __typename: 'UserApiToken'
  readonly id: Scalars['GlobalID']
  readonly name: Scalars['String']
  readonly expiration?: Maybe<Scalars['DateTime']>
}

export type UserChangePasswordInput = {
  readonly old: Scalars['String']
  readonly new: Scalars['String']
}

export type UserEmail = {
  readonly __typename: 'UserEmail'
  readonly id: Scalars['GlobalID']
  readonly value: Scalars['String']
  readonly normalized: Scalars['String']
  readonly verified: Scalars['Boolean']
  readonly primary: Scalars['Boolean']
}

export type UserPage = {
  readonly __typename: 'UserPage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<User>
}

export type UserRecoverPasswordInput = {
  readonly newPassword: Scalars['String']
  readonly token: Scalars['String']
}

export type UserRecoverPasswordPayload = {
  readonly __typename: 'UserRecoverPasswordPayload'
  readonly success: Scalars['Boolean']
  readonly email: Scalars['String']
  readonly emailVerified: Scalars['Boolean']
}

export type UserRemoveEmailInput = {
  readonly email: Scalars['String']
}

export type UserRemoveEmailPayload = {
  readonly __typename: 'UserRemoveEmailPayload'
  readonly user: User
}

export type VerifyEmailPayload = {
  readonly __typename: 'VerifyEmailPayload'
  readonly email?: Maybe<Scalars['String']>
}

export type Vote = {
  readonly __typename: 'Vote'
  readonly id: Scalars['GlobalID']
  readonly submandate: Submandate
  readonly poll: Poll
  readonly vote: VoteValue
}

export type VotePage = {
  readonly __typename: 'VotePage'
  readonly count: Scalars['Int']
  readonly list: ReadonlyArray<Vote>
}

export type VoteValue =
  | 'for'
  | 'against'
  | 'abstained'
  | 'not_present'
  | 'secret'
  | 'excused'

export type AdminSubmandatePickerSubmandateFragment = {
  readonly __typename: 'Submandate'
  readonly id: string
  readonly start: string
  readonly end?: Maybe<string>
  readonly mandate: {
    readonly __typename: 'Mandate'
    readonly id: string
    readonly chamber: Chamber
    readonly region?: Maybe<{
      readonly __typename: 'Region'
      readonly id: string
      readonly name: string
    }>
    readonly politician: {
      readonly __typename: 'Politician'
      readonly id: string
      readonly person: {
        readonly __typename: 'Person'
        readonly id: string
        readonly name: string
        readonly surname: string
      }
    }
  }
}

export type AdminSubmandatePickerQueryVariables = Exact<{
  date: Scalars['DateTime']
}>

export type AdminSubmandatePickerQuery = {
  readonly __typename: 'Query'
  readonly submandatesByDate: {
    readonly __typename: 'SubmandatePage'
    readonly count: number
    readonly list: ReadonlyArray<{
      readonly __typename: 'Submandate'
      readonly id: string
      readonly start: string
      readonly end?: Maybe<string>
      readonly mandate: {
        readonly __typename: 'Mandate'
        readonly id: string
        readonly chamber: Chamber
        readonly region?: Maybe<{
          readonly __typename: 'Region'
          readonly id: string
          readonly name: string
        }>
        readonly politician: {
          readonly __typename: 'Politician'
          readonly id: string
          readonly person: {
            readonly __typename: 'Person'
            readonly id: string
            readonly name: string
            readonly surname: string
          }
        }
      }
    }>
  }
}

export type AdminSubmandatePickerMissingQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['GlobalID']> | Scalars['GlobalID']
}>

export type AdminSubmandatePickerMissingQuery = {
  readonly __typename: 'Query'
  readonly submandatesByIds: ReadonlyArray<{
    readonly __typename: 'Submandate'
    readonly id: string
    readonly start: string
    readonly end?: Maybe<string>
    readonly mandate: {
      readonly __typename: 'Mandate'
      readonly id: string
      readonly chamber: Chamber
      readonly region?: Maybe<{
        readonly __typename: 'Region'
        readonly id: string
        readonly name: string
      }>
      readonly politician: {
        readonly __typename: 'Politician'
        readonly id: string
        readonly person: {
          readonly __typename: 'Person'
          readonly id: string
          readonly name: string
          readonly surname: string
        }
      }
    }
  }>
}

export type AdminRemoveRoleMutationVariables = Exact<{
  userId: Scalars['GlobalID']
  role: Scalars['String']
}>

export type AdminRemoveRoleMutation = {
  readonly __typename: 'Mutation'
  readonly removeRole: boolean
}

export type AdminAddRoleMutationVariables = Exact<{
  userId: Scalars['GlobalID']
  role: Scalars['String']
}>

export type AdminAddRoleMutation = {
  readonly __typename: 'Mutation'
  readonly addRole: boolean
}

export type AdminImportCommitteeVoteCreateMutationVariables = Exact<{
  data: CommitteePollEvalInput
}>

export type AdminImportCommitteeVoteCreateMutation = {
  readonly __typename: 'Mutation'
  readonly createCommitteePollEval: boolean
}

export type AdminImportActivityPoliticiansQueryVariables = Exact<{
  [key: string]: never
}>

export type AdminImportActivityPoliticiansQuery = {
  readonly __typename: 'Query'
  readonly politicians: {
    readonly __typename: 'PoliticianPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Politician'
      readonly id: string
      readonly person: {
        readonly __typename: 'Person'
        readonly name: string
        readonly surname: string
      }
      readonly mandates: {
        readonly __typename: 'MandatePage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Mandate'
          readonly submandates: {
            readonly __typename: 'SubmandatePage'
            readonly list: ReadonlyArray<{
              readonly __typename: 'Submandate'
              readonly id: string
              readonly start: string
              readonly end?: Maybe<string>
              readonly electionPeriod: {
                readonly __typename: 'ElectionPeriod'
                readonly id: string
              }
            }>
          }
        }>
      }
    }>
  }
}

export type AdminSendTestNotificationMutationVariables = Exact<{
  title: Scalars['String']
  body: Scalars['String']
}>

export type AdminSendTestNotificationMutation = {
  readonly __typename: 'Mutation'
  readonly sendTestNotification?: Maybe<string>
}

export type AdminReflektorUpdateSendTestNotificationMutationVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminReflektorUpdateSendTestNotificationMutation = {
  readonly __typename: 'Mutation'
  readonly reflektorUpdateSendTestNotification?: Maybe<string>
}

export type AdminPartyListQueryVariables = Exact<{ [key: string]: never }>

export type AdminPartyListQuery = {
  readonly __typename: 'Query'
  readonly parties: {
    readonly __typename: 'PartyPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Party'
      readonly id: string
      readonly abbreviation: string
      readonly name?: Maybe<string>
      readonly displayText: string
    }>
  }
}

export type PartyFragment = {
  readonly __typename: 'Party'
  readonly id: string
  readonly name?: Maybe<string>
  readonly abbreviation: string
  readonly displayText: string
  readonly color?: Maybe<string>
  readonly contrastingColor?: Maybe<string>
  readonly availableInReflektor: boolean
}

export type AdminPartyQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminPartyQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | { readonly __typename: 'Politician' }
    | { readonly __typename: 'Topic' }
    | { readonly __typename: 'File' }
    | {
        readonly __typename: 'Party'
        readonly id: string
        readonly name?: Maybe<string>
        readonly abbreviation: string
        readonly displayText: string
        readonly color?: Maybe<string>
        readonly contrastingColor?: Maybe<string>
        readonly availableInReflektor: boolean
      }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | { readonly __typename: 'Poll' }
    | { readonly __typename: 'PollEvaluation' }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type AdminUpdatePartyMutationVariables = Exact<{
  id: Scalars['GlobalID']
  data: PartyPatchInput
}>

export type AdminUpdatePartyMutation = {
  readonly __typename: 'Mutation'
  readonly updateParty?: Maybe<{
    readonly __typename: 'UpdatePartyPayload'
    readonly party: {
      readonly __typename: 'Party'
      readonly id: string
      readonly name?: Maybe<string>
      readonly abbreviation: string
      readonly displayText: string
      readonly color?: Maybe<string>
      readonly contrastingColor?: Maybe<string>
      readonly availableInReflektor: boolean
    }
  }>
}

export type AdminPoliticianListQueryVariables = Exact<{ [key: string]: never }>

export type AdminPoliticianListQuery = {
  readonly __typename: 'Query'
  readonly politicians: {
    readonly __typename: 'PoliticianPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Politician'
      readonly id: string
      readonly email?: Maybe<string>
      readonly person: {
        readonly __typename: 'Person'
        readonly id: string
        readonly name: string
        readonly surname: string
      }
    }>
  }
}

export type AdminPollEvaluationListQueryVariables = Exact<{
  topic: Scalars['GlobalID']
}>

export type AdminPollEvaluationListQuery = {
  readonly __typename: 'Query'
  readonly pollEvaluations: {
    readonly __typename: 'PollEvaluationPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'PollEvaluation'
      readonly id: string
      readonly committeePoll: boolean
      readonly description: string
      readonly poll: {
        readonly __typename: 'Poll'
        readonly id: string
        readonly date: string
      }
    }>
  }
}

export type PollEvaluationFragment = {
  readonly __typename: 'PollEvaluation'
  readonly id: string
  readonly weight: number
  readonly description: string
  readonly standpoint: string
  readonly positive: boolean
  readonly committeePoll: boolean
  readonly poll: { readonly __typename: 'Poll'; readonly id: string }
  readonly topic: { readonly __typename: 'Topic'; readonly id: string }
}

export type AdminUpsertPollEvaluationMutationVariables = Exact<{
  id?: Maybe<Scalars['GlobalID']>
  data: PollEvaluationPatchInput
}>

export type AdminUpsertPollEvaluationMutation = {
  readonly __typename: 'Mutation'
  readonly upsertPollEvaluation?: Maybe<{
    readonly __typename: 'UpsertPollEvaluationPayload'
    readonly pollEvaluation: {
      readonly __typename: 'PollEvaluation'
      readonly id: string
      readonly weight: number
      readonly description: string
      readonly standpoint: string
      readonly positive: boolean
      readonly committeePoll: boolean
      readonly poll: { readonly __typename: 'Poll'; readonly id: string }
      readonly topic: { readonly __typename: 'Topic'; readonly id: string }
    }
  }>
}

export type AdminPollEvaluationQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminPollEvaluationQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | { readonly __typename: 'Politician' }
    | { readonly __typename: 'Topic' }
    | { readonly __typename: 'File' }
    | { readonly __typename: 'Party' }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | { readonly __typename: 'Poll' }
    | {
        readonly __typename: 'PollEvaluation'
        readonly id: string
        readonly weight: number
        readonly description: string
        readonly standpoint: string
        readonly positive: boolean
        readonly committeePoll: boolean
        readonly poll: { readonly __typename: 'Poll'; readonly id: string }
        readonly topic: { readonly __typename: 'Topic'; readonly id: string }
      }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type AdminReflektorUpdateFragment = {
  readonly __typename: 'ReflektorUpdate'
  readonly id: string
  readonly title: string
  readonly titleGoodPoliticians: string
  readonly titleNotGoodPoliticians: string
  readonly linkHref: string
  readonly linkLabel: string
  readonly publishDate?: Maybe<string>
  readonly type: ReflektorUpdateTypeEnum
  readonly endDate?: Maybe<string>
  readonly summary: any
  readonly text: any
  readonly onlyCorrespondingEmoji: boolean
  readonly archive: boolean
  readonly leadImage?: Maybe<{
    readonly __typename: 'File'
    readonly id: string
    readonly url: string
  }>
  readonly politicians: {
    readonly __typename: 'ReflektorUpdatePoliticianPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'ReflektorUpdatePolitician'
      readonly id: string
      readonly good: boolean
      readonly isAboveFold: boolean
      readonly politician: {
        readonly __typename: 'Politician'
        readonly id: string
        readonly person: {
          readonly __typename: 'Person'
          readonly id: string
          readonly name: string
        }
      }
    }>
  }
  readonly topic: { readonly __typename: 'Topic'; readonly id: string }
  readonly poll?: Maybe<{ readonly __typename: 'Poll'; readonly id: string }>
}

export type AdminGetReflektorUpdateQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminGetReflektorUpdateQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | { readonly __typename: 'Politician' }
    | { readonly __typename: 'Topic' }
    | { readonly __typename: 'File' }
    | { readonly __typename: 'Party' }
    | {
        readonly __typename: 'ReflektorUpdate'
        readonly id: string
        readonly title: string
        readonly titleGoodPoliticians: string
        readonly titleNotGoodPoliticians: string
        readonly linkHref: string
        readonly linkLabel: string
        readonly publishDate?: Maybe<string>
        readonly type: ReflektorUpdateTypeEnum
        readonly endDate?: Maybe<string>
        readonly summary: any
        readonly text: any
        readonly onlyCorrespondingEmoji: boolean
        readonly archive: boolean
        readonly leadImage?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
          readonly url: string
        }>
        readonly politicians: {
          readonly __typename: 'ReflektorUpdatePoliticianPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'ReflektorUpdatePolitician'
            readonly id: string
            readonly good: boolean
            readonly isAboveFold: boolean
            readonly politician: {
              readonly __typename: 'Politician'
              readonly id: string
              readonly person: {
                readonly __typename: 'Person'
                readonly id: string
                readonly name: string
              }
            }
          }>
        }
        readonly topic: { readonly __typename: 'Topic'; readonly id: string }
        readonly poll?: Maybe<{
          readonly __typename: 'Poll'
          readonly id: string
        }>
      }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | { readonly __typename: 'Poll' }
    | { readonly __typename: 'PollEvaluation' }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type AdminReflektorUpsertUpdateMutationVariables = Exact<{
  id?: Maybe<Scalars['GlobalID']>
  data: ReflektorUpdatePatchInput
}>

export type AdminReflektorUpsertUpdateMutation = {
  readonly __typename: 'Mutation'
  readonly reflektorUpsertUpdate?: Maybe<{
    readonly __typename: 'ReflektorUpsertUpdatePayload'
    readonly update: {
      readonly __typename: 'ReflektorUpdate'
      readonly id: string
      readonly title: string
      readonly titleGoodPoliticians: string
      readonly titleNotGoodPoliticians: string
      readonly linkHref: string
      readonly linkLabel: string
      readonly publishDate?: Maybe<string>
      readonly type: ReflektorUpdateTypeEnum
      readonly endDate?: Maybe<string>
      readonly summary: any
      readonly text: any
      readonly onlyCorrespondingEmoji: boolean
      readonly archive: boolean
      readonly leadImage?: Maybe<{
        readonly __typename: 'File'
        readonly id: string
        readonly url: string
      }>
      readonly politicians: {
        readonly __typename: 'ReflektorUpdatePoliticianPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'ReflektorUpdatePolitician'
          readonly id: string
          readonly good: boolean
          readonly isAboveFold: boolean
          readonly politician: {
            readonly __typename: 'Politician'
            readonly id: string
            readonly person: {
              readonly __typename: 'Person'
              readonly id: string
              readonly name: string
            }
          }
        }>
      }
      readonly topic: { readonly __typename: 'Topic'; readonly id: string }
      readonly poll?: Maybe<{
        readonly __typename: 'Poll'
        readonly id: string
      }>
    }
  }>
}

export type AdminReflektorUpdateListQueryVariables = Exact<{
  [key: string]: never
}>

export type AdminReflektorUpdateListQuery = {
  readonly __typename: 'Query'
  readonly authorities: {
    readonly __typename: 'AuthorityPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Authority'
      readonly topics: {
        readonly __typename: 'TopicPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Topic'
          readonly reflektorUpdates: {
            readonly __typename: 'ReflektorUpdatePage'
            readonly list: ReadonlyArray<{
              readonly __typename: 'ReflektorUpdate'
              readonly id: string
              readonly title: string
              readonly archive: boolean
              readonly publishDate?: Maybe<string>
            }>
          }
        }>
      }
    }>
  }
}

export type AdminPollsAtDateQueryVariables = Exact<{
  date: Scalars['DateOnly']
}>

export type AdminPollsAtDateQuery = {
  readonly __typename: 'Query'
  readonly polls: {
    readonly __typename: 'PollPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Poll'
      readonly id: string
      readonly description?: Maybe<string>
      readonly context?: Maybe<string>
      readonly importId?: Maybe<string>
      readonly url?: Maybe<string>
      readonly electionPeriod: {
        readonly __typename: 'ElectionPeriod'
        readonly id: string
        readonly isSenate: boolean
      }
    }>
  }
}

export type AdminPollByIdQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminPollByIdQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | { readonly __typename: 'Politician' }
    | { readonly __typename: 'Topic' }
    | { readonly __typename: 'File' }
    | { readonly __typename: 'Party' }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | {
        readonly __typename: 'Poll'
        readonly id: string
        readonly votes: {
          readonly __typename: 'VotePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Vote'
            readonly id: string
            readonly vote: VoteValue
            readonly submandate: {
              readonly __typename: 'Submandate'
              readonly mandate: {
                readonly __typename: 'Mandate'
                readonly politician: {
                  readonly __typename: 'Politician'
                  readonly id: string
                  readonly person: {
                    readonly __typename: 'Person'
                    readonly id: string
                    readonly name: string
                    readonly surname: string
                    readonly gender: Gender
                  }
                }
              }
            }
          }>
        }
      }
    | { readonly __typename: 'PollEvaluation' }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type AdminPollDatesQueryVariables = Exact<{ [key: string]: never }>

export type AdminPollDatesQuery = {
  readonly __typename: 'Query'
  readonly pollDates: ReadonlyArray<{
    readonly __typename: 'PollDate'
    readonly date: string
    readonly count: number
  }>
}

export type AdminTopicListQueryVariables = Exact<{
  only?: Maybe<TopicFilter>
}>

export type AdminTopicListQuery = {
  readonly __typename: 'Query'
  readonly authorities: {
    readonly __typename: 'AuthorityPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Authority'
      readonly topics: {
        readonly __typename: 'TopicPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Topic'
          readonly id: string
          readonly name: string
          readonly availableInReflektor: boolean
          readonly availableInEvaluation: boolean
        }>
      }
    }>
  }
}

export type TopicFragment = {
  readonly __typename: 'Topic'
  readonly id: string
  readonly name: string
  readonly description: any
  readonly shareLink: string
  readonly availableInReflektor: boolean
  readonly availableInEvaluation: boolean
  readonly icon?: Maybe<{
    readonly __typename: 'File'
    readonly id: string
    readonly url: string
  }>
}

export type AdminTopicQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminTopicQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | { readonly __typename: 'Politician' }
    | {
        readonly __typename: 'Topic'
        readonly id: string
        readonly name: string
        readonly description: any
        readonly shareLink: string
        readonly availableInReflektor: boolean
        readonly availableInEvaluation: boolean
        readonly icon?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
          readonly url: string
        }>
      }
    | { readonly __typename: 'File' }
    | { readonly __typename: 'Party' }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | { readonly __typename: 'Poll' }
    | { readonly __typename: 'PollEvaluation' }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type AdminUpsertTopicMutationVariables = Exact<{
  id?: Maybe<Scalars['GlobalID']>
  data: TopicPatchInput
}>

export type AdminUpsertTopicMutation = {
  readonly __typename: 'Mutation'
  readonly upsertTopic?: Maybe<{
    readonly __typename: 'UpsertTopicPayload'
    readonly topic: {
      readonly __typename: 'Topic'
      readonly id: string
      readonly name: string
      readonly description: any
      readonly shareLink: string
      readonly availableInReflektor: boolean
      readonly availableInEvaluation: boolean
      readonly icon?: Maybe<{
        readonly __typename: 'File'
        readonly id: string
        readonly url: string
      }>
    }
  }>
}

export type AdminMeQueryVariables = Exact<{ [key: string]: never }>

export type AdminMeQuery = {
  readonly __typename: 'Query'
  readonly me?: Maybe<{
    readonly __typename: 'User'
    readonly id: string
    readonly roles: ReadonlyArray<string>
  }>
}

export type AdminLoginMutationVariables = Exact<{
  input: LoginInput
}>

export type AdminLoginMutation = {
  readonly __typename: 'Mutation'
  readonly login: {
    readonly __typename: 'LoginPayload'
    readonly user?: Maybe<{ readonly __typename: 'User'; readonly id: string }>
  }
}

export type AdminRegisterMutationVariables = Exact<{
  input: RegisterInput
}>

export type AdminRegisterMutation = {
  readonly __typename: 'Mutation'
  readonly register: {
    readonly __typename: 'RegisterPayload'
    readonly user?: Maybe<{ readonly __typename: 'User'; readonly id: string }>
  }
}

export type AdminLogoutMutationVariables = Exact<{ [key: string]: never }>

export type AdminLogoutMutation = {
  readonly __typename: 'Mutation'
  readonly logout: boolean
}

export type AdminUserListQueryVariables = Exact<{ [key: string]: never }>

export type AdminUserListQuery = {
  readonly __typename: 'Query'
  readonly userList?: Maybe<{
    readonly __typename: 'UserPage'
    readonly count: number
    readonly users: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: string
      readonly roles: ReadonlyArray<string>
      readonly emails: ReadonlyArray<{
        readonly __typename: 'UserEmail'
        readonly value: string
        readonly verified: boolean
      }>
    }>
  }>
}

export type AdminUserDeleteMutationVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminUserDeleteMutation = {
  readonly __typename: 'Mutation'
  readonly userDelete?: Maybe<boolean>
}

export type AdminApiTokensQueryVariables = Exact<{ [key: string]: never }>

export type AdminApiTokensQuery = {
  readonly __typename: 'Query'
  readonly me?: Maybe<{
    readonly __typename: 'User'
    readonly id: string
    readonly apiTokens: ReadonlyArray<{
      readonly __typename: 'UserApiToken'
      readonly id: string
      readonly name: string
      readonly expiration?: Maybe<string>
    }>
  }>
}

export type AdminGenerateApiTokenMutationVariables = Exact<{
  name: Scalars['String']
}>

export type AdminGenerateApiTokenMutation = {
  readonly __typename: 'Mutation'
  readonly generateApiToken?: Maybe<{
    readonly __typename: 'GenerateApiTokenPayload'
    readonly value: string
    readonly token: {
      readonly __typename: 'UserApiToken'
      readonly id: string
      readonly expiration?: Maybe<string>
      readonly name: string
    }
  }>
}

export type AdminDeleteApiTokenMutationVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminDeleteApiTokenMutation = {
  readonly __typename: 'Mutation'
  readonly deleteApiToken?: Maybe<boolean>
}

export type AdminAuditQueryVariables = Exact<{ [key: string]: never }>

export type AdminAuditQuery = {
  readonly __typename: 'Query'
  readonly authorities: {
    readonly __typename: 'AuthorityPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Authority'
      readonly topics: {
        readonly __typename: 'TopicPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Topic'
          readonly id: string
          readonly name: string
        }>
      }
    }>
  }
  readonly politicians: {
    readonly __typename: 'PoliticianPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Politician'
      readonly id: string
      readonly slug: string
      readonly person: {
        readonly __typename: 'Person'
        readonly id: string
        readonly name: string
        readonly surname: string
      }
      readonly evaluation?: Maybe<{
        readonly __typename: 'PoliticianEvaluation'
        readonly id: string
        readonly rating?: Maybe<number>
        readonly supportCategory: string
        readonly sortable: number
      }>
      readonly filteredEvaluations: {
        readonly __typename: 'PoliticianFilteredEvaluationPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'PoliticianFilteredEvaluation'
          readonly id: string
          readonly votePositive: number
          readonly voteNegative: number
          readonly voteNeutral: number
          readonly activityPositive: number
          readonly activityNegative: number
          readonly overallPositive: number
          readonly overallNegative: number
          readonly overallNeutral: number
          readonly filterElectionPeriod?: Maybe<{
            readonly __typename: 'ElectionPeriod'
            readonly id: string
            readonly start: string
            readonly end?: Maybe<string>
            readonly isSenate: boolean
          }>
          readonly filterTopic?: Maybe<{
            readonly __typename: 'Topic'
            readonly id: string
          }>
        }>
      }
    }>
  }
}

export type AdminDbExplorerQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminDbExplorerQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | {
        readonly __typename: 'Mandate'
        readonly id: string
        readonly chamber: Chamber
        readonly start: string
        readonly end?: Maybe<string>
        readonly region?: Maybe<{
          readonly __typename: 'Region'
          readonly id: string
        }>
        readonly politician: {
          readonly __typename: 'Politician'
          readonly id: string
        }
        readonly submandates: {
          readonly __typename: 'SubmandatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Submandate'
            readonly id: string
          }>
        }
      }
    | {
        readonly __typename: 'PartyMembership'
        readonly id: string
        readonly end?: Maybe<string>
        readonly function?: Maybe<string>
        readonly start_1?: Maybe<string>
        readonly politician: {
          readonly __typename: 'Politician'
          readonly id: string
        }
        readonly party: { readonly __typename: 'Party'; readonly id: string }
      }
    | {
        readonly __typename: 'Politician'
        readonly id: string
        readonly caption?: Maybe<string>
        readonly senateImportId?: Maybe<number>
        readonly lowerChamberImportId?: Maybe<string>
        readonly dead?: Maybe<boolean>
        readonly email?: Maybe<string>
        readonly reflektorTitleOverride?: Maybe<string>
        readonly resolvedPartyAbbreviation?: Maybe<string>
        readonly slug: string
        readonly person: { readonly __typename: 'Person'; readonly id: string }
        readonly partyMemberships: {
          readonly __typename: 'PartyMembershipPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'PartyMembership'
            readonly id: string
          }>
        }
        readonly mandates: {
          readonly __typename: 'MandatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Mandate'
            readonly id: string
          }>
        }
        readonly evaluation?: Maybe<{
          readonly __typename: 'PoliticianEvaluation'
          readonly id: string
        }>
        readonly filteredEvaluations: {
          readonly __typename: 'PoliticianFilteredEvaluationPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'PoliticianFilteredEvaluation'
            readonly id: string
          }>
        }
        readonly parlamentaryGroup?: Maybe<{
          readonly __typename: 'ParlamentaryGroup'
          readonly id: string
        }>
      }
    | {
        readonly __typename: 'Topic'
        readonly id: string
        readonly name: string
        readonly shareLink: string
        readonly availableInReflektor: boolean
        readonly availableInEvaluation: boolean
        readonly reflektorHot: boolean
        readonly description_1: any
        readonly icon?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
          readonly sha256: string
          readonly mime: string
          readonly url: string
          readonly size: number
          readonly imageMetadata?: Maybe<{
            readonly __typename: 'ImageMetadata'
            readonly width: number
            readonly height: number
          }>
        }>
        readonly reflektorUpdates: {
          readonly __typename: 'ReflektorUpdatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'ReflektorUpdate'
            readonly id: string
          }>
        }
      }
    | { readonly __typename: 'File' }
    | {
        readonly __typename: 'Party'
        readonly id: string
        readonly color?: Maybe<string>
        readonly contrastingColor?: Maybe<string>
        readonly abbreviation: string
        readonly displayText: string
        readonly availableInReflektor: boolean
        readonly name_1?: Maybe<string>
        readonly logo?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
        }>
        readonly partyMemberships: {
          readonly __typename: 'PartyMembershipPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'PartyMembership'
            readonly id: string
          }>
        }
      }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | {
        readonly __typename: 'ActivityGroup'
        readonly id: string
        readonly isPositive: boolean
        readonly topic: { readonly __typename: 'Topic'; readonly id: string }
        readonly activities: {
          readonly __typename: 'ActivityPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Activity'
            readonly id: string
          }>
        }
      }
    | {
        readonly __typename: 'Activity'
        readonly id: string
        readonly description: string
        readonly weight: number
        readonly submandates: {
          readonly __typename: 'SubmandatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Submandate'
            readonly id: string
          }>
        }
      }
    | {
        readonly __typename: 'Submandate'
        readonly id: string
        readonly start: string
        readonly end?: Maybe<string>
        readonly mandate: {
          readonly __typename: 'Mandate'
          readonly id: string
        }
        readonly electionPeriod: {
          readonly __typename: 'ElectionPeriod'
          readonly id: string
        }
        readonly votes: {
          readonly __typename: 'VotePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Vote'
            readonly id: string
          }>
        }
        readonly parlamentaryGroupMemberships: {
          readonly __typename: 'ParlamentaryGroupMembershipPage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'ParlamentaryGroupMembership'
            readonly id: string
            readonly start: string
            readonly end?: Maybe<string>
            readonly group: {
              readonly __typename: 'ParlamentaryGroup'
              readonly id: string
            }
            readonly submandate: {
              readonly __typename: 'Submandate'
              readonly id: string
            }
          }>
        }
      }
    | { readonly __typename: 'Poll' }
    | { readonly __typename: 'PollEvaluation' }
    | {
        readonly __typename: 'ParlamentaryGroup'
        readonly id: string
        readonly start: string
        readonly end?: Maybe<string>
        readonly name: string
        readonly abbreviation: string
        readonly party_1?: Maybe<{
          readonly __typename: 'Party'
          readonly id: string
        }>
      }
    | {
        readonly __typename: 'ParlamentaryGroupMembership'
        readonly id: string
        readonly start: string
        readonly end?: Maybe<string>
        readonly group: {
          readonly __typename: 'ParlamentaryGroup'
          readonly id: string
        }
        readonly submandate: {
          readonly __typename: 'Submandate'
          readonly id: string
        }
      }
  >
}

export type AdminGlobalSettingsQueryVariables = Exact<{ [key: string]: never }>

export type AdminGlobalSettingsQuery = {
  readonly __typename: 'Query'
  readonly globalSettings: ReadonlyArray<{
    readonly __typename: 'GlobalSettingKV'
    readonly value: string
    readonly name: string
    readonly description: string
    readonly readonly: boolean
    readonly enum?: Maybe<
      ReadonlyArray<{
        readonly __typename: 'SettingEnum'
        readonly display: string
        readonly value: string
      }>
    >
  }>
}

export type AdminSetGlobalSettingMutationVariables = Exact<{
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
}>

export type AdminSetGlobalSettingMutation = {
  readonly __typename: 'Mutation'
  readonly setGlobalSetting: {
    readonly __typename: 'GlobalSettingKV'
    readonly value: string
  }
}

export type AdminActivityGroupsByTopicQueryVariables = Exact<{
  topic: Scalars['GlobalID']
}>

export type AdminActivityGroupsByTopicQuery = {
  readonly __typename: 'Query'
  readonly activityGroupsByTopic: {
    readonly __typename: 'ActivityGroupPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'ActivityGroup'
      readonly id: string
      readonly isPositive: boolean
      readonly activities: {
        readonly __typename: 'ActivityPage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Activity'
          readonly weight: number
          readonly id: string
          readonly description: string
          readonly hidden: boolean
        }>
      }
    }>
  }
}

export type AdminListPoliticiansQueryVariables = Exact<{ [key: string]: never }>

export type AdminListPoliticiansQuery = {
  readonly __typename: 'Query'
  readonly politicians: {
    readonly __typename: 'PoliticianPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Politician'
      readonly id: string
      readonly person: {
        readonly __typename: 'Person'
        readonly name: string
        readonly surname: string
      }
    }>
  }
}

export type AdminLastEvalQueryVariables = Exact<{ [key: string]: never }>

export type AdminLastEvalQuery = {
  readonly __typename: 'Query'
  readonly globalSetting?: Maybe<{
    readonly __typename: 'GlobalSettingKV'
    readonly value: string
  }>
}

export type AdminUpsertActivityGroupGroupFragment = {
  readonly __typename: 'ActivityGroup'
  readonly id: string
  readonly isPositive: boolean
  readonly topic: { readonly __typename: 'Topic'; readonly id: string }
  readonly activities: {
    readonly __typename: 'ActivityPage'
    readonly list: ReadonlyArray<{
      readonly __typename: 'Activity'
      readonly id: string
      readonly weight: number
      readonly description: string
      readonly hidden: boolean
      readonly submandates: {
        readonly __typename: 'SubmandatePage'
        readonly list: ReadonlyArray<{
          readonly __typename: 'Submandate'
          readonly id: string
        }>
      }
    }>
  }
}

export type AdminUpsertActivityGroupByIdQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminUpsertActivityGroupByIdQuery = {
  readonly __typename: 'Query'
  readonly activityGroupById?: Maybe<{
    readonly __typename: 'ActivityGroup'
    readonly id: string
    readonly isPositive: boolean
    readonly topic: { readonly __typename: 'Topic'; readonly id: string }
    readonly activities: {
      readonly __typename: 'ActivityPage'
      readonly list: ReadonlyArray<{
        readonly __typename: 'Activity'
        readonly id: string
        readonly weight: number
        readonly description: string
        readonly hidden: boolean
        readonly submandates: {
          readonly __typename: 'SubmandatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Submandate'
            readonly id: string
          }>
        }
      }>
    }
  }>
}

export type AdminUpsertActivityGroupMutationVariables = Exact<{
  id?: Maybe<Scalars['GlobalID']>
  data: UpsertActivityGroup
}>

export type AdminUpsertActivityGroupMutation = {
  readonly __typename: 'Mutation'
  readonly upsertActivityGroup?: Maybe<{
    readonly __typename: 'ActivityGroup'
    readonly id: string
    readonly isPositive: boolean
    readonly topic: { readonly __typename: 'Topic'; readonly id: string }
    readonly activities: {
      readonly __typename: 'ActivityPage'
      readonly list: ReadonlyArray<{
        readonly __typename: 'Activity'
        readonly id: string
        readonly weight: number
        readonly description: string
        readonly hidden: boolean
        readonly submandates: {
          readonly __typename: 'SubmandatePage'
          readonly list: ReadonlyArray<{
            readonly __typename: 'Submandate'
            readonly id: string
          }>
        }
      }>
    }
  }>
}

export type DeleteActivityGroupMutationVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type DeleteActivityGroupMutation = {
  readonly __typename: 'Mutation'
  readonly deleteActivityGroup: boolean
}

export type AdminPoliticianFragmentFragment = {
  readonly __typename: 'Politician'
  readonly id: string
  readonly statement: string
  readonly partyOverride?: Maybe<string>
  readonly email?: Maybe<string>
  readonly slug: string
  readonly person: {
    readonly __typename: 'Person'
    readonly name: string
    readonly surname: string
    readonly gender: Gender
    readonly image?: Maybe<{
      readonly __typename: 'File'
      readonly id: string
      readonly url: string
    }>
  }
}

export type AdminCreatePoliticianMutationVariables = Exact<{
  input: CreatePoliticianInput
}>

export type AdminCreatePoliticianMutation = {
  readonly __typename: 'Mutation'
  readonly createPolitician?: Maybe<{
    readonly __typename: 'CreatePoliticianPayload'
    readonly politician: {
      readonly __typename: 'Politician'
      readonly id: string
      readonly statement: string
      readonly partyOverride?: Maybe<string>
      readonly email?: Maybe<string>
      readonly slug: string
      readonly person: {
        readonly __typename: 'Person'
        readonly name: string
        readonly surname: string
        readonly gender: Gender
        readonly image?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
          readonly url: string
        }>
      }
    }
  }>
}

export type AdminUpdatePoliticianMutationVariables = Exact<{
  input: UpdatePoliticianInput
}>

export type AdminUpdatePoliticianMutation = {
  readonly __typename: 'Mutation'
  readonly updatePolitician?: Maybe<{
    readonly __typename: 'UpdatePoliticianPayload'
    readonly politician: {
      readonly __typename: 'Politician'
      readonly id: string
      readonly statement: string
      readonly partyOverride?: Maybe<string>
      readonly email?: Maybe<string>
      readonly slug: string
      readonly person: {
        readonly __typename: 'Person'
        readonly name: string
        readonly surname: string
        readonly gender: Gender
        readonly image?: Maybe<{
          readonly __typename: 'File'
          readonly id: string
          readonly url: string
        }>
      }
    }
  }>
}

export type AdminPoliticianQueryVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type AdminPoliticianQuery = {
  readonly __typename: 'Query'
  readonly node?: Maybe<
    | { readonly __typename: 'Mandate' }
    | { readonly __typename: 'PartyMembership' }
    | {
        readonly __typename: 'Politician'
        readonly id: string
        readonly statement: string
        readonly partyOverride?: Maybe<string>
        readonly email?: Maybe<string>
        readonly slug: string
        readonly person: {
          readonly __typename: 'Person'
          readonly name: string
          readonly surname: string
          readonly gender: Gender
          readonly image?: Maybe<{
            readonly __typename: 'File'
            readonly id: string
            readonly url: string
          }>
        }
      }
    | { readonly __typename: 'Topic' }
    | { readonly __typename: 'File' }
    | { readonly __typename: 'Party' }
    | { readonly __typename: 'ReflektorUpdate' }
    | { readonly __typename: 'ReflektorUpdatePolitician' }
    | { readonly __typename: 'ActivityGroup' }
    | { readonly __typename: 'Activity' }
    | { readonly __typename: 'Submandate' }
    | { readonly __typename: 'Poll' }
    | { readonly __typename: 'PollEvaluation' }
    | { readonly __typename: 'ParlamentaryGroup' }
    | { readonly __typename: 'ParlamentaryGroupMembership' }
  >
}

export type DeletePollEvalMutationVariables = Exact<{
  id: Scalars['GlobalID']
}>

export type DeletePollEvalMutation = {
  readonly __typename: 'Mutation'
  readonly deletePollEval: boolean
}

export const AdminSubmandatePickerSubmandateFragmentDoc = gql`
  fragment AdminSubmandatePickerSubmandate on Submandate {
    __typename
    id
    start
    end
    mandate {
      id
      chamber
      region {
        id
        name
      }
      politician {
        id
        person {
          id
          name
          surname
        }
      }
    }
  }
`
export const PartyFragmentDoc = gql`
  fragment party on Party {
    id
    name
    abbreviation
    displayText(editable: true)
    color
    contrastingColor
    availableInReflektor
  }
`
export const PollEvaluationFragmentDoc = gql`
  fragment pollEvaluation on PollEvaluation {
    id
    poll {
      id
    }
    topic {
      id
    }
    weight
    description(editable: true)
    standpoint
    positive
    committeePoll
  }
`
export const AdminReflektorUpdateFragmentDoc = gql`
  fragment AdminReflektorUpdate on ReflektorUpdate {
    id
    title
    titleGoodPoliticians
    titleNotGoodPoliticians
    linkHref(editable: true)
    linkLabel
    leadImage {
      id
      url
    }
    publishDate
    type
    endDate
    politicians {
      list {
        id
        politician {
          id
          person {
            id
            name(editable: true)
          }
        }
        good
        isAboveFold
      }
    }
    summary(editable: true)
    text(editable: true)
    topic {
      id
    }
    poll {
      id
    }
    onlyCorrespondingEmoji
    archive
  }
`
export const TopicFragmentDoc = gql`
  fragment topic on Topic {
    id
    name
    icon {
      id
      url
    }
    description(editable: true)
    shareLink(editable: true)
    availableInReflektor
    availableInEvaluation
  }
`
export const AdminUpsertActivityGroupGroupFragmentDoc = gql`
  fragment AdminUpsertActivityGroupGroup on ActivityGroup {
    id
    isPositive
    topic {
      id
    }
    activities {
      list {
        id
        weight
        description
        hidden
        submandates {
          list {
            id
          }
        }
      }
    }
  }
`
export const AdminPoliticianFragmentFragmentDoc = gql`
  fragment AdminPoliticianFragment on Politician {
    id
    person {
      name
      surname
      gender
      image {
        id
        url
      }
    }
    statement
    partyOverride
    email
    slug
  }
`
export const AdminSubmandatePickerDocument = gql`
  query AdminSubmandatePicker($date: DateTime!) {
    submandatesByDate(date: $date) {
      count
      list {
        ...AdminSubmandatePickerSubmandate
      }
    }
  }
  ${AdminSubmandatePickerSubmandateFragmentDoc}
`

/**
 * __useAdminSubmandatePickerQuery__
 *
 * To run a query within a React component, call `useAdminSubmandatePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSubmandatePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSubmandatePickerQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAdminSubmandatePickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminSubmandatePickerQuery,
    AdminSubmandatePickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminSubmandatePickerQuery,
    AdminSubmandatePickerQueryVariables
  >(AdminSubmandatePickerDocument, options)
}
export function useAdminSubmandatePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSubmandatePickerQuery,
    AdminSubmandatePickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminSubmandatePickerQuery,
    AdminSubmandatePickerQueryVariables
  >(AdminSubmandatePickerDocument, options)
}
export type AdminSubmandatePickerQueryHookResult = ReturnType<
  typeof useAdminSubmandatePickerQuery
>
export type AdminSubmandatePickerLazyQueryHookResult = ReturnType<
  typeof useAdminSubmandatePickerLazyQuery
>
export type AdminSubmandatePickerQueryResult = Apollo.QueryResult<
  AdminSubmandatePickerQuery,
  AdminSubmandatePickerQueryVariables
>
export const AdminSubmandatePickerMissingDocument = gql`
  query AdminSubmandatePickerMissing($ids: [GlobalID!]!) {
    submandatesByIds(ids: $ids) {
      ...AdminSubmandatePickerSubmandate
    }
  }
  ${AdminSubmandatePickerSubmandateFragmentDoc}
`

/**
 * __useAdminSubmandatePickerMissingQuery__
 *
 * To run a query within a React component, call `useAdminSubmandatePickerMissingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSubmandatePickerMissingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSubmandatePickerMissingQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminSubmandatePickerMissingQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminSubmandatePickerMissingQuery,
    AdminSubmandatePickerMissingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminSubmandatePickerMissingQuery,
    AdminSubmandatePickerMissingQueryVariables
  >(AdminSubmandatePickerMissingDocument, options)
}
export function useAdminSubmandatePickerMissingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSubmandatePickerMissingQuery,
    AdminSubmandatePickerMissingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminSubmandatePickerMissingQuery,
    AdminSubmandatePickerMissingQueryVariables
  >(AdminSubmandatePickerMissingDocument, options)
}
export type AdminSubmandatePickerMissingQueryHookResult = ReturnType<
  typeof useAdminSubmandatePickerMissingQuery
>
export type AdminSubmandatePickerMissingLazyQueryHookResult = ReturnType<
  typeof useAdminSubmandatePickerMissingLazyQuery
>
export type AdminSubmandatePickerMissingQueryResult = Apollo.QueryResult<
  AdminSubmandatePickerMissingQuery,
  AdminSubmandatePickerMissingQueryVariables
>
export const AdminRemoveRoleDocument = gql`
  mutation adminRemoveRole($userId: GlobalID!, $role: String!) {
    removeRole(userId: $userId, role: $role)
  }
`
export type AdminRemoveRoleMutationFn = Apollo.MutationFunction<
  AdminRemoveRoleMutation,
  AdminRemoveRoleMutationVariables
>

/**
 * __useAdminRemoveRoleMutation__
 *
 * To run a mutation, you first call `useAdminRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveRoleMutation, { data, loading, error }] = useAdminRemoveRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAdminRemoveRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveRoleMutation,
    AdminRemoveRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminRemoveRoleMutation,
    AdminRemoveRoleMutationVariables
  >(AdminRemoveRoleDocument, options)
}
export type AdminRemoveRoleMutationHookResult = ReturnType<
  typeof useAdminRemoveRoleMutation
>
export type AdminRemoveRoleMutationResult =
  Apollo.MutationResult<AdminRemoveRoleMutation>
export type AdminRemoveRoleMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveRoleMutation,
  AdminRemoveRoleMutationVariables
>
export const AdminAddRoleDocument = gql`
  mutation adminAddRole($userId: GlobalID!, $role: String!) {
    addRole(userId: $userId, role: $role)
  }
`
export type AdminAddRoleMutationFn = Apollo.MutationFunction<
  AdminAddRoleMutation,
  AdminAddRoleMutationVariables
>

/**
 * __useAdminAddRoleMutation__
 *
 * To run a mutation, you first call `useAdminAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddRoleMutation, { data, loading, error }] = useAdminAddRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAdminAddRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminAddRoleMutation,
    AdminAddRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminAddRoleMutation,
    AdminAddRoleMutationVariables
  >(AdminAddRoleDocument, options)
}
export type AdminAddRoleMutationHookResult = ReturnType<
  typeof useAdminAddRoleMutation
>
export type AdminAddRoleMutationResult =
  Apollo.MutationResult<AdminAddRoleMutation>
export type AdminAddRoleMutationOptions = Apollo.BaseMutationOptions<
  AdminAddRoleMutation,
  AdminAddRoleMutationVariables
>
export const AdminImportCommitteeVoteCreateDocument = gql`
  mutation AdminImportCommitteeVoteCreate($data: CommitteePollEvalInput!) {
    createCommitteePollEval(data: $data)
  }
`
export type AdminImportCommitteeVoteCreateMutationFn = Apollo.MutationFunction<
  AdminImportCommitteeVoteCreateMutation,
  AdminImportCommitteeVoteCreateMutationVariables
>

/**
 * __useAdminImportCommitteeVoteCreateMutation__
 *
 * To run a mutation, you first call `useAdminImportCommitteeVoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminImportCommitteeVoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminImportCommitteeVoteCreateMutation, { data, loading, error }] = useAdminImportCommitteeVoteCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminImportCommitteeVoteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminImportCommitteeVoteCreateMutation,
    AdminImportCommitteeVoteCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminImportCommitteeVoteCreateMutation,
    AdminImportCommitteeVoteCreateMutationVariables
  >(AdminImportCommitteeVoteCreateDocument, options)
}
export type AdminImportCommitteeVoteCreateMutationHookResult = ReturnType<
  typeof useAdminImportCommitteeVoteCreateMutation
>
export type AdminImportCommitteeVoteCreateMutationResult =
  Apollo.MutationResult<AdminImportCommitteeVoteCreateMutation>
export type AdminImportCommitteeVoteCreateMutationOptions =
  Apollo.BaseMutationOptions<
    AdminImportCommitteeVoteCreateMutation,
    AdminImportCommitteeVoteCreateMutationVariables
  >
export const AdminImportActivityPoliticiansDocument = gql`
  query AdminImportActivityPoliticians {
    politicians(limit: -1) {
      list {
        id
        person {
          name
          surname
        }
        mandates {
          list {
            submandates {
              list {
                id
                start
                end
                electionPeriod {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useAdminImportActivityPoliticiansQuery__
 *
 * To run a query within a React component, call `useAdminImportActivityPoliticiansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminImportActivityPoliticiansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminImportActivityPoliticiansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminImportActivityPoliticiansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminImportActivityPoliticiansQuery,
    AdminImportActivityPoliticiansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminImportActivityPoliticiansQuery,
    AdminImportActivityPoliticiansQueryVariables
  >(AdminImportActivityPoliticiansDocument, options)
}
export function useAdminImportActivityPoliticiansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminImportActivityPoliticiansQuery,
    AdminImportActivityPoliticiansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminImportActivityPoliticiansQuery,
    AdminImportActivityPoliticiansQueryVariables
  >(AdminImportActivityPoliticiansDocument, options)
}
export type AdminImportActivityPoliticiansQueryHookResult = ReturnType<
  typeof useAdminImportActivityPoliticiansQuery
>
export type AdminImportActivityPoliticiansLazyQueryHookResult = ReturnType<
  typeof useAdminImportActivityPoliticiansLazyQuery
>
export type AdminImportActivityPoliticiansQueryResult = Apollo.QueryResult<
  AdminImportActivityPoliticiansQuery,
  AdminImportActivityPoliticiansQueryVariables
>
export const AdminSendTestNotificationDocument = gql`
  mutation adminSendTestNotification($title: String!, $body: String!) {
    sendTestNotification(title: $title, body: $body)
  }
`
export type AdminSendTestNotificationMutationFn = Apollo.MutationFunction<
  AdminSendTestNotificationMutation,
  AdminSendTestNotificationMutationVariables
>

/**
 * __useAdminSendTestNotificationMutation__
 *
 * To run a mutation, you first call `useAdminSendTestNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSendTestNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSendTestNotificationMutation, { data, loading, error }] = useAdminSendTestNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAdminSendTestNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSendTestNotificationMutation,
    AdminSendTestNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminSendTestNotificationMutation,
    AdminSendTestNotificationMutationVariables
  >(AdminSendTestNotificationDocument, options)
}
export type AdminSendTestNotificationMutationHookResult = ReturnType<
  typeof useAdminSendTestNotificationMutation
>
export type AdminSendTestNotificationMutationResult =
  Apollo.MutationResult<AdminSendTestNotificationMutation>
export type AdminSendTestNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    AdminSendTestNotificationMutation,
    AdminSendTestNotificationMutationVariables
  >
export const AdminReflektorUpdateSendTestNotificationDocument = gql`
  mutation adminReflektorUpdateSendTestNotification($id: GlobalID!) {
    reflektorUpdateSendTestNotification(id: $id)
  }
`
export type AdminReflektorUpdateSendTestNotificationMutationFn =
  Apollo.MutationFunction<
    AdminReflektorUpdateSendTestNotificationMutation,
    AdminReflektorUpdateSendTestNotificationMutationVariables
  >

/**
 * __useAdminReflektorUpdateSendTestNotificationMutation__
 *
 * To run a mutation, you first call `useAdminReflektorUpdateSendTestNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminReflektorUpdateSendTestNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminReflektorUpdateSendTestNotificationMutation, { data, loading, error }] = useAdminReflektorUpdateSendTestNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminReflektorUpdateSendTestNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminReflektorUpdateSendTestNotificationMutation,
    AdminReflektorUpdateSendTestNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminReflektorUpdateSendTestNotificationMutation,
    AdminReflektorUpdateSendTestNotificationMutationVariables
  >(AdminReflektorUpdateSendTestNotificationDocument, options)
}
export type AdminReflektorUpdateSendTestNotificationMutationHookResult =
  ReturnType<typeof useAdminReflektorUpdateSendTestNotificationMutation>
export type AdminReflektorUpdateSendTestNotificationMutationResult =
  Apollo.MutationResult<AdminReflektorUpdateSendTestNotificationMutation>
export type AdminReflektorUpdateSendTestNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    AdminReflektorUpdateSendTestNotificationMutation,
    AdminReflektorUpdateSendTestNotificationMutationVariables
  >
export const AdminPartyListDocument = gql`
  query adminPartyList {
    parties {
      list {
        id
        abbreviation
        name
        displayText(editable: true)
      }
    }
  }
`

/**
 * __useAdminPartyListQuery__
 *
 * To run a query within a React component, call `useAdminPartyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPartyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPartyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPartyListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminPartyListQuery,
    AdminPartyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPartyListQuery, AdminPartyListQueryVariables>(
    AdminPartyListDocument,
    options,
  )
}
export function useAdminPartyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPartyListQuery,
    AdminPartyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminPartyListQuery, AdminPartyListQueryVariables>(
    AdminPartyListDocument,
    options,
  )
}
export type AdminPartyListQueryHookResult = ReturnType<
  typeof useAdminPartyListQuery
>
export type AdminPartyListLazyQueryHookResult = ReturnType<
  typeof useAdminPartyListLazyQuery
>
export type AdminPartyListQueryResult = Apollo.QueryResult<
  AdminPartyListQuery,
  AdminPartyListQueryVariables
>
export const AdminPartyDocument = gql`
  query adminParty($id: GlobalID!) {
    node(id: $id) {
      ...party
    }
  }
  ${PartyFragmentDoc}
`

/**
 * __useAdminPartyQuery__
 *
 * To run a query within a React component, call `useAdminPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPartyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPartyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPartyQuery,
    AdminPartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPartyQuery, AdminPartyQueryVariables>(
    AdminPartyDocument,
    options,
  )
}
export function useAdminPartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPartyQuery,
    AdminPartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminPartyQuery, AdminPartyQueryVariables>(
    AdminPartyDocument,
    options,
  )
}
export type AdminPartyQueryHookResult = ReturnType<typeof useAdminPartyQuery>
export type AdminPartyLazyQueryHookResult = ReturnType<
  typeof useAdminPartyLazyQuery
>
export type AdminPartyQueryResult = Apollo.QueryResult<
  AdminPartyQuery,
  AdminPartyQueryVariables
>
export const AdminUpdatePartyDocument = gql`
  mutation adminUpdateParty($id: GlobalID!, $data: PartyPatchInput!) {
    updateParty(id: $id, data: $data) {
      party {
        ...party
      }
    }
  }
  ${PartyFragmentDoc}
`
export type AdminUpdatePartyMutationFn = Apollo.MutationFunction<
  AdminUpdatePartyMutation,
  AdminUpdatePartyMutationVariables
>

/**
 * __useAdminUpdatePartyMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePartyMutation, { data, loading, error }] = useAdminUpdatePartyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdatePartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdatePartyMutation,
    AdminUpdatePartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdatePartyMutation,
    AdminUpdatePartyMutationVariables
  >(AdminUpdatePartyDocument, options)
}
export type AdminUpdatePartyMutationHookResult = ReturnType<
  typeof useAdminUpdatePartyMutation
>
export type AdminUpdatePartyMutationResult =
  Apollo.MutationResult<AdminUpdatePartyMutation>
export type AdminUpdatePartyMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdatePartyMutation,
  AdminUpdatePartyMutationVariables
>
export const AdminPoliticianListDocument = gql`
  query adminPoliticianList {
    politicians(limit: -1) {
      list {
        id
        person {
          id
          name
          surname
        }
        email
      }
    }
  }
`

/**
 * __useAdminPoliticianListQuery__
 *
 * To run a query within a React component, call `useAdminPoliticianListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPoliticianListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPoliticianListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPoliticianListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminPoliticianListQuery,
    AdminPoliticianListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminPoliticianListQuery,
    AdminPoliticianListQueryVariables
  >(AdminPoliticianListDocument, options)
}
export function useAdminPoliticianListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPoliticianListQuery,
    AdminPoliticianListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminPoliticianListQuery,
    AdminPoliticianListQueryVariables
  >(AdminPoliticianListDocument, options)
}
export type AdminPoliticianListQueryHookResult = ReturnType<
  typeof useAdminPoliticianListQuery
>
export type AdminPoliticianListLazyQueryHookResult = ReturnType<
  typeof useAdminPoliticianListLazyQuery
>
export type AdminPoliticianListQueryResult = Apollo.QueryResult<
  AdminPoliticianListQuery,
  AdminPoliticianListQueryVariables
>
export const AdminPollEvaluationListDocument = gql`
  query adminPollEvaluationList($topic: GlobalID!) {
    pollEvaluations(filter: { topic: $topic }) {
      list {
        id
        committeePoll
        poll {
          id
          date
        }
        description
      }
    }
  }
`

/**
 * __useAdminPollEvaluationListQuery__
 *
 * To run a query within a React component, call `useAdminPollEvaluationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPollEvaluationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPollEvaluationListQuery({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useAdminPollEvaluationListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPollEvaluationListQuery,
    AdminPollEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminPollEvaluationListQuery,
    AdminPollEvaluationListQueryVariables
  >(AdminPollEvaluationListDocument, options)
}
export function useAdminPollEvaluationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPollEvaluationListQuery,
    AdminPollEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminPollEvaluationListQuery,
    AdminPollEvaluationListQueryVariables
  >(AdminPollEvaluationListDocument, options)
}
export type AdminPollEvaluationListQueryHookResult = ReturnType<
  typeof useAdminPollEvaluationListQuery
>
export type AdminPollEvaluationListLazyQueryHookResult = ReturnType<
  typeof useAdminPollEvaluationListLazyQuery
>
export type AdminPollEvaluationListQueryResult = Apollo.QueryResult<
  AdminPollEvaluationListQuery,
  AdminPollEvaluationListQueryVariables
>
export const AdminUpsertPollEvaluationDocument = gql`
  mutation adminUpsertPollEvaluation(
    $id: GlobalID
    $data: PollEvaluationPatchInput!
  ) {
    upsertPollEvaluation(id: $id, data: $data) {
      pollEvaluation {
        ...pollEvaluation
      }
    }
  }
  ${PollEvaluationFragmentDoc}
`
export type AdminUpsertPollEvaluationMutationFn = Apollo.MutationFunction<
  AdminUpsertPollEvaluationMutation,
  AdminUpsertPollEvaluationMutationVariables
>

/**
 * __useAdminUpsertPollEvaluationMutation__
 *
 * To run a mutation, you first call `useAdminUpsertPollEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertPollEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpsertPollEvaluationMutation, { data, loading, error }] = useAdminUpsertPollEvaluationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpsertPollEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpsertPollEvaluationMutation,
    AdminUpsertPollEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpsertPollEvaluationMutation,
    AdminUpsertPollEvaluationMutationVariables
  >(AdminUpsertPollEvaluationDocument, options)
}
export type AdminUpsertPollEvaluationMutationHookResult = ReturnType<
  typeof useAdminUpsertPollEvaluationMutation
>
export type AdminUpsertPollEvaluationMutationResult =
  Apollo.MutationResult<AdminUpsertPollEvaluationMutation>
export type AdminUpsertPollEvaluationMutationOptions =
  Apollo.BaseMutationOptions<
    AdminUpsertPollEvaluationMutation,
    AdminUpsertPollEvaluationMutationVariables
  >
export const AdminPollEvaluationDocument = gql`
  query adminPollEvaluation($id: GlobalID!) {
    node(id: $id) {
      ...pollEvaluation
    }
  }
  ${PollEvaluationFragmentDoc}
`

/**
 * __useAdminPollEvaluationQuery__
 *
 * To run a query within a React component, call `useAdminPollEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPollEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPollEvaluationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPollEvaluationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPollEvaluationQuery,
    AdminPollEvaluationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminPollEvaluationQuery,
    AdminPollEvaluationQueryVariables
  >(AdminPollEvaluationDocument, options)
}
export function useAdminPollEvaluationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPollEvaluationQuery,
    AdminPollEvaluationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminPollEvaluationQuery,
    AdminPollEvaluationQueryVariables
  >(AdminPollEvaluationDocument, options)
}
export type AdminPollEvaluationQueryHookResult = ReturnType<
  typeof useAdminPollEvaluationQuery
>
export type AdminPollEvaluationLazyQueryHookResult = ReturnType<
  typeof useAdminPollEvaluationLazyQuery
>
export type AdminPollEvaluationQueryResult = Apollo.QueryResult<
  AdminPollEvaluationQuery,
  AdminPollEvaluationQueryVariables
>
export const AdminGetReflektorUpdateDocument = gql`
  query adminGetReflektorUpdate($id: GlobalID!) {
    node(id: $id) {
      ...AdminReflektorUpdate
    }
  }
  ${AdminReflektorUpdateFragmentDoc}
`

/**
 * __useAdminGetReflektorUpdateQuery__
 *
 * To run a query within a React component, call `useAdminGetReflektorUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReflektorUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReflektorUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetReflektorUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetReflektorUpdateQuery,
    AdminGetReflektorUpdateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminGetReflektorUpdateQuery,
    AdminGetReflektorUpdateQueryVariables
  >(AdminGetReflektorUpdateDocument, options)
}
export function useAdminGetReflektorUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetReflektorUpdateQuery,
    AdminGetReflektorUpdateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminGetReflektorUpdateQuery,
    AdminGetReflektorUpdateQueryVariables
  >(AdminGetReflektorUpdateDocument, options)
}
export type AdminGetReflektorUpdateQueryHookResult = ReturnType<
  typeof useAdminGetReflektorUpdateQuery
>
export type AdminGetReflektorUpdateLazyQueryHookResult = ReturnType<
  typeof useAdminGetReflektorUpdateLazyQuery
>
export type AdminGetReflektorUpdateQueryResult = Apollo.QueryResult<
  AdminGetReflektorUpdateQuery,
  AdminGetReflektorUpdateQueryVariables
>
export const AdminReflektorUpsertUpdateDocument = gql`
  mutation adminReflektorUpsertUpdate(
    $id: GlobalID
    $data: ReflektorUpdatePatchInput!
  ) {
    reflektorUpsertUpdate(id: $id, data: $data) {
      update {
        ...AdminReflektorUpdate
      }
    }
  }
  ${AdminReflektorUpdateFragmentDoc}
`
export type AdminReflektorUpsertUpdateMutationFn = Apollo.MutationFunction<
  AdminReflektorUpsertUpdateMutation,
  AdminReflektorUpsertUpdateMutationVariables
>

/**
 * __useAdminReflektorUpsertUpdateMutation__
 *
 * To run a mutation, you first call `useAdminReflektorUpsertUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminReflektorUpsertUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminReflektorUpsertUpdateMutation, { data, loading, error }] = useAdminReflektorUpsertUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminReflektorUpsertUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminReflektorUpsertUpdateMutation,
    AdminReflektorUpsertUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminReflektorUpsertUpdateMutation,
    AdminReflektorUpsertUpdateMutationVariables
  >(AdminReflektorUpsertUpdateDocument, options)
}
export type AdminReflektorUpsertUpdateMutationHookResult = ReturnType<
  typeof useAdminReflektorUpsertUpdateMutation
>
export type AdminReflektorUpsertUpdateMutationResult =
  Apollo.MutationResult<AdminReflektorUpsertUpdateMutation>
export type AdminReflektorUpsertUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    AdminReflektorUpsertUpdateMutation,
    AdminReflektorUpsertUpdateMutationVariables
  >
export const AdminReflektorUpdateListDocument = gql`
  query adminReflektorUpdateList {
    authorities {
      list {
        topics {
          list {
            reflektorUpdates(onlyPublished: false) {
              list {
                id
                title
                archive
                publishDate
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useAdminReflektorUpdateListQuery__
 *
 * To run a query within a React component, call `useAdminReflektorUpdateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReflektorUpdateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReflektorUpdateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminReflektorUpdateListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminReflektorUpdateListQuery,
    AdminReflektorUpdateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminReflektorUpdateListQuery,
    AdminReflektorUpdateListQueryVariables
  >(AdminReflektorUpdateListDocument, options)
}
export function useAdminReflektorUpdateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminReflektorUpdateListQuery,
    AdminReflektorUpdateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminReflektorUpdateListQuery,
    AdminReflektorUpdateListQueryVariables
  >(AdminReflektorUpdateListDocument, options)
}
export type AdminReflektorUpdateListQueryHookResult = ReturnType<
  typeof useAdminReflektorUpdateListQuery
>
export type AdminReflektorUpdateListLazyQueryHookResult = ReturnType<
  typeof useAdminReflektorUpdateListLazyQuery
>
export type AdminReflektorUpdateListQueryResult = Apollo.QueryResult<
  AdminReflektorUpdateListQuery,
  AdminReflektorUpdateListQueryVariables
>
export const AdminPollsAtDateDocument = gql`
  query adminPollsAtDate($date: DateOnly!) {
    polls(filter: { date: $date }) {
      list {
        id
        description
        context
        importId
        url
        electionPeriod {
          id
          isSenate
        }
      }
    }
  }
`

/**
 * __useAdminPollsAtDateQuery__
 *
 * To run a query within a React component, call `useAdminPollsAtDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPollsAtDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPollsAtDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAdminPollsAtDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPollsAtDateQuery,
    AdminPollsAtDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPollsAtDateQuery, AdminPollsAtDateQueryVariables>(
    AdminPollsAtDateDocument,
    options,
  )
}
export function useAdminPollsAtDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPollsAtDateQuery,
    AdminPollsAtDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminPollsAtDateQuery,
    AdminPollsAtDateQueryVariables
  >(AdminPollsAtDateDocument, options)
}
export type AdminPollsAtDateQueryHookResult = ReturnType<
  typeof useAdminPollsAtDateQuery
>
export type AdminPollsAtDateLazyQueryHookResult = ReturnType<
  typeof useAdminPollsAtDateLazyQuery
>
export type AdminPollsAtDateQueryResult = Apollo.QueryResult<
  AdminPollsAtDateQuery,
  AdminPollsAtDateQueryVariables
>
export const AdminPollByIdDocument = gql`
  query adminPollById($id: GlobalID!) {
    node(id: $id) {
      ... on Poll {
        id
        votes {
          list {
            id
            submandate {
              mandate {
                politician {
                  id
                  person {
                    id
                    name
                    surname
                    gender
                  }
                }
              }
            }
            vote
          }
        }
      }
    }
  }
`

/**
 * __useAdminPollByIdQuery__
 *
 * To run a query within a React component, call `useAdminPollByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPollByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPollByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPollByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPollByIdQuery,
    AdminPollByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPollByIdQuery, AdminPollByIdQueryVariables>(
    AdminPollByIdDocument,
    options,
  )
}
export function useAdminPollByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPollByIdQuery,
    AdminPollByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminPollByIdQuery, AdminPollByIdQueryVariables>(
    AdminPollByIdDocument,
    options,
  )
}
export type AdminPollByIdQueryHookResult = ReturnType<
  typeof useAdminPollByIdQuery
>
export type AdminPollByIdLazyQueryHookResult = ReturnType<
  typeof useAdminPollByIdLazyQuery
>
export type AdminPollByIdQueryResult = Apollo.QueryResult<
  AdminPollByIdQuery,
  AdminPollByIdQueryVariables
>
export const AdminPollDatesDocument = gql`
  query adminPollDates {
    pollDates {
      date
      count
    }
  }
`

/**
 * __useAdminPollDatesQuery__
 *
 * To run a query within a React component, call `useAdminPollDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPollDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPollDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPollDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminPollDatesQuery,
    AdminPollDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPollDatesQuery, AdminPollDatesQueryVariables>(
    AdminPollDatesDocument,
    options,
  )
}
export function useAdminPollDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPollDatesQuery,
    AdminPollDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminPollDatesQuery, AdminPollDatesQueryVariables>(
    AdminPollDatesDocument,
    options,
  )
}
export type AdminPollDatesQueryHookResult = ReturnType<
  typeof useAdminPollDatesQuery
>
export type AdminPollDatesLazyQueryHookResult = ReturnType<
  typeof useAdminPollDatesLazyQuery
>
export type AdminPollDatesQueryResult = Apollo.QueryResult<
  AdminPollDatesQuery,
  AdminPollDatesQueryVariables
>
export const AdminTopicListDocument = gql`
  query adminTopicList($only: TopicFilter) {
    authorities {
      list {
        topics: filteredTopics(only: $only) {
          list {
            id
            name
            availableInReflektor
            availableInEvaluation
          }
        }
      }
    }
  }
`

/**
 * __useAdminTopicListQuery__
 *
 * To run a query within a React component, call `useAdminTopicListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTopicListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTopicListQuery({
 *   variables: {
 *      only: // value for 'only'
 *   },
 * });
 */
export function useAdminTopicListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminTopicListQuery,
    AdminTopicListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminTopicListQuery, AdminTopicListQueryVariables>(
    AdminTopicListDocument,
    options,
  )
}
export function useAdminTopicListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminTopicListQuery,
    AdminTopicListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminTopicListQuery, AdminTopicListQueryVariables>(
    AdminTopicListDocument,
    options,
  )
}
export type AdminTopicListQueryHookResult = ReturnType<
  typeof useAdminTopicListQuery
>
export type AdminTopicListLazyQueryHookResult = ReturnType<
  typeof useAdminTopicListLazyQuery
>
export type AdminTopicListQueryResult = Apollo.QueryResult<
  AdminTopicListQuery,
  AdminTopicListQueryVariables
>
export const AdminTopicDocument = gql`
  query adminTopic($id: GlobalID!) {
    node(id: $id) {
      ...topic
    }
  }
  ${TopicFragmentDoc}
`

/**
 * __useAdminTopicQuery__
 *
 * To run a query within a React component, call `useAdminTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminTopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminTopicQuery,
    AdminTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminTopicQuery, AdminTopicQueryVariables>(
    AdminTopicDocument,
    options,
  )
}
export function useAdminTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminTopicQuery,
    AdminTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminTopicQuery, AdminTopicQueryVariables>(
    AdminTopicDocument,
    options,
  )
}
export type AdminTopicQueryHookResult = ReturnType<typeof useAdminTopicQuery>
export type AdminTopicLazyQueryHookResult = ReturnType<
  typeof useAdminTopicLazyQuery
>
export type AdminTopicQueryResult = Apollo.QueryResult<
  AdminTopicQuery,
  AdminTopicQueryVariables
>
export const AdminUpsertTopicDocument = gql`
  mutation adminUpsertTopic($id: GlobalID, $data: TopicPatchInput!) {
    upsertTopic(id: $id, data: $data) {
      topic {
        ...topic
      }
    }
  }
  ${TopicFragmentDoc}
`
export type AdminUpsertTopicMutationFn = Apollo.MutationFunction<
  AdminUpsertTopicMutation,
  AdminUpsertTopicMutationVariables
>

/**
 * __useAdminUpsertTopicMutation__
 *
 * To run a mutation, you first call `useAdminUpsertTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpsertTopicMutation, { data, loading, error }] = useAdminUpsertTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpsertTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpsertTopicMutation,
    AdminUpsertTopicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpsertTopicMutation,
    AdminUpsertTopicMutationVariables
  >(AdminUpsertTopicDocument, options)
}
export type AdminUpsertTopicMutationHookResult = ReturnType<
  typeof useAdminUpsertTopicMutation
>
export type AdminUpsertTopicMutationResult =
  Apollo.MutationResult<AdminUpsertTopicMutation>
export type AdminUpsertTopicMutationOptions = Apollo.BaseMutationOptions<
  AdminUpsertTopicMutation,
  AdminUpsertTopicMutationVariables
>
export const AdminMeDocument = gql`
  query adminMe {
    me {
      id
      roles
    }
  }
`

/**
 * __useAdminMeQuery__
 *
 * To run a query within a React component, call `useAdminMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminMeQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminMeQuery, AdminMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminMeQuery, AdminMeQueryVariables>(
    AdminMeDocument,
    options,
  )
}
export function useAdminMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminMeQuery,
    AdminMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminMeQuery, AdminMeQueryVariables>(
    AdminMeDocument,
    options,
  )
}
export type AdminMeQueryHookResult = ReturnType<typeof useAdminMeQuery>
export type AdminMeLazyQueryHookResult = ReturnType<typeof useAdminMeLazyQuery>
export type AdminMeQueryResult = Apollo.QueryResult<
  AdminMeQuery,
  AdminMeQueryVariables
>
export const AdminLoginDocument = gql`
  mutation adminLogin($input: LoginInput!) {
    login(input: $input) {
      user {
        id
      }
    }
  }
`
export type AdminLoginMutationFn = Apollo.MutationFunction<
  AdminLoginMutation,
  AdminLoginMutationVariables
>

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminLoginMutation,
    AdminLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(
    AdminLoginDocument,
    options,
  )
}
export type AdminLoginMutationHookResult = ReturnType<
  typeof useAdminLoginMutation
>
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<
  AdminLoginMutation,
  AdminLoginMutationVariables
>
export const AdminRegisterDocument = gql`
  mutation adminRegister($input: RegisterInput!) {
    register(input: $input) {
      user {
        id
      }
    }
  }
`
export type AdminRegisterMutationFn = Apollo.MutationFunction<
  AdminRegisterMutation,
  AdminRegisterMutationVariables
>

/**
 * __useAdminRegisterMutation__
 *
 * To run a mutation, you first call `useAdminRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRegisterMutation, { data, loading, error }] = useAdminRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRegisterMutation,
    AdminRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminRegisterMutation,
    AdminRegisterMutationVariables
  >(AdminRegisterDocument, options)
}
export type AdminRegisterMutationHookResult = ReturnType<
  typeof useAdminRegisterMutation
>
export type AdminRegisterMutationResult =
  Apollo.MutationResult<AdminRegisterMutation>
export type AdminRegisterMutationOptions = Apollo.BaseMutationOptions<
  AdminRegisterMutation,
  AdminRegisterMutationVariables
>
export const AdminLogoutDocument = gql`
  mutation adminLogout {
    logout
  }
`
export type AdminLogoutMutationFn = Apollo.MutationFunction<
  AdminLogoutMutation,
  AdminLogoutMutationVariables
>

/**
 * __useAdminLogoutMutation__
 *
 * To run a mutation, you first call `useAdminLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogoutMutation, { data, loading, error }] = useAdminLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminLogoutMutation,
    AdminLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdminLogoutMutation, AdminLogoutMutationVariables>(
    AdminLogoutDocument,
    options,
  )
}
export type AdminLogoutMutationHookResult = ReturnType<
  typeof useAdminLogoutMutation
>
export type AdminLogoutMutationResult =
  Apollo.MutationResult<AdminLogoutMutation>
export type AdminLogoutMutationOptions = Apollo.BaseMutationOptions<
  AdminLogoutMutation,
  AdminLogoutMutationVariables
>
export const AdminUserListDocument = gql`
  query adminUserList {
    userList: users {
      count
      users: list {
        id
        roles
        emails(onlyPrimary: true) {
          value
          verified
        }
      }
    }
  }
`

/**
 * __useAdminUserListQuery__
 *
 * To run a query within a React component, call `useAdminUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminUserListQuery,
    AdminUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminUserListQuery, AdminUserListQueryVariables>(
    AdminUserListDocument,
    options,
  )
}
export function useAdminUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminUserListQuery,
    AdminUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminUserListQuery, AdminUserListQueryVariables>(
    AdminUserListDocument,
    options,
  )
}
export type AdminUserListQueryHookResult = ReturnType<
  typeof useAdminUserListQuery
>
export type AdminUserListLazyQueryHookResult = ReturnType<
  typeof useAdminUserListLazyQuery
>
export type AdminUserListQueryResult = Apollo.QueryResult<
  AdminUserListQuery,
  AdminUserListQueryVariables
>
export const AdminUserDeleteDocument = gql`
  mutation adminUserDelete($id: GlobalID!) {
    userDelete(id: $id)
  }
`
export type AdminUserDeleteMutationFn = Apollo.MutationFunction<
  AdminUserDeleteMutation,
  AdminUserDeleteMutationVariables
>

/**
 * __useAdminUserDeleteMutation__
 *
 * To run a mutation, you first call `useAdminUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUserDeleteMutation, { data, loading, error }] = useAdminUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUserDeleteMutation,
    AdminUserDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUserDeleteMutation,
    AdminUserDeleteMutationVariables
  >(AdminUserDeleteDocument, options)
}
export type AdminUserDeleteMutationHookResult = ReturnType<
  typeof useAdminUserDeleteMutation
>
export type AdminUserDeleteMutationResult =
  Apollo.MutationResult<AdminUserDeleteMutation>
export type AdminUserDeleteMutationOptions = Apollo.BaseMutationOptions<
  AdminUserDeleteMutation,
  AdminUserDeleteMutationVariables
>
export const AdminApiTokensDocument = gql`
  query adminApiTokens {
    me {
      id
      apiTokens {
        id
        name
        expiration
      }
    }
  }
`

/**
 * __useAdminApiTokensQuery__
 *
 * To run a query within a React component, call `useAdminApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminApiTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminApiTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminApiTokensQuery,
    AdminApiTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminApiTokensQuery, AdminApiTokensQueryVariables>(
    AdminApiTokensDocument,
    options,
  )
}
export function useAdminApiTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminApiTokensQuery,
    AdminApiTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminApiTokensQuery, AdminApiTokensQueryVariables>(
    AdminApiTokensDocument,
    options,
  )
}
export type AdminApiTokensQueryHookResult = ReturnType<
  typeof useAdminApiTokensQuery
>
export type AdminApiTokensLazyQueryHookResult = ReturnType<
  typeof useAdminApiTokensLazyQuery
>
export type AdminApiTokensQueryResult = Apollo.QueryResult<
  AdminApiTokensQuery,
  AdminApiTokensQueryVariables
>
export const AdminGenerateApiTokenDocument = gql`
  mutation adminGenerateApiToken($name: String!) {
    generateApiToken(name: $name) {
      value
      token {
        id
        expiration
        name
      }
    }
  }
`
export type AdminGenerateApiTokenMutationFn = Apollo.MutationFunction<
  AdminGenerateApiTokenMutation,
  AdminGenerateApiTokenMutationVariables
>

/**
 * __useAdminGenerateApiTokenMutation__
 *
 * To run a mutation, you first call `useAdminGenerateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminGenerateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminGenerateApiTokenMutation, { data, loading, error }] = useAdminGenerateApiTokenMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAdminGenerateApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminGenerateApiTokenMutation,
    AdminGenerateApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminGenerateApiTokenMutation,
    AdminGenerateApiTokenMutationVariables
  >(AdminGenerateApiTokenDocument, options)
}
export type AdminGenerateApiTokenMutationHookResult = ReturnType<
  typeof useAdminGenerateApiTokenMutation
>
export type AdminGenerateApiTokenMutationResult =
  Apollo.MutationResult<AdminGenerateApiTokenMutation>
export type AdminGenerateApiTokenMutationOptions = Apollo.BaseMutationOptions<
  AdminGenerateApiTokenMutation,
  AdminGenerateApiTokenMutationVariables
>
export const AdminDeleteApiTokenDocument = gql`
  mutation adminDeleteApiToken($id: GlobalID!) {
    deleteApiToken(id: $id)
  }
`
export type AdminDeleteApiTokenMutationFn = Apollo.MutationFunction<
  AdminDeleteApiTokenMutation,
  AdminDeleteApiTokenMutationVariables
>

/**
 * __useAdminDeleteApiTokenMutation__
 *
 * To run a mutation, you first call `useAdminDeleteApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteApiTokenMutation, { data, loading, error }] = useAdminDeleteApiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteApiTokenMutation,
    AdminDeleteApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminDeleteApiTokenMutation,
    AdminDeleteApiTokenMutationVariables
  >(AdminDeleteApiTokenDocument, options)
}
export type AdminDeleteApiTokenMutationHookResult = ReturnType<
  typeof useAdminDeleteApiTokenMutation
>
export type AdminDeleteApiTokenMutationResult =
  Apollo.MutationResult<AdminDeleteApiTokenMutation>
export type AdminDeleteApiTokenMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteApiTokenMutation,
  AdminDeleteApiTokenMutationVariables
>
export const AdminAuditDocument = gql`
  query AdminAudit {
    authorities {
      list {
        topics {
          list {
            id
            name
          }
        }
      }
    }
    politicians(onlyWithEvaluations: true, limit: -1) {
      list {
        id
        slug
        person {
          id
          name
          surname
        }
        evaluation {
          id
          rating
          supportCategory
          sortable
        }
        filteredEvaluations {
          list {
            id
            votePositive
            voteNegative
            voteNeutral
            activityPositive
            activityNegative
            overallPositive
            overallNegative
            overallNeutral
            filterElectionPeriod {
              id
              start
              end
              isSenate
            }
            filterTopic {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useAdminAuditQuery__
 *
 * To run a query within a React component, call `useAdminAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAuditQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminAuditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminAuditQuery,
    AdminAuditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminAuditQuery, AdminAuditQueryVariables>(
    AdminAuditDocument,
    options,
  )
}
export function useAdminAuditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminAuditQuery,
    AdminAuditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminAuditQuery, AdminAuditQueryVariables>(
    AdminAuditDocument,
    options,
  )
}
export type AdminAuditQueryHookResult = ReturnType<typeof useAdminAuditQuery>
export type AdminAuditLazyQueryHookResult = ReturnType<
  typeof useAdminAuditLazyQuery
>
export type AdminAuditQueryResult = Apollo.QueryResult<
  AdminAuditQuery,
  AdminAuditQueryVariables
>
export const AdminDbExplorerDocument = gql`
  query AdminDbExplorer($id: GlobalID!) {
    node(id: $id) {
      __typename
      ... on ActivityGroup {
        id
        isPositive
        topic {
          id
        }
        activities {
          list {
            id
          }
        }
      }
      ... on Topic {
        id
        name
        icon {
          id
          sha256
          mime
          url
          size
          imageMetadata {
            width
            height
          }
        }
        shareLink
        availableInReflektor
        availableInEvaluation
        description_1: description
        reflektorUpdates {
          list {
            id
          }
        }
        reflektorHot
      }
      ... on Activity {
        id
        submandates {
          list {
            id
          }
        }
        description
        weight
      }
      ... on Submandate {
        id
        start
        end
        mandate {
          id
        }
        electionPeriod {
          id
        }
        votes {
          list {
            id
          }
        }
        parlamentaryGroupMemberships {
          list {
            id
            start
            end
            group {
              id
            }
            submandate {
              id
            }
          }
        }
      }
      ... on Mandate {
        id
        chamber
        start
        end
        region {
          id
        }
        politician {
          id
        }
        submandates {
          list {
            id
          }
        }
      }
      ... on Politician {
        id
        person {
          id
        }
        caption
        senateImportId
        lowerChamberImportId
        partyMemberships {
          list {
            id
          }
        }
        mandates {
          list {
            id
          }
        }
        evaluation {
          id
        }
        dead
        filteredEvaluations {
          list {
            id
          }
        }
        email
        parlamentaryGroup {
          id
        }
        reflektorTitleOverride
        resolvedPartyAbbreviation
        slug
      }
      ... on PartyMembership {
        id
        politician {
          id
        }
        party {
          id
        }
        start_1: start
        end
        function
      }
      ... on ParlamentaryGroup {
        id
        start
        end
        name
        abbreviation
        party_1: party {
          id
        }
      }
      ... on Party {
        id
        color
        contrastingColor
        logo {
          id
        }
        abbreviation
        name_1: name
        partyMemberships {
          list {
            id
          }
        }
        displayText
        availableInReflektor
      }
      ... on ParlamentaryGroupMembership {
        id
        start
        end
        group {
          id
        }
        submandate {
          id
        }
      }
    }
  }
`

/**
 * __useAdminDbExplorerQuery__
 *
 * To run a query within a React component, call `useAdminDbExplorerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDbExplorerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDbExplorerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDbExplorerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminDbExplorerQuery,
    AdminDbExplorerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminDbExplorerQuery, AdminDbExplorerQueryVariables>(
    AdminDbExplorerDocument,
    options,
  )
}
export function useAdminDbExplorerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminDbExplorerQuery,
    AdminDbExplorerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminDbExplorerQuery,
    AdminDbExplorerQueryVariables
  >(AdminDbExplorerDocument, options)
}
export type AdminDbExplorerQueryHookResult = ReturnType<
  typeof useAdminDbExplorerQuery
>
export type AdminDbExplorerLazyQueryHookResult = ReturnType<
  typeof useAdminDbExplorerLazyQuery
>
export type AdminDbExplorerQueryResult = Apollo.QueryResult<
  AdminDbExplorerQuery,
  AdminDbExplorerQueryVariables
>
export const AdminGlobalSettingsDocument = gql`
  query AdminGlobalSettings {
    globalSettings {
      value
      name
      description
      readonly: graphqlReadonly
      enum {
        display
        value
      }
    }
  }
`

/**
 * __useAdminGlobalSettingsQuery__
 *
 * To run a query within a React component, call `useAdminGlobalSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGlobalSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGlobalSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGlobalSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGlobalSettingsQuery,
    AdminGlobalSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminGlobalSettingsQuery,
    AdminGlobalSettingsQueryVariables
  >(AdminGlobalSettingsDocument, options)
}
export function useAdminGlobalSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGlobalSettingsQuery,
    AdminGlobalSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminGlobalSettingsQuery,
    AdminGlobalSettingsQueryVariables
  >(AdminGlobalSettingsDocument, options)
}
export type AdminGlobalSettingsQueryHookResult = ReturnType<
  typeof useAdminGlobalSettingsQuery
>
export type AdminGlobalSettingsLazyQueryHookResult = ReturnType<
  typeof useAdminGlobalSettingsLazyQuery
>
export type AdminGlobalSettingsQueryResult = Apollo.QueryResult<
  AdminGlobalSettingsQuery,
  AdminGlobalSettingsQueryVariables
>
export const AdminSetGlobalSettingDocument = gql`
  mutation AdminSetGlobalSetting($name: String!, $value: String) {
    setGlobalSetting(name: $name, value: $value) {
      value
    }
  }
`
export type AdminSetGlobalSettingMutationFn = Apollo.MutationFunction<
  AdminSetGlobalSettingMutation,
  AdminSetGlobalSettingMutationVariables
>

/**
 * __useAdminSetGlobalSettingMutation__
 *
 * To run a mutation, you first call `useAdminSetGlobalSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetGlobalSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetGlobalSettingMutation, { data, loading, error }] = useAdminSetGlobalSettingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAdminSetGlobalSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSetGlobalSettingMutation,
    AdminSetGlobalSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminSetGlobalSettingMutation,
    AdminSetGlobalSettingMutationVariables
  >(AdminSetGlobalSettingDocument, options)
}
export type AdminSetGlobalSettingMutationHookResult = ReturnType<
  typeof useAdminSetGlobalSettingMutation
>
export type AdminSetGlobalSettingMutationResult =
  Apollo.MutationResult<AdminSetGlobalSettingMutation>
export type AdminSetGlobalSettingMutationOptions = Apollo.BaseMutationOptions<
  AdminSetGlobalSettingMutation,
  AdminSetGlobalSettingMutationVariables
>
export const AdminActivityGroupsByTopicDocument = gql`
  query AdminActivityGroupsByTopic($topic: GlobalID!) {
    activityGroupsByTopic(topic: $topic) {
      list {
        id
        isPositive
        activities {
          list {
            weight
            id
            description
            hidden
          }
        }
      }
    }
  }
`

/**
 * __useAdminActivityGroupsByTopicQuery__
 *
 * To run a query within a React component, call `useAdminActivityGroupsByTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminActivityGroupsByTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminActivityGroupsByTopicQuery({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useAdminActivityGroupsByTopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminActivityGroupsByTopicQuery,
    AdminActivityGroupsByTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminActivityGroupsByTopicQuery,
    AdminActivityGroupsByTopicQueryVariables
  >(AdminActivityGroupsByTopicDocument, options)
}
export function useAdminActivityGroupsByTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminActivityGroupsByTopicQuery,
    AdminActivityGroupsByTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminActivityGroupsByTopicQuery,
    AdminActivityGroupsByTopicQueryVariables
  >(AdminActivityGroupsByTopicDocument, options)
}
export type AdminActivityGroupsByTopicQueryHookResult = ReturnType<
  typeof useAdminActivityGroupsByTopicQuery
>
export type AdminActivityGroupsByTopicLazyQueryHookResult = ReturnType<
  typeof useAdminActivityGroupsByTopicLazyQuery
>
export type AdminActivityGroupsByTopicQueryResult = Apollo.QueryResult<
  AdminActivityGroupsByTopicQuery,
  AdminActivityGroupsByTopicQueryVariables
>
export const AdminListPoliticiansDocument = gql`
  query AdminListPoliticians {
    politicians(limit: -1) {
      list {
        id
        person {
          name
          surname
        }
      }
    }
  }
`

/**
 * __useAdminListPoliticiansQuery__
 *
 * To run a query within a React component, call `useAdminListPoliticiansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPoliticiansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPoliticiansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminListPoliticiansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListPoliticiansQuery,
    AdminListPoliticiansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminListPoliticiansQuery,
    AdminListPoliticiansQueryVariables
  >(AdminListPoliticiansDocument, options)
}
export function useAdminListPoliticiansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPoliticiansQuery,
    AdminListPoliticiansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminListPoliticiansQuery,
    AdminListPoliticiansQueryVariables
  >(AdminListPoliticiansDocument, options)
}
export type AdminListPoliticiansQueryHookResult = ReturnType<
  typeof useAdminListPoliticiansQuery
>
export type AdminListPoliticiansLazyQueryHookResult = ReturnType<
  typeof useAdminListPoliticiansLazyQuery
>
export type AdminListPoliticiansQueryResult = Apollo.QueryResult<
  AdminListPoliticiansQuery,
  AdminListPoliticiansQueryVariables
>
export const AdminLastEvalDocument = gql`
  query AdminLastEval {
    globalSetting(name: "last_evaluation_time") {
      value
    }
  }
`

/**
 * __useAdminLastEvalQuery__
 *
 * To run a query within a React component, call `useAdminLastEvalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLastEvalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLastEvalQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminLastEvalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminLastEvalQuery,
    AdminLastEvalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminLastEvalQuery, AdminLastEvalQueryVariables>(
    AdminLastEvalDocument,
    options,
  )
}
export function useAdminLastEvalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminLastEvalQuery,
    AdminLastEvalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminLastEvalQuery, AdminLastEvalQueryVariables>(
    AdminLastEvalDocument,
    options,
  )
}
export type AdminLastEvalQueryHookResult = ReturnType<
  typeof useAdminLastEvalQuery
>
export type AdminLastEvalLazyQueryHookResult = ReturnType<
  typeof useAdminLastEvalLazyQuery
>
export type AdminLastEvalQueryResult = Apollo.QueryResult<
  AdminLastEvalQuery,
  AdminLastEvalQueryVariables
>
export const AdminUpsertActivityGroupByIdDocument = gql`
  query AdminUpsertActivityGroupById($id: GlobalID!) {
    activityGroupById(id: $id) {
      ...AdminUpsertActivityGroupGroup
    }
  }
  ${AdminUpsertActivityGroupGroupFragmentDoc}
`

/**
 * __useAdminUpsertActivityGroupByIdQuery__
 *
 * To run a query within a React component, call `useAdminUpsertActivityGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertActivityGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUpsertActivityGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUpsertActivityGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminUpsertActivityGroupByIdQuery,
    AdminUpsertActivityGroupByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminUpsertActivityGroupByIdQuery,
    AdminUpsertActivityGroupByIdQueryVariables
  >(AdminUpsertActivityGroupByIdDocument, options)
}
export function useAdminUpsertActivityGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminUpsertActivityGroupByIdQuery,
    AdminUpsertActivityGroupByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminUpsertActivityGroupByIdQuery,
    AdminUpsertActivityGroupByIdQueryVariables
  >(AdminUpsertActivityGroupByIdDocument, options)
}
export type AdminUpsertActivityGroupByIdQueryHookResult = ReturnType<
  typeof useAdminUpsertActivityGroupByIdQuery
>
export type AdminUpsertActivityGroupByIdLazyQueryHookResult = ReturnType<
  typeof useAdminUpsertActivityGroupByIdLazyQuery
>
export type AdminUpsertActivityGroupByIdQueryResult = Apollo.QueryResult<
  AdminUpsertActivityGroupByIdQuery,
  AdminUpsertActivityGroupByIdQueryVariables
>
export const AdminUpsertActivityGroupDocument = gql`
  mutation AdminUpsertActivityGroup(
    $id: GlobalID
    $data: UpsertActivityGroup!
  ) {
    upsertActivityGroup(id: $id, data: $data) {
      ...AdminUpsertActivityGroupGroup
    }
  }
  ${AdminUpsertActivityGroupGroupFragmentDoc}
`
export type AdminUpsertActivityGroupMutationFn = Apollo.MutationFunction<
  AdminUpsertActivityGroupMutation,
  AdminUpsertActivityGroupMutationVariables
>

/**
 * __useAdminUpsertActivityGroupMutation__
 *
 * To run a mutation, you first call `useAdminUpsertActivityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertActivityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpsertActivityGroupMutation, { data, loading, error }] = useAdminUpsertActivityGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpsertActivityGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpsertActivityGroupMutation,
    AdminUpsertActivityGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpsertActivityGroupMutation,
    AdminUpsertActivityGroupMutationVariables
  >(AdminUpsertActivityGroupDocument, options)
}
export type AdminUpsertActivityGroupMutationHookResult = ReturnType<
  typeof useAdminUpsertActivityGroupMutation
>
export type AdminUpsertActivityGroupMutationResult =
  Apollo.MutationResult<AdminUpsertActivityGroupMutation>
export type AdminUpsertActivityGroupMutationOptions =
  Apollo.BaseMutationOptions<
    AdminUpsertActivityGroupMutation,
    AdminUpsertActivityGroupMutationVariables
  >
export const DeleteActivityGroupDocument = gql`
  mutation deleteActivityGroup($id: GlobalID!) {
    deleteActivityGroup(group: $id)
  }
`
export type DeleteActivityGroupMutationFn = Apollo.MutationFunction<
  DeleteActivityGroupMutation,
  DeleteActivityGroupMutationVariables
>

/**
 * __useDeleteActivityGroupMutation__
 *
 * To run a mutation, you first call `useDeleteActivityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityGroupMutation, { data, loading, error }] = useDeleteActivityGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteActivityGroupMutation,
    DeleteActivityGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteActivityGroupMutation,
    DeleteActivityGroupMutationVariables
  >(DeleteActivityGroupDocument, options)
}
export type DeleteActivityGroupMutationHookResult = ReturnType<
  typeof useDeleteActivityGroupMutation
>
export type DeleteActivityGroupMutationResult =
  Apollo.MutationResult<DeleteActivityGroupMutation>
export type DeleteActivityGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteActivityGroupMutation,
  DeleteActivityGroupMutationVariables
>
export const AdminCreatePoliticianDocument = gql`
  mutation AdminCreatePolitician($input: CreatePoliticianInput!) {
    createPolitician(input: $input) {
      politician {
        ...AdminPoliticianFragment
      }
    }
  }
  ${AdminPoliticianFragmentFragmentDoc}
`
export type AdminCreatePoliticianMutationFn = Apollo.MutationFunction<
  AdminCreatePoliticianMutation,
  AdminCreatePoliticianMutationVariables
>

/**
 * __useAdminCreatePoliticianMutation__
 *
 * To run a mutation, you first call `useAdminCreatePoliticianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreatePoliticianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreatePoliticianMutation, { data, loading, error }] = useAdminCreatePoliticianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreatePoliticianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreatePoliticianMutation,
    AdminCreatePoliticianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminCreatePoliticianMutation,
    AdminCreatePoliticianMutationVariables
  >(AdminCreatePoliticianDocument, options)
}
export type AdminCreatePoliticianMutationHookResult = ReturnType<
  typeof useAdminCreatePoliticianMutation
>
export type AdminCreatePoliticianMutationResult =
  Apollo.MutationResult<AdminCreatePoliticianMutation>
export type AdminCreatePoliticianMutationOptions = Apollo.BaseMutationOptions<
  AdminCreatePoliticianMutation,
  AdminCreatePoliticianMutationVariables
>
export const AdminUpdatePoliticianDocument = gql`
  mutation AdminUpdatePolitician($input: UpdatePoliticianInput!) {
    updatePolitician(input: $input) {
      politician {
        ...AdminPoliticianFragment
      }
    }
  }
  ${AdminPoliticianFragmentFragmentDoc}
`
export type AdminUpdatePoliticianMutationFn = Apollo.MutationFunction<
  AdminUpdatePoliticianMutation,
  AdminUpdatePoliticianMutationVariables
>

/**
 * __useAdminUpdatePoliticianMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePoliticianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePoliticianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePoliticianMutation, { data, loading, error }] = useAdminUpdatePoliticianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdatePoliticianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdatePoliticianMutation,
    AdminUpdatePoliticianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdatePoliticianMutation,
    AdminUpdatePoliticianMutationVariables
  >(AdminUpdatePoliticianDocument, options)
}
export type AdminUpdatePoliticianMutationHookResult = ReturnType<
  typeof useAdminUpdatePoliticianMutation
>
export type AdminUpdatePoliticianMutationResult =
  Apollo.MutationResult<AdminUpdatePoliticianMutation>
export type AdminUpdatePoliticianMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdatePoliticianMutation,
  AdminUpdatePoliticianMutationVariables
>
export const AdminPoliticianDocument = gql`
  query AdminPolitician($id: GlobalID!) {
    node(id: $id) {
      ...AdminPoliticianFragment
    }
  }
  ${AdminPoliticianFragmentFragmentDoc}
`

/**
 * __useAdminPoliticianQuery__
 *
 * To run a query within a React component, call `useAdminPoliticianQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPoliticianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPoliticianQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPoliticianQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminPoliticianQuery,
    AdminPoliticianQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminPoliticianQuery, AdminPoliticianQueryVariables>(
    AdminPoliticianDocument,
    options,
  )
}
export function useAdminPoliticianLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPoliticianQuery,
    AdminPoliticianQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminPoliticianQuery,
    AdminPoliticianQueryVariables
  >(AdminPoliticianDocument, options)
}
export type AdminPoliticianQueryHookResult = ReturnType<
  typeof useAdminPoliticianQuery
>
export type AdminPoliticianLazyQueryHookResult = ReturnType<
  typeof useAdminPoliticianLazyQuery
>
export type AdminPoliticianQueryResult = Apollo.QueryResult<
  AdminPoliticianQuery,
  AdminPoliticianQueryVariables
>
export const DeletePollEvalDocument = gql`
  mutation deletePollEval($id: GlobalID!) {
    deletePollEval(evaluation: $id)
  }
`
export type DeletePollEvalMutationFn = Apollo.MutationFunction<
  DeletePollEvalMutation,
  DeletePollEvalMutationVariables
>

/**
 * __useDeletePollEvalMutation__
 *
 * To run a mutation, you first call `useDeletePollEvalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePollEvalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePollEvalMutation, { data, loading, error }] = useDeletePollEvalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePollEvalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePollEvalMutation,
    DeletePollEvalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePollEvalMutation,
    DeletePollEvalMutationVariables
  >(DeletePollEvalDocument, options)
}
export type DeletePollEvalMutationHookResult = ReturnType<
  typeof useDeletePollEvalMutation
>
export type DeletePollEvalMutationResult =
  Apollo.MutationResult<DeletePollEvalMutation>
export type DeletePollEvalMutationOptions = Apollo.BaseMutationOptions<
  DeletePollEvalMutation,
  DeletePollEvalMutationVariables
>

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Hamburger from './hamburger'
import { useState, useEffect } from 'react'
import { useEnv } from 'routes/routes'
import MenuItems from './menu-items'
import { withRouter } from 'react-router'
import ErrorBoundary from './error-boundary'

const WithAdminMenu = withRouter(({ children, history }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const unregister = history.listen(() => {
      setOpen(false)
    })
    return unregister
  })
  const env = useEnv().env
  return (
    <>
      <div
        css={css`
          display: flex;
          font-size: 30px;
          line-height: 65px;
          @media (min-width: 1400px) {
            padding-left: 20px;
          }
        `}
      >
        <Hamburger
          open={open}
          onClick={() => setOpen((v) => !v)}
          css={css`
            @media (min-width: 1400px) {
              display: none;
            }
          `}
        />
        ReSt Admin ({env === 'www' ? 'production' : env})
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          flex-direction: row;
          max-width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 0px;
            @media (min-width: 1400px) {
              width: 250px;
            }
          `}
        >
          <div
            css={css`
              width: 250px;
              min-width: 250px;
              background: #222;
              color: #eee;
              display: flex;
              flex-direction: column;
              padding-top: 10px;

              transform: ${open ? 'translateX(0px)' : 'translateX(-252px)'};
              transition: transform 200ms ease-in-out;
              z-index: 10;

              @media (min-width: 1400px) {
                transform: translateX(0px);
              }
            `}
          >
            <MenuItems />
          </div>
        </div>
        <div
          css={css`
            padding: 20px;
            background: #eee;
            flex-grow: 1;
            max-width: 100%;
            @media (min-width: 1400px) {
              width: calc(100% - 250px);
            }
          `}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </>
  )
})
export default WithAdminMenu

import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { EditorState, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Schema, DOMParser } from 'prosemirror-model'
import { schema as schemaBasic } from 'prosemirror-schema-basic'
import { exampleSetup } from 'prosemirror-example-setup'
import { Raven } from 'utils/globals'
import { toggleMark, setBlockType, wrapIn } from 'prosemirror-commands'
import { EditorStyles } from './styles'
import {
  placeholderPlugin,
  findPlaceholder,
  placeholderBackground,
} from './placeholder-plugin'
import { useUploadFile } from '../file-input'
import Menu from './menu'
import { cloudUpload } from './cloud-upload'
import { TextEditorViewContextProvider } from './text-editor-view-context'
import { textEditorSchema, textEditorSchemaVersion } from './text-editor-schema'
import { TextEditorFileUpload } from './text-editor-file-upload'

const StyledEditor = styled.div({
  borderRadius: 3,
  backgroundColor: 'white',
  border: '3px solid #aaa',
  maxWidth: 800,
  p: {
    margin: 0,
    padding: '18px 0 0 0',
  },
  'div.ProseMirror-menubar': {
    padding: '3px 6px',
    background: 'transparent',
  },
  '.ProseMirror': {
    padding: 10,
    'p:first-of-type': {
      padding: 0,
    },
    '.cloud-upload': cloudUpload(placeholderBackground),
  },
})

type MySchema = typeof textEditorSchema

export type Value = EditorState<MySchema>

function useEditorView({
  value,
  onChange,
  onUpdate,
  submit,
}: {
  value: Value
  onChange: (v: { value: Value }) => void
  onUpdate: () => void
  submit: () => void
}) {
  const [view, setView] = useState<EditorView<MySchema> | null>(null)
  const editorRef = useRef<HTMLDivElement | null>(null)
  const valueRef = useRef<Value>(value)

  const onChangeRef = useRef(onChange)
  const onUpdateRef = useRef(onUpdate)
  const submitRef = useRef(submit)
  useEffect(() => {
    onChangeRef.current = onChange
    onUpdateRef.current = onUpdate
    submitRef.current = submit
  })

  useEffect(() => {
    const view = new EditorView<MySchema>(editorRef.current!, {
      state: valueRef.current,
      dispatchTransaction: (transaction) => {
        const nextValue = valueRef.current.apply(transaction)
        onChangeRef.current({ value: nextValue })
        view.updateState(nextValue)
        valueRef.current = nextValue
        onUpdateRef.current()
      },
      handleKeyDown: (_, evt) => {
        if (evt.ctrlKey && ['NumpadEnter', 'Enter'].includes(evt.code)) {
          submitRef.current()
          return true
        }
        return false
      },
    })
    setView(view)
    return () => {
      console.log('Destroying editor view')
      view.destroy()
    }
  }, [])
  return { view, editorRef }
}

const TextEditor = ({
  onChange,
  value,
  submit = () => {},
}: {
  onChange: (v: { value: Value }) => void
  value: Value
  submit?: () => void
}) => {
  const [, setVersion] = useState(0)
  const { view, editorRef } = useEditorView({
    value,
    onChange,
    onUpdate: () => setVersion((v) => v + 1),
    submit,
  })

  return (
    <StyledEditor>
      {view && (
        <TextEditorViewContextProvider view={view}>
          <TextEditorFileUpload />
          <Menu />
        </TextEditorViewContextProvider>
      )}
      <EditorStyles>
        <div ref={editorRef} />
      </EditorStyles>
    </StyledEditor>
  )
}

export default TextEditor
export function serialize(value: Value): any {
  return {
    editor: 'prosemirror',
    version: textEditorSchemaVersion,
    value: value.toJSON(),
  }
}

const cfg = {
  schema: textEditorSchema,
  plugins: exampleSetup({ schema: textEditorSchema }).concat([
    placeholderPlugin,
  ]),
}
export function deserialize(json: any): Value {
  if (json && json.editor === 'prosemirror') {
    const state = EditorState.fromJSON<MySchema>(cfg, json.value)
    if (json.version !== textEditorSchemaVersion) {
      return state.reconfigure(cfg)
    }
    return state
  }
  if (!json || !json.editor) {
    return EditorState.create(cfg)
  }
  throw new Error(`Cannot edit '${json.editor}' with this editor`)
}

export function fromHtml(html: string) {
  const art = document.createElement('article')
  art.innerHTML = html
  return {
    editor: 'prosemirror',
    version: textEditorSchemaVersion,
    value: EditorState.create({
      ...cfg,
      doc: DOMParser.fromSchema(textEditorSchema).parse(art),
    }).toJSON(),
  }
}

import React, { useMemo } from 'react'
import gql from 'graphql-tag'
import {
  useAdminRemoveRoleMutation,
  useAdminAddRoleMutation,
  useAdminUserDeleteMutation,
  AdminUserListDocument,
} from 'queries/queries'

gql`
  mutation adminRemoveRole($userId: GlobalID!, $role: String!) {
    removeRole(userId: $userId, role: $role)
  }
  mutation adminAddRole($userId: GlobalID!, $role: String!) {
    addRole(userId: $userId, role: $role)
  }
`

export default function useChangeUser() {
  const [removeRole] = useAdminRemoveRoleMutation({ ignoreResults: true })
  const [addRole] = useAdminAddRoleMutation({ ignoreResults: true })
  const [deleteUser] = useAdminUserDeleteMutation({ ignoreResults: true })
  return useMemo(
    () => ({
      addRole: (arg: { user: string; role: string }) =>
        addRole({
          variables: { userId: arg.user, role: arg.role },
          refetchQueries: ['adminUserList'],
        }),

      removeRole: (arg: { user: string; role: string }) =>
        removeRole({
          variables: { userId: arg.user, role: arg.role },
          refetchQueries: ['adminUserList'],
        }),
      delete: (arg: { user: string }) =>
        deleteUser({
          variables: { id: arg.user },
          refetchQueries: [{ query: AdminUserListDocument }],
        }),
    }),
    [addRole, deleteUser, removeRole],
  )
}

import React from 'react'
import AdminLoginContainer from 'admin/containers/login-container'
import styled from '@emotion/styled'
import AdminInput from 'admin/components/admin-input'

const Center = styled.div`
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Form = styled.div`
  margin: 0 auto;
  border: 0;
  padding: 10px;
  background: #ccc;
  border-radius: 10px;
  input {
    width: 200px;
    margin-right: 0;
  }
  label > span:first-child {
    display: inline-block;
    width: 100px;
  }
  > div {
    margin: 3px;
  }
`

const Switcher = styled.div`
  display: flex;
  justify-content: center;
`

const SwitchLink = styled.a<{ active: boolean }>`
  margin: 0 10px;
  border: 1px solid black;
  border-bottom: 0;
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  background: #222;
  color: white;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  cursor: pointer;
`

export default function Login({ message }: { message?: string }) {
  return (
    <AdminLoginContainer>
      {({ change, submit, values, errors, result }) => {
        if (result)
          return (
            <Center>
              <Form>{result}</Form>
            </Center>
          )

        return (
          <form onSubmit={submit} style={{ position: 'relative', flexGrow: 1 }}>
            <Center>
              <Switcher>
                <SwitchLink
                  onClick={() => change.register(false)}
                  active={!values.register}
                >
                  Přihlásit se
                </SwitchLink>
                <SwitchLink
                  onClick={() => change.register(true)}
                  active={values.register}
                >
                  Vytvořit účet
                </SwitchLink>
              </Switcher>
              {values.register ? (
                <div>Administrace používá účet z reflektoru...</div>
              ) : (
                <Form>
                  {message && <div>{message}</div>}
                  <div>
                    <AdminInput
                      label="email"
                      type="text"
                      state={{ value: values.email, change: change.email }}
                      name="email"
                    />
                  </div>
                  <div>
                    <AdminInput
                      label="heslo"
                      type="password"
                      state={{
                        value: values.password,
                        change: change.password,
                      }}
                      name="password"
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'right',
                    }}
                  >
                    <button style={{ flexGrow: 0 }}>Přihlásit se</button>
                  </div>
                </Form>
              )}
            </Center>
          </form>
        )
      }}
    </AdminLoginContainer>
  )
}

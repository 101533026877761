/** @jsx jsx */
import { jsx } from '@emotion/react'
import Link from 'components/link'
import styled from '@emotion/styled'
import { useAdminReflektorUpdateList } from 'admin/queries/reflektor-update'
import { SingleTwemoji } from 'components/twemoji'
import { useMemo } from 'react'
import { LocalizedDate } from 'components/localized-date'
import { DateTime } from 'luxon'
import { useBariolItalic } from 'utils/fonts'

const ListItem = styled(Link)({
  display: 'flex',
  padding: 5,
})

const AdminReflektorUpdateList = () => {
  const unsorted = useAdminReflektorUpdateList()
  const sorted = useMemo(() => {
    if (!unsorted) return null
    const drafts: typeof unsorted[0][] = []
    const published: typeof unsorted[0][] = []
    const archive: typeof unsorted[0][] = []
    for (const update of unsorted) {
      if (update.publishDate) published.push(update)
      else if (update.archive) archive.push(update)
      else drafts.push(update)
    }
    return [
      ...drafts,
      ...published.sort((a, b) =>
        a.publishDate === b.publishDate
          ? 0
          : !a.publishDate
          ? -1
          : !b.publishDate
          ? 1
          : a.publishDate > b.publishDate
          ? -1
          : 1,
      ),
      ...archive,
    ]
  }, [unsorted])
  const bariolItalic = useBariolItalic()
  if (!sorted) return null
  return (
    <div>
      <ListItem to="/admin/reflektor-update/new">
        Vložit novou výzvu reflektoru
      </ListItem>
      <div css={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
        {sorted.map((update) => (
          <>
            <div>
              {update.publishDate ? (
                <LocalizedDate date={DateTime.fromISO(update.publishDate)} />
              ) : null}
            </div>
            <div>
              <ListItem
                key={update.id}
                to={`/admin/reflektor-update/${update.id}`}
              >
                {update.archive ? <SingleTwemoji emoji="📜" /> : null}{' '}
                {update.title}
              </ListItem>
            </div>
          </>
        ))}
      </div>
      <div css={[bariolItalic, { marginTop: 16, maxWidth: '48ch' }]}>
        Řazení: první jsou výzvy, které nebyly publikované, pak jsou publikované
        výzvy podle data a nakonec jsou výzvy které nikdy nebyly publikované a
        jsou nastavené jako archivní.
      </div>
    </div>
  )
}
export default AdminReflektorUpdateList

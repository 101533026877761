/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useXLSX } from './import-utils'

import { useCallback, useState, useRef, useMemo, useLayoutEffect } from 'react'

import { useDropzone, DropEvent, FileRejection } from 'react-dropzone'
import { WorkBook } from 'xlsx/types'
import filesize from 'filesize'

function parseCellLocation(cell: string): { row: number; col: number } | null {
  if (!/^[A-Z]+[0-9]+$/i.test(cell)) return null
  const row = Number.parseInt(cell.replace(/^[a-z]+/i, ''), 10)
  if (!Number.isInteger(row) || row < 1) return null
  const rowName = cell.replace(/[0-9]+$/i, '').toLowerCase()
  let col = 0
  const aCode = 'a'.codePointAt(0)!
  const zCode = 'z'.codePointAt(0)!
  let mult = 1
  for (let idx = rowName.length - 1; idx >= 0; idx -= 1) {
    col += (rowName.codePointAt(idx)! - aCode + 1) * mult
    mult *= zCode - aCode + 1
  }
  return { col: col - 1, row: row - 1 }
}

function serializeLocation(row: number, _col: number) {
  let col = _col
  let colName = ''
  const aCode = 'a'.codePointAt(0)!
  while (col >= 0.99) {
    colName = String.fromCharCode((col % 26) + aCode) + colName
    col = Math.floor(col / 26)
  }
  return `${colName.toUpperCase() || 'A'}${row + 1}`
}

export type ParsedSheet = {
  cells: {
    name: string
    value: string | number | boolean | Date | null
  }[][]
  rows: number
  cols: number
}

export function useSheetSelector({
  match,
}: {
  match: (name: string) => number
}) {
  const xlsx = useXLSX()
  const counter = useRef(0)
  const [d, setWorkbook] = useState(
    null as {
      workbook: WorkBook
      file: File
    } | null,
  )
  const onDrop = useCallback(
    (files: File[], _: FileRejection[], evt: DropEvent) => {
      if ('target' in evt && evt.target) {
        // eslint-disable-next-line no-param-reassign
        ;(evt.target as any).value = null
      }
      if (files.length > 0) {
        const reader = new FileReader()
        reader.onload = () => {
          const result = reader.result
          if (!result) return
          if (typeof result === 'string') {
            counter.current += 1
            setWorkbook({
              workbook: xlsx.read(result, { type: 'string', cellDates: true }),
              file: files[0],
            })
          } else {
            const data = new Uint8Array(result)
            counter.current += 1
            setWorkbook({
              workbook: xlsx.read(data, { type: 'array', cellDates: true }),
              file: files[0],
            })
          }
        }
        const file = files[0]
        if (file.name.endsWith('.csv')) reader.readAsText(files[0], 'utf8')
        else reader.readAsArrayBuffer(files[0])
      }
    },
    [xlsx],
  )
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    onDragEnter: () => {},
    onDragOver: () => {},
    onDragLeave: () => {},
  })

  const [sheetName, setSheet] = useState('')
  useLayoutEffect(() => {
    if (d) {
      if (d.workbook.SheetNames.length === 1) {
        setSheet(d.workbook.SheetNames[0] || '')
      } else {
        let bestSheet: string | null = null
        let bestScore = 0
        for (const sheet of d.workbook.SheetNames) {
          const score = match(sheet)
          if (score > bestScore) {
            bestScore = score
            bestSheet = sheet
          }
        }
        setSheet(bestSheet || '')
      }
    } else {
      setSheet('')
    }
  }, [d, match])

  const sheet = d && d.workbook.Sheets[sheetName]
  const parsed = useMemo(() => {
    if (!sheet) return null
    const cells: ParsedSheet['cells'] = []
    let rows = 0
    let cols = 0
    for (const [cell, value] of Object.entries(sheet)) {
      const loc = parseCellLocation(cell)
      if (!loc) continue
      if (!cells[loc.row]) cells[loc.row] = []
      if (Math.floor(value.v) === 42746) console.log(value)
      cells[loc.row]![loc.col] = { name: cell, value: value.w || value.v }
      if (loc.row + 1 > rows) rows = loc.row + 1
      if (loc.col + 1 > cols) cols = loc.col + 1
    }
    for (let row = 0; row < rows; row += 1) {
      if (!cells[row]) cells[row] = []
      for (let col = 0; col < cols; col += 1) {
        if (!cells[row][col]) {
          cells[row][col] = { name: serializeLocation(row, col), value: null }
        }
      }
    }
    return { cells, rows, cols }
  }, [sheet])
  return {
    element: (
      <>
        <div>
          <div>0. Řekni Isabelle ať udělá zálohu databáze</div>
          <div>
            1. Vyber spreadsheet <input {...(getInputProps() as any)} />
            <button type="button" onClick={open}>
              Procházet...
            </button>
            <i>
              {isDragActive
                ? ' Můžeš upustit soubor'
                : ' Nebo můžeš použít drag and drop'}
            </i>
          </div>
          {!d ? null : (
            <div>
              Vybraný soubor: {d.file.name} ({filesize(d.file.size)})
            </div>
          )}
        </div>
        {!d ? null : (
          <div>
            2. Vyberte list{' '}
            <select
              value={sheetName}
              onChange={(evt) => setSheet(evt.target.value)}
              css={{ color: 'black', background: 'white' }}
            >
              <option value="">vyber prosím list</option>
              {d.workbook.SheetNames.map((name, i) => (
                <option key={i}>{name}</option>
              ))}
            </select>
          </div>
        )}
      </>
    ),
    rootProps: {
      ...getRootProps(),
      style: isDragActive ? { filter: 'brightness(0.7)' } : {},
      onClick: () => {},
      tabIndex: undefined,
    },
    sheet,
    // changes on any sheet change
    sheetId: `${counter.current}-${sheetName}`,
    parsed,
  }
}

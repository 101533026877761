/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useMemo } from 'react'
import AdminButton from 'admin/components/admin-button'
import LoaderSpinner from 'components/loader-spinner'
import { useThen } from 'admin/utils/use-then'
import gql from 'graphql-tag'
import { useAdminLastEvalQuery } from 'queries/queries'
import { DateTime } from 'luxon'
import { useEnv } from 'routes/routes'
import { useApolloClient } from '@apollo/client/react/hooks'

gql`
  query AdminLastEval {
    globalSetting(name: "last_evaluation_time") {
      value
    }
  }
`

export function AdminRecalculateEvaluation() {
  const [promise, setPromise] = useState<null | ReturnType<typeof recalc>>(null)
  const lastEval = useAdminLastEvalQuery()
  const v = useThen(promise)
  const env = useEnv()
  const dt = useMemo(() => {
    const s = lastEval.data?.globalSetting?.value
    return s
      ? DateTime.fromISO(s).setZone('local').toFormat('d. M. yyyy H:mm:ss')
      : '...'
  }, [lastEval.data?.globalSetting?.value])
  const apollo = useApolloClient()
  return (
    <div>
      <h1>Přepočítat hodnocení</h1>
      Naposledy přepočítáno: {dt}
      {v.promise === null ? (
        <div />
      ) : v.status === 'done' ? (
        <pre>{JSON.stringify(v.data, null, 2)}</pre>
      ) : v.status === 'loading' ? (
        <LoaderSpinner />
      ) : (
        <pre>{JSON.stringify(v.error, null, 2)}</pre>
      )}
      {v.status !== 'loading' || v.promise === null ? (
        <>
          <AdminButton
            onClick={() => setPromise(recalc(false, () => apollo.clearStore()))}
          >
            Přepočítat
          </AdminButton>
          {env.env === 'development' ? (
            <AdminButton
              onClick={() => setPromise(recalc(true, () => lastEval.refetch()))}
            >
              Dev počítat
            </AdminButton>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

async function recalc(dev: boolean, refetch: () => Promise<any>) {
  const r = await fetch(
    '/api/recalculate-evaluation' + (dev ? '?disableInsert=true' : ''),
    {
      credentials: 'same-origin',
      method: 'POST',
    },
  )
  const res = await r.json()
  await refetch()
  return res
}

import { css } from '@emotion/react'

export const cloudUpload = (bg: string) => css`
  transform: scale(3);
  left: calc(50% - 21px);
  top: calc(50% - 6px);

  color: #000;
  position: absolute;
  margin-left: 1px;
  margin-top: 9px;
  width: 5px;
  height: 6px;
  border-radius: 4px 0 0 4px;
  border-left: solid 1px currentColor;
  border-top: solid 1px currentColor;
  border-bottom: solid 1px currentColor;

  :before {
    content: '';
    position: absolute;
    top: -7px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-left: solid 1px transparent;
    border-right: solid 1px currentColor;
    border-top: solid 1px currentColor;
    border-bottom: solid 1px currentColor;
  }
  :after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 9px;
    height: 2px;
    color: ${bg};
    background-color: currentColor;
  }
  & i {
    position: absolute;
    left: 5px;
    top: -4px;
    z-index: 2;
  }
  & i:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 1px;
    width: 1px;
    height: 7px;
    background-color: currentColor;
    border-left: solid 4px ${bg};
    border-right: solid 4px ${bg};
  }
  & i:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 4px;
    width: 4px;
    height: 4px;
    border-top: solid 1px currentColor;
    border-right: solid 1px currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`

import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  useReducer,
} from 'react'
// @ts-ignore
import { __RouterContext, RouteComponentProps } from 'react-router'

/**
 * Use this only if you know what you are doing
 */
function useRouterUnsafe<T = {}>(): RouteComponentProps<T> {
  return useContext(__RouterContext as any)
}

export function useHistoryChange() {
  const { history } = useRouterUnsafe()
  return useMemo(
    () => ({
      push: (path: string, state?: any) => history.push(path, state),
      replace: (path: string, state?: any) => history.replace(path, state),
    }),
    [history],
  )
}

type Setter = (
  value: string | null,
  opts?: { push?: boolean; state?: any },
) => void
export function useQueryParam(param: string): [string | null, Setter] {
  const router = useRouterUnsafe()
  const [value, setValue] = useState(() =>
    new URLSearchParams(router.location.search).get(param),
  )
  const scheduled = useRef([] as (string | null)[])

  useEffect(() => {
    return router.history.listen((location) => {
      const newValue = new URLSearchParams(location.search).get(param) || ''
      if (scheduled.current.includes(newValue)) {
        scheduled.current.splice(scheduled.current.indexOf(newValue), 1)
      } else {
        requestAnimationFrame(() => {
          setValue(newValue)
        })
      }
    })
  }, [param, router.history])

  const setValueOnRouter = useCallback<Setter>(
    (
      newValue,
      {
        push = false,
        state: locationState,
      }: { push?: boolean; state?: any } = {},
    ) => {
      const params = new URLSearchParams(router.location.search)
      if (newValue !== null) params.set(param, newValue)
      else params.delete(param)
      params.sort()
      const state = {
        ...router.location,
        state:
          locationState === undefined ? router.location.state : locationState,
        search: params.toString(),
      }
      setValue(newValue)
      scheduled.current.push(newValue)
      if (push) router.history.push(state)
      else router.history.replace(state)
    },
    [param, router.history, router.location],
  )

  return [value, setValueOnRouter]
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, {
  PropsWithChildren,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { CardBase, CardHead, CardBody } from 'admin/components/card'
import { XLSXProvider, useXLSX } from '../import-utils'
import { useDropzone } from 'react-dropzone'
import filesize from 'filesize'
import { WorkBook, Sheet } from 'xlsx/types'
import { Tooltip } from '@material-ui/core'
import {
  ActivityImportSelectColumns,
  ColumnMap,
} from './activity-select-columns'
import { FinishActivityImport } from './finish-activity-import'
import { ImporterHelp } from '../importer-help'
import { useSheetSelector } from '../sheet-selector'

function Card({ children }: PropsWithChildren<{}>) {
  return (
    <CardBase>
      <CardHead>Import aktivity</CardHead>
      <CardBody>{children}</CardBody>
    </CardBase>
  )
}

function ActivityImport() {
  const sheet = useSheetSelector({
    match: useCallback(
      (name) =>
        name.includes('aktivita') || name.includes('activity') ? 1 : 0,
      [],
    ),
  })

  const [columns, setColumnMap] = useState<{
    columnMap: ColumnMap
    ok: boolean
  } | null>(null)
  useEffect(() => {
    if (!sheet.sheet) {
      if (columns) setColumnMap(null)
    }
  }, [columns, sheet])

  return (
    <div {...sheet.rootProps}>
      <Card>
        <ImporterHelp />
        {sheet.element}
        {!sheet.sheet ? null : (
          <ActivityImportSelectColumns
            key={sheet.sheetId}
            sheet={sheet.sheet}
            onChange={setColumnMap}
          />
        )}
        {columns && columns.ok && sheet.sheet ? (
          <FinishActivityImport
            columnMap={columns.columnMap}
            sheet={sheet.sheet}
          />
        ) : null}
      </Card>
    </div>
  )
}

export default function AdminActivityImport() {
  return (
    <XLSXProvider>
      <ActivityImport />
    </XLSXProvider>
  )
}

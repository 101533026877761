import React, { useMemo } from 'react'

import { Code } from './json-view-parts'
import { JSONViewInternal } from './json-view-key-value'
import { JSONViewOptions } from './json-view-types'

export const JSONView = React.memo(function JSONView(
  props: ({ data: any } | { children: any }) & Partial<JSONViewOptions>,
) {
  const {
    expandedDepth = Number.POSITIVE_INFINITY,
    folding = true,
    isLink = () => false,
    onClick = () => {},
  } = props
  const opts = useMemo(
    (): JSONViewOptions => ({ expandedDepth, folding, isLink, onClick }),
    [expandedDepth, folding, isLink, onClick],
  )
  return (
    <Code>
      <JSONViewInternal
        data={'data' in props ? props.data : props.children}
        options={opts}
        depth={0}
      />
    </Code>
  )
})

import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import NotFound from './not-found'
import { useEnv } from './routes'
import { DefaultComponent } from '@loadable/component'
import Admin from '../admin/admin'

const notFound = (
  <Route>
    <NotFound />
  </Route>
)

const admin = <Route path="/admin" render={() => <Admin />} />

export default function RestRoutes() {
  const { hosttype } = useEnv()
  if (hosttype === 'reflektor') {
    return <NotFound />
  }

  if (hosttype === 'admin') {
    return (
      <Switch>
        {admin}
        <Route path="/" exact>
          <Redirect to="/admin" />
        </Route>
        {notFound}
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/" exact>
        <a href="/admin">admin</a>
      </Route>
      {admin}

      {notFound}
    </Switch>
  )
}

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ParsedSheet } from '../sheet-selector'

export function SheetPreview({ parsed }: { parsed: ParsedSheet }) {
  const border = '1px solid black'
  return (
    <div>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: `repeat(${parsed.cols + 1}, auto)`,
          '> div': {
            padding: 2,
            borderRight: border,
            borderBottom: border,
            maxHeight: 100,
            minWidth: 200,
            overflowY: 'auto',
            '&.number, &.top': {
              minWidth: 40,
              textAlign: 'center',
              background: '#aaa',
            },
          },
          border,
          maxHeight: 500,
          overflow: 'auto',
          width: '100%',
        }}
      >
        <div className="number top" />
        {parsed.cells[0].map((cell) => (
          <div key={cell.name.replace(/[0-9]+/, '')} className="top">
            {cell.name.replace(/[0-9]+/, '')}
          </div>
        ))}
        {parsed.cells.map((el, i) => [
          <div key={el[0].name.replace(/[a-z]+/i, '')} className="number">
            {el[0].name.replace(/[a-z]+/i, '')}
          </div>,
          ...el.map((cell) => (
            <div key={cell.name}>
              {cell.value === null ? null : JSON.stringify(cell.value)}
            </div>
          )),
        ])}
      </div>
    </div>
  )
}
